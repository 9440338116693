export const charIcons = {
  Altaïr: `${process.env.PUBLIC_URL}/char_icons/altair.png`,
  "Banjo & Kazooie": `${process.env.PUBLIC_URL}/char_icons/banjo_kazooie.png`,
  Bayonetta: `${process.env.PUBLIC_URL}/char_icons/bayonetta.png`,
  Bowser: `${process.env.PUBLIC_URL}/char_icons/bowser.png`,
  "Bowser Jr.": `${process.env.PUBLIC_URL}/char_icons/bowser_jr.png`,
  Byleth: `${process.env.PUBLIC_URL}/char_icons/byleth.png`,
  "Captain Falcon": `${process.env.PUBLIC_URL}/char_icons/captain_falcon.png`,
  Charizard: `${process.env.PUBLIC_URL}/char_icons/charizard.png`,
  Chrom: `${process.env.PUBLIC_URL}/char_icons/chrom.png`,
  Cloud: `${process.env.PUBLIC_URL}/char_icons/cloud.png`,
  Corrin: `${process.env.PUBLIC_URL}/char_icons/corrin.png`,
  Cuphead: `${process.env.PUBLIC_URL}/char_icons/cuphead.png`,
  Daisy: `${process.env.PUBLIC_URL}/char_icons/daisy.png`,
  "Dark Pit": `${process.env.PUBLIC_URL}/char_icons/dark_pit.png`,
  "Dark Samus": `${process.env.PUBLIC_URL}/char_icons/dark_samus.png`,
  "Diddy Kong": `${process.env.PUBLIC_URL}/char_icons/diddy_kong.png`,
  "Donkey Kong": `${process.env.PUBLIC_URL}/char_icons/donkey_kong.png`,
  "Dr. Mario": `${process.env.PUBLIC_URL}/char_icons/dr_mario.png`,
  "Duck Hunt Duo": `${process.env.PUBLIC_URL}/char_icons/duck_hunt.png`,
  Falco: `${process.env.PUBLIC_URL}/char_icons/falco.png`,
  Fox: `${process.env.PUBLIC_URL}/char_icons/fox.png`,
  Ganondorf: `${process.env.PUBLIC_URL}/char_icons/ganondorf.png`,
  Greninja: `${process.env.PUBLIC_URL}/char_icons/greninja.png`,
  Hero: `${process.env.PUBLIC_URL}/char_icons/hero.png`,
  "Ice Climbers": `${process.env.PUBLIC_URL}/char_icons/ice_climbers.png`,
  Ike: `${process.env.PUBLIC_URL}/char_icons/ike.png`,
  Inkling: `${process.env.PUBLIC_URL}/char_icons/inkling.png`,
  Incineroar: `${process.env.PUBLIC_URL}/char_icons/incineroar.png`,
  Isabelle: `${process.env.PUBLIC_URL}/char_icons/isabelle.png`,
  Ivysaur: `${process.env.PUBLIC_URL}/char_icons/ivysaur.png`,
  Jigglypuff: `${process.env.PUBLIC_URL}/char_icons/jigglypuff.png`,
  Joker: `${process.env.PUBLIC_URL}/char_icons/joker.png`,
  Kazuya: `${process.env.PUBLIC_URL}/char_icons/kazuya.png`,
  Ken: `${process.env.PUBLIC_URL}/char_icons/ken.png`,
  "King Dedede": `${process.env.PUBLIC_URL}/char_icons/king_dedede.png`,
  "King K. Rool": `${process.env.PUBLIC_URL}/char_icons/king_k_rool.png`,
  Kirby: `${process.env.PUBLIC_URL}/char_icons/kirby.png`,
  Link: `${process.env.PUBLIC_URL}/char_icons/link.png`,
  "Little Mac": `${process.env.PUBLIC_URL}/char_icons/little_mac.png`,
  Lucario: `${process.env.PUBLIC_URL}/char_icons/lucario.png`,
  Lucas: `${process.env.PUBLIC_URL}/char_icons/lucas.png`,
  Lucina: `${process.env.PUBLIC_URL}/char_icons/lucina.png`,
  Luigi: `${process.env.PUBLIC_URL}/char_icons/luigi.png`,
  Mario: `${process.env.PUBLIC_URL}/char_icons/mario.png`,
  Marth: `${process.env.PUBLIC_URL}/char_icons/marth.png`,
  "Mega Man": `${process.env.PUBLIC_URL}/char_icons/mega_man.png`,
  "Meta Knight": `${process.env.PUBLIC_URL}/char_icons/meta_knight.png`,
  Mewtwo: `${process.env.PUBLIC_URL}/char_icons/mewtwo.png`,
  "Mii Brawler": `${process.env.PUBLIC_URL}/char_icons/mii_brawler.png`,
  "Mii Gunner": `${process.env.PUBLIC_URL}/char_icons/mii_gunner.png`,
  "Mii Swordfighter": `${process.env.PUBLIC_URL}/char_icons/mii_swordfighter.png`,
  "Min Min": `${process.env.PUBLIC_URL}/char_icons/min_min.png`,
  "Mr. Game & Watch": `${process.env.PUBLIC_URL}/char_icons/mr_game_and_watch.png`,
  Ness: `${process.env.PUBLIC_URL}/char_icons/ness.png`,
  Olimar: `${process.env.PUBLIC_URL}/char_icons/olimar.png`,
  "PAC-MAN": `${process.env.PUBLIC_URL}/char_icons/pac_man.png`,
  Palutena: `${process.env.PUBLIC_URL}/char_icons/palutena.png`,
  Peach: `${process.env.PUBLIC_URL}/char_icons/princesses.png`,
  Pichu: `${process.env.PUBLIC_URL}/char_icons/pichu.png`,
  Pikachu: `${process.env.PUBLIC_URL}/char_icons/pikachu.png`,
  "Piranha Plant": `${process.env.PUBLIC_URL}/char_icons/piranha_plant.png`,
  Pit: `${process.env.PUBLIC_URL}/char_icons/pits.png`,
  "Pokemon Trainer": `${process.env.PUBLIC_URL}/char_icons/pokemon_trainer.png`,
  "Pyra / Mythra": `${process.env.PUBLIC_URL}/char_icons/pyra_mythra.png`,
  Belmonts: `${process.env.PUBLIC_URL}/char_icons/belmonts.png`,
  Ridley: `${process.env.PUBLIC_URL}/char_icons/ridley.png`,
  "R.O.B.": `${process.env.PUBLIC_URL}/char_icons/rob.png`,
  Robin: `${process.env.PUBLIC_URL}/char_icons/robin.png`,
  "Rosalina & Luma": `${process.env.PUBLIC_URL}/char_icons/rosalina_and_luma.png`,
  Roy: `${process.env.PUBLIC_URL}/char_icons/roy.png`,
  Ryu: `${process.env.PUBLIC_URL}/char_icons/ryu.png`,
  Samus: `${process.env.PUBLIC_URL}/char_icons/samuses.png`,
  Sans: `${process.env.PUBLIC_URL}/char_icons/sans.png`,
  Sephiroth: `${process.env.PUBLIC_URL}/char_icons/sephiroth.png`,
  Sheik: `${process.env.PUBLIC_URL}/char_icons/sheik.png`,
  Shulk: `${process.env.PUBLIC_URL}/char_icons/shulk.png`,
  Simon: `${process.env.PUBLIC_URL}/char_icons/simon.png`,
  Snake: `${process.env.PUBLIC_URL}/char_icons/snake.png`,
  Sonic: `${process.env.PUBLIC_URL}/char_icons/sonic.png`,
  Sora: `${process.env.PUBLIC_URL}/char_icons/sora.png`,
  Squirtle: `${process.env.PUBLIC_URL}/char_icons/squirtle.png`,
  Steve: `${process.env.PUBLIC_URL}/char_icons/steve.png`,
  Terry: `${process.env.PUBLIC_URL}/char_icons/terry.png`,
  "Toon Link": `${process.env.PUBLIC_URL}/char_icons/toon_link.png`,
  Villager: `${process.env.PUBLIC_URL}/char_icons/villager.png`,
  Wario: `${process.env.PUBLIC_URL}/char_icons/wario.png`,
  "Wii Fit Trainer": `${process.env.PUBLIC_URL}/char_icons/wii_fit_trainer.png`,
  Wolf: `${process.env.PUBLIC_URL}/char_icons/wolf.png`,
  Yoshi: `${process.env.PUBLIC_URL}/char_icons/yoshi.png`,
  "Young Link": `${process.env.PUBLIC_URL}/char_icons/young_link.png`,
  Zelda: `${process.env.PUBLIC_URL}/char_icons/zelda.png`,
  "Zero Suit Samus": `${process.env.PUBLIC_URL}/char_icons/zero_suit_samus.png`,

  // Melody Melee

  "Bjørn Ironstrings": `${process.env.PUBLIC_URL}/char_icons/mm/bjorn.png`,
  Octavia: `${process.env.PUBLIC_URL}/char_icons/mm/octavia.png`,
  Demonix: `${process.env.PUBLIC_URL}/char_icons/mm/demonix.png`,
};

export const charRenders = {
  Altaïr: `${process.env.PUBLIC_URL}/char_renders/altair.png`,
  "Banjo & Kazooie": `${process.env.PUBLIC_URL}/char_renders/banjo_kazooie.png`,
  Bayonetta: `${process.env.PUBLIC_URL}/char_renders/bayonetta.png`,
  Bowser: `${process.env.PUBLIC_URL}/char_renders/bowser.png`,
  "Bowser Jr.": `${process.env.PUBLIC_URL}/char_renders/bowser_jr.png`,
  Byleth: `${process.env.PUBLIC_URL}/char_renders/byleth.png`,
  "Captain Falcon": `${process.env.PUBLIC_URL}/char_renders/captain_falcon.png`,
  Charizard: `${process.env.PUBLIC_URL}/char_renders/pokemon_trainer.png`,
  Chrom: `${process.env.PUBLIC_URL}/char_renders/chrom.png`,
  Cloud: `${process.env.PUBLIC_URL}/char_renders/cloud.png`,
  Corrin: `${process.env.PUBLIC_URL}/char_renders/corrin.png`,
  Cuphead: `${process.env.PUBLIC_URL}/char_renders/cuphead.png`,
  Daisy: `${process.env.PUBLIC_URL}/char_renders/daisy.png`,
  "Dark Pit": `${process.env.PUBLIC_URL}/char_renders/dark_pit.png`,
  "Dark Samus": `${process.env.PUBLIC_URL}/char_renders/dark_samus.png`,
  "Diddy Kong": `${process.env.PUBLIC_URL}/char_renders/diddy_kong.png`,
  "Donkey Kong": `${process.env.PUBLIC_URL}/char_renders/donkey_kong.png`,
  "Dr. Mario": `${process.env.PUBLIC_URL}/char_renders/dr_mario.png`,
  "Duck Hunt Duo": `${process.env.PUBLIC_URL}/char_renders/duck_hunt.png`,
  Falco: `${process.env.PUBLIC_URL}/char_renders/falco.png`,
  Fox: `${process.env.PUBLIC_URL}/char_renders/fox.png`,
  Ganondorf: `${process.env.PUBLIC_URL}/char_renders/ganondorf.png`,
  Greninja: `${process.env.PUBLIC_URL}/char_renders/greninja.png`,
  Hero: `${process.env.PUBLIC_URL}/char_renders/hero.png`,
  "Ice Climbers": `${process.env.PUBLIC_URL}/char_renders/ice_climbers.png`,
  Ike: `${process.env.PUBLIC_URL}/char_renders/ike.png`,
  Incineroar: `${process.env.PUBLIC_URL}/char_renders/incineroar.png`,
  Inkling: `${process.env.PUBLIC_URL}/char_renders/inkling.png`,
  Isabelle: `${process.env.PUBLIC_URL}/char_renders/isabelle.png`,
  Ivysaur: `${process.env.PUBLIC_URL}/char_renders/pokemon_trainer.png`,
  Jigglypuff: `${process.env.PUBLIC_URL}/char_renders/jigglypuff.png`,
  Joker: `${process.env.PUBLIC_URL}/char_renders/joker.png`,
  Kazuya: `${process.env.PUBLIC_URL}/char_renders/kazuya.png`,
  Ken: `${process.env.PUBLIC_URL}/char_renders/ken.png`,
  "King Dedede": `${process.env.PUBLIC_URL}/char_renders/king_dedede.png`,
  "King K. Rool": `${process.env.PUBLIC_URL}/char_renders/king_k_rool.png`,
  Kirby: `${process.env.PUBLIC_URL}/char_renders/kirby.png`,
  Link: `${process.env.PUBLIC_URL}/char_renders/link.png`,
  "Little Mac": `${process.env.PUBLIC_URL}/char_renders/little_mac.png`,
  Lucario: `${process.env.PUBLIC_URL}/char_renders/lucario.png`,
  Lucas: `${process.env.PUBLIC_URL}/char_renders/lucas.png`,
  Lucina: `${process.env.PUBLIC_URL}/char_renders/lucina.png`,
  Luigi: `${process.env.PUBLIC_URL}/char_renders/luigi.png`,
  Mario: `${process.env.PUBLIC_URL}/char_renders/mario.png`,
  Marth: `${process.env.PUBLIC_URL}/char_renders/marth.png`,
  "Mega Man": `${process.env.PUBLIC_URL}/char_renders/mega_man.png`,
  "Meta Knight": `${process.env.PUBLIC_URL}/char_renders/meta_knight.png`,
  Mewtwo: `${process.env.PUBLIC_URL}/char_renders/mewtwo.png`,
  "Mii Brawler": `${process.env.PUBLIC_URL}/char_renders/mii_brawler.png`,
  "Mii Gunner": `${process.env.PUBLIC_URL}/char_renders/mii_gunner.png`,
  "Mii Swordfighter": `${process.env.PUBLIC_URL}/char_renders/mii_swordfighter.png`,
  "Min Min": `${process.env.PUBLIC_URL}/char_renders/min_min.png`,
  "Mr. Game & Watch": `${process.env.PUBLIC_URL}/char_renders/mr_game_and_watch.png`,
  Ness: `${process.env.PUBLIC_URL}/char_renders/ness.png`,
  Olimar: `${process.env.PUBLIC_URL}/char_renders/olimar.png`,
  "PAC-MAN": `${process.env.PUBLIC_URL}/char_renders/pac_man.png`,
  Palutena: `${process.env.PUBLIC_URL}/char_renders/palutena.png`,
  Peach: `${process.env.PUBLIC_URL}/char_renders/princesses.png`,
  Pichu: `${process.env.PUBLIC_URL}/char_renders/pichu.png`,
  Pikachu: `${process.env.PUBLIC_URL}/char_renders/pikachu.png`,
  "Piranha Plant": `${process.env.PUBLIC_URL}/char_renders/piranha_plant.png`,
  Pit: `${process.env.PUBLIC_URL}/char_renders/pits.png`,
  "Pokemon Trainer": `${process.env.PUBLIC_URL}/char_renders/pokemon_trainer.png`,
  "Pyra / Mythra": `${process.env.PUBLIC_URL}/char_renders/pyra_mythra.png`,
  Ridley: `${process.env.PUBLIC_URL}/char_renders/ridley.png`,
  Belmonts: `${process.env.PUBLIC_URL}/char_renders/belmonts.png`,
  "R.O.B.": `${process.env.PUBLIC_URL}/char_renders/rob.png`,
  Robin: `${process.env.PUBLIC_URL}/char_renders/robin.png`,
  "Rosalina & Luma": `${process.env.PUBLIC_URL}/char_renders/rosalina_and_luma.png`,
  Roy: `${process.env.PUBLIC_URL}/char_renders/roy.png`,
  Ryu: `${process.env.PUBLIC_URL}/char_renders/ryu.png`,
  Samus: `${process.env.PUBLIC_URL}/char_renders/samuses.png`,
  Sans: `${process.env.PUBLIC_URL}/char_renders/sans.png`,
  Sephiroth: `${process.env.PUBLIC_URL}/char_renders/sephiroth.png`,
  Sheik: `${process.env.PUBLIC_URL}/char_renders/sheik.png`,
  Shulk: `${process.env.PUBLIC_URL}/char_renders/shulk.png`,
  Simon: `${process.env.PUBLIC_URL}/char_renders/simon.png`,
  Snake: `${process.env.PUBLIC_URL}/char_renders/snake.png`,
  Sonic: `${process.env.PUBLIC_URL}/char_renders/sonic.png`,
  Sora: `${process.env.PUBLIC_URL}/char_renders/sora.png`,
  Squirtle: `${process.env.PUBLIC_URL}/char_renders/pokemon_trainer.png`,
  Steve: `${process.env.PUBLIC_URL}/char_renders/steve.png`,
  Terry: `${process.env.PUBLIC_URL}/char_renders/terry.png`,
  "Toon Link": `${process.env.PUBLIC_URL}/char_renders/toon_link.png`,
  Villager: `${process.env.PUBLIC_URL}/char_renders/villager.png`,
  Wario: `${process.env.PUBLIC_URL}/char_renders/wario.png`,
  "Wii Fit Trainer": `${process.env.PUBLIC_URL}/char_renders/wii_fit_trainer.png`,
  Wolf: `${process.env.PUBLIC_URL}/char_renders/wolf.png`,
  Yoshi: `${process.env.PUBLIC_URL}/char_renders/yoshi.png`,
  "Young Link": `${process.env.PUBLIC_URL}/char_renders/young_link.png`,
  Zelda: `${process.env.PUBLIC_URL}/char_renders/zelda.png`,
  "Zero Suit Samus": `${process.env.PUBLIC_URL}/char_renders/zero_suit_samus.png`,

  // Melody Melee

  "Bjørn Ironstrings": `${process.env.PUBLIC_URL}/char_renders/mm/bjorn.png`,
  Octavia: `${process.env.PUBLIC_URL}/char_renders/mm/octavia.png`,
  Demonix: `${process.env.PUBLIC_URL}/char_renders/mm/demonix_cut.png`,
};
