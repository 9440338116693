import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    ReferenceLine,
} from "recharts";

import {
    getData,
    fighterStatGrade,
    weightClass,
    fighterHypeGrade,
} from "../../util";
import { charIcons, charRenders } from "../../data/assets";

import "./Fighter.scss";
import PageHeader from "../../components/page-header/PageHeader";
import { Abilities } from "../../data/Abilities";

class Fighter extends Component {
    chartData(fighter, fighterRanking) {
        const fighters = getData("fighters");
        const settings = getData("settings");

        const immersiveStatMode = settings.statMode.indexOf("immersive") > -1;
        let history = fighter.history;
        if (immersiveStatMode) {
            history = history.slice(0, history.length - 1);
        }

        return history.map((item, index) => {
            if (item.position === "X") {
                return {
                    season: `S${index + 1}`,
                    "Rank #": null,
                    icon: null,
                };
            }
            return {
                season: `S${index + 1}`,
                "Rank #":
                    item.position !== null ? item.position + 1 : fighterRanking,
                icon: charIcons[fighter.name],
            };
        });
    }

    render() {
        const time = getData("time");
        const fighterId = this.props.match.params.id;
        const settings = getData("settings");
        const followedPlayers = getData("followedPlayers");
        const players = getData("players")
            .sort((a, b) => (a.seed > b.seed ? -1 : 1))
            .slice(0, 128);

        const mains = players.filter((p) => p.main === fighterId);
        const fighters = getData("fighters").sort((a, b) =>
            a.seed > b.seed ? -1 : 1
        );
        const fighter = fighters.find((f) => f.id === fighterId);
        let fighterRanking = fighters.findIndex((p) => p.id === fighterId) + 1;
        const immersiveStatMode = settings.statMode.indexOf("immersive") > -1;
        let fighterSeed = fighter.seed.toFixed(2);
        const statVisible =
            !immersiveStatMode ||
            (immersiveStatMode &&
                fighterStatGrade("neutral", fighter.history) !== "?");
        if (immersiveStatMode && statVisible) {
            fighterRanking =
                fighter.history[fighter.history.length - 2].position + 1;

            fighterSeed = fighter.history[fighter.history.length - 2].seed;
        }

        const fighterAbility = Abilities.find(
            (a) => a.name === fighter.ability
        );

        return (
            <div className="fighter-container">
                <PageHeader>
                    {fighter.name}
                    <img src={charRenders[fighter.name]} className="render" />
                </PageHeader>
                <div className="stats">
                    <dl className="seed">
                        <dt>Rank #{statVisible ? fighterRanking : "?"}</dt>
                        <dd>[Seed {statVisible ? fighterSeed : "?"}]</dd>
                    </dl>

                    <div className="score-history">
                        {statVisible && (
                            <LineChart
                                margin={{
                                    top: 15,
                                    left: 15,
                                    right: 15,
                                    bottom: 15,
                                }}
                                data={this.chartData(fighter, fighterRanking)}
                                height={240}
                                width={320}
                            >
                                <XAxis
                                    dataKey="season"
                                    tick={{ fontSize: 14 }}
                                />
                                <ReferenceLine
                                    x="S1"
                                    yAxisId="left"
                                    stroke="#666666"
                                />
                                {fighter.history.map((item, index) => {
                                    if (
                                        item.balance &&
                                        item.balance.indexOf("-") === 0
                                    ) {
                                        return (
                                            <ReferenceLine
                                                x={`S${index + 1}`}
                                                stroke="#f00"
                                                strokeDasharray="3 3"
                                                yAxisId="left"
                                            />
                                        );
                                    } else if (
                                        item.balance &&
                                        item.balance.indexOf("+") === 0
                                    ) {
                                        return (
                                            <ReferenceLine
                                                x={`S${index + 1}`}
                                                stroke="#0f0"
                                                strokeDasharray="3 3"
                                                yAxisId="left"
                                            />
                                        );
                                    }
                                })}
                                <YAxis
                                    yAxisId="left"
                                    tick={{ fontSize: 10 }}
                                    width={20}
                                    ticks={[1, fighters.length]}
                                    reversed
                                    domain={[1, fighters.length]}
                                    orientation="right"
                                />
                                <Tooltip />
                                <Line
                                    type="monotone"
                                    dataKey="Rank #"
                                    stroke="#62c89eff"
                                    isAnimationActive={false}
                                    dot={<StockIcon />}
                                    yAxisId="left"
                                />
                            </LineChart>
                        )}
                    </div>

                    <dl>
                        <dt>Neutral options</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade("neutral", fighter.history)
                                : fighter.neutral}
                        </dd>

                        <dt>Damage output</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade("advantage", fighter.history)
                                : fighter.advantage}
                        </dd>

                        <dt>Projectiles</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade(
                                      "projectiles",
                                      fighter.history,
                                      fighter.projectiles
                                  )
                                : fighter.projectiles}
                        </dd>

                        <dt>Mobility</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade("mobility", fighter.history)
                                : fighter.mobility}
                        </dd>

                        <dt>Out of shield</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade(
                                      "outofshield",
                                      fighter.history
                                  )
                                : fighter.outofshield}
                        </dd>

                        <dt>Disadvantage game</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade(
                                      "disadvantage",
                                      fighter.history
                                  )
                                : fighter.disadvantage}
                        </dd>

                        <dt>KO options</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade("punish", fighter.history)
                                : fighter.punish}
                        </dd>

                        <dt>Recovery</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterStatGrade("recovery", fighter.history)
                                : fighter.recovery}
                        </dd>
                    </dl>

                    <dl>
                        <dt>Weight class</dt>
                        <dd>{weightClass(fighter.weight)}</dd>

                        <dt>Fighter type</dt>
                        <dd>{fighter.type}</dd>

                        <dt>Hypeness</dt>
                        <dd>
                            {immersiveStatMode
                                ? fighterHypeGrade(fighter.hype)
                                : fighter.hype}
                        </dd>
                    </dl>

                    {fighterAbility && (
                        <div className="fighter-ability">
                            <h3>
                                <span>{fighterAbility.type} ability:</span>{" "}
                                {fighterAbility.name}
                            </h3>
                            <p>{fighterAbility.description}</p>
                        </div>
                    )}

                    <h3>Notable mains</h3>
                    <dl className="mains">
                        {mains.map((player) => {
                            const isFollowing = followedPlayers.find(
                                (p) => p.id === player.id
                            );
                            return (
                                <>
                                    <dt>
                                        <Link
                                            to={`/players/${player.id}`}
                                            className={
                                                isFollowing ? "following" : ""
                                            }
                                        >
                                            {player.name}
                                        </Link>
                                        <img src={player.flag} />
                                    </dt>
                                    <dd>
                                        WR #
                                        {players.findIndex(
                                            (pl) => pl.id === player.id
                                        ) + 1}
                                        <span className="seed">
                                            [{player.seed.toFixed(2)}]
                                        </span>
                                    </dd>
                                </>
                            );
                        })}
                    </dl>
                </div>
            </div>
        );
    }
}

const StockIcon = (props) => {
    const { cx, cy, payload } = props;

    return (
        <image
            href={payload.icon}
            height="24"
            width="24"
            x={cx - 12}
            y={cy - 12}
        />
    );
};

export default Fighter;
