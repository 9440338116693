import React, { Component } from "react";
import { Link } from "react-router-dom";
import Switcher from "../../components/switcher/Switcher";
import VideoThumbnail from "../../components/video-thumbnail/VideoThumbnail";
import { getData } from "../../util";

class PlayTubeUploads extends Component {
  render() {
    const uploadHistory = getData("playTubeUploadHistory");

    return (
      <div className="playtube-container">
        <Switcher>
          <span className="active">Uploads</span>
          <Link to="/playtube/history">
            <span>Watch history</span>
          </Link>
        </Switcher>

        {uploadHistory.slice(0, 25).map((video) => (
          <VideoThumbnail
            quality={video.quality}
            streamer={video.streamer}
            title={video.title}
            fighter={video.fighter}
            thumbnail={video.thumbnail}
          />
        ))}
      </div>
    );
  }
}

export default PlayTubeUploads;
