import React from "react";
import { Link } from "react-router-dom";

import "./Button.scss";

class Button extends React.Component {
    onClick() {
        this.props.onClick();
    }

    render() {
        const {
            primary,
            isExternal,
            href,
            children,
            onClick,
            disabled,
        } = this.props;

        let className = "button";
        if (primary) className += " primary";
        if (disabled) className += " disabled";

        if (onClick) {
            return (
                <span className={className} onClick={this.onClick.bind(this)}>
                    {children}
                </span>
            );
        }

        return (
            <Link
                to={`${process.env.PUBLIC_URL}${href}`}
                className={primary ? "button primary" : "button"}
                target={isExternal ? "_blank" : ""}
            >
                {children}
            </Link>
        );
    }
}

export default Button;
