import React, { Component } from "react";

import { getData } from "../../util";
import { charIcons, charRenders } from "../../data/assets";
import Loader from "../loader/Loader";
import Button from "../button/Button";

import "./MatchRenderer.scss";
import Dropdown from "../dropdown/Dropdown";
import { STRATEGIES } from "../../data/Strategies";
import { MATCH_STATES } from "../../data/MatchStates";
import CharacterSelectScreen from "../character-select-screen/CharacterSelectScreen";

class MatchRenderer extends Component {
  state = {
    introScreen: true,
    skipping: false,
  };

  skip() {
    const { match } = this.props;

    match.skip();
  }

  changeStrategy(strategy, value) {
    const { match } = this.props;

    match.selectedStrategyActions[strategy] = value;
  }

  abilityActions(ability, value) {
    const { match } = this.props;

    const humanPlayer = getData("humanPlayer");
    const playerOne = match.playerOne;
    const playerTwo = match.playerTwo;

    let playerPerformingAction = null;
    if (humanPlayer) {
      if (humanPlayer.id === playerOne.id) playerPerformingAction = playerOne;
      else if (humanPlayer.id === playerTwo.id)
        playerPerformingAction = playerTwo;
    }

    match.activeAbility(ability, value, playerPerformingAction);
  }

  chooseCharacter(fighter) {
    const { match } = this.props;

    const fighters = getData("fighters");
    const humanPlayer = getData("humanPlayer");
    const playerOne = match.playerOne;
    const playerTwo = match.playerTwo;

    const opponentPlaysMain = Math.random() < 0.7;
    const alternativeOpponentFighter =
      fighters[Math.floor(Math.random() * fighters.length)];

    if (humanPlayer.id === playerOne.id) {
      const opponentFighter = opponentPlaysMain
        ? fighters.find((f) => f.id === playerTwo.main)
        : alternativeOpponentFighter;
      match.changeFighters(fighter, opponentFighter);
    } else if (humanPlayer.id === playerTwo.id) {
      const opponentFighter = opponentPlaysMain
        ? fighters.find((f) => f.id === playerOne.main)
        : alternativeOpponentFighter;
      match.changeFighters(opponentFighter, fighter);
    }
  }

  beginMatch() {
    const { match } = this.props;

    this.setState({
      introScreen: false,
    });
    match.play();
  }

  showIntroScreen() {
    const { match } = this.props;

    match.showCSS = false;
  }

  render() {
    const { introScreen } = this.state;
    const { match } = this.props;

    if (!match) return <Loader />;

    if (match.fighterFlexible && match.showCSS) {
      return (
        <div className="match-renderer">
          <div>
            <CharacterSelectScreen
              onCharacterSelect={this.chooseCharacter.bind(this)}
            />

            <Button onClick={this.showIntroScreen.bind(this)}>Choose</Button>
          </div>
        </div>
      );
    }

    const humanPlayer = getData("humanPlayer");
    const playerOne = match.playerOne;
    const playerTwo = match.playerTwo;

    const fighterOne = match.fighterOne;
    const fighterTwo = match.fighterTwo;

    const fighterOneStocks = [];
    for (let i = 0; i < match.playerOneStocks; i++)
      fighterOneStocks.push(
        <img src={charIcons[fighterOne.name]} key={`p1${i}`} />
      );
    const fighterTwoStocks = [];
    for (let i = 0; i < match.playerTwoStocks; i++)
      fighterTwoStocks.push(
        <img src={charIcons[fighterTwo.name]} key={`p2${i}`} />
      );

    const fighterOneCSSName = fighterOne.name
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/\&/g, "_")
      .replace(/\\/g, "_");
    const fighterTwoCSSName = fighterTwo.name
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/\&/g, "_")
      .replace(/\\/g, "_");

    const humanPlayerAbilityInUse =
      humanPlayer && humanPlayer.id === playerOne.id
        ? match.playerOneUsesAbility
        : match.playerTwoUsesAbility;

    const playerOneNeutralStrategy =
      humanPlayer && humanPlayer.id === playerOne.id
        ? match.selectedStrategyActions[STRATEGIES.NEUTRAL]
        : playerOne.neutralStrategy;

    const playerTwoNeutralStrategy =
      humanPlayer && humanPlayer.id === playerTwo.id
        ? match.selectedStrategyActions[STRATEGIES.NEUTRAL]
        : playerTwo.neutralStrategy;

    const playerOneEdgeguardingStrategy =
      humanPlayer && humanPlayer.id === playerOne.id
        ? match.selectedStrategyActions[STRATEGIES.EDGEGUARDING]
        : match.playerOneEdgeguardingStrategy;

    const playerOneStrategyClass = `strategy-${playerOneNeutralStrategy
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/\./g, "")
      .replace(/\&/g, "_")
      .replace(/\\/g, "_")}`;
    const playerTwoStrategyClass = `strategy-${playerTwoNeutralStrategy
      .toLowerCase()
      .replace(/ /g, "_")
      .replace(/\./g, "")
      .replace(/\&/g, "_")
      .replace(/\\/g, "_")}`;

    const playerTwoEdgeguardingStrategy =
      humanPlayer && humanPlayer.id === playerTwo.id
        ? match.selectedStrategyActions[STRATEGIES.EDGEGUARDING]
        : match.playerTwoEdgeguardingStrategy;

    let fighterOneEdgeguardingClass = "";
    let fighterTwoEdgeguardingClass = "";
    if (match.playerOneState === MATCH_STATES.EDGEGUARDING)
      fighterOneEdgeguardingClass = `edgeguarding-${playerOneEdgeguardingStrategy
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/\./g, "")
        .replace(/\&/g, "_")
        .replace(/\\/g, "_")}`;
    if (match.playerTwoState === MATCH_STATES.EDGEGUARDING)
      fighterTwoEdgeguardingClass = `edgeguarding-${playerTwoEdgeguardingStrategy
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/\./g, "")
        .replace(/\&/g, "_")
        .replace(/\\/g, "_")}`;

    if (introScreen) {
      return (
        <div className="match-renderer">
          <div>
            <div className="intro-screen">
              <h2>
                {match.tournamentName} {match.roundName}
              </h2>
              <div className="players">
                <div className="player">
                  <div className="fighter-portrait">
                    <img src={charRenders[match.fighterOne.name]} />
                  </div>
                  <div className="player-details">
                    <img src={match.playerOne.avatar} />
                    <p>{match.playerOne.name}</p>
                  </div>
                </div>
                <div className="player">
                  <div className="fighter-portrait">
                    <img src={charRenders[match.fighterTwo.name]} />
                  </div>
                  <div className="player-details">
                    <img src={match.playerTwo.avatar} />
                    <p>{match.playerTwo.name}</p>
                  </div>
                </div>
              </div>

              <Button onClick={this.beginMatch.bind(this)}>Begin</Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="match-renderer">
        <div>
          <h2>
            {match.tournamentName} {match.roundName}
          </h2>
          <div className="stage">
            {match.showGameStart && <h1>GO!</h1>}
            {match.showGameEnd && <h1>GAME!</h1>}

            <img
              className={`fighter fighter-one fighter-${
                match.playerOneState
              } fighter-${fighterOneCSSName} opponent-${fighterTwoCSSName} ${
                match.playerOneBonus ? "bonus" : ""
              } ${fighterOneEdgeguardingClass} ${fighterTwoEdgeguardingClass}
                            ${playerOneStrategyClass} opponent-${playerTwoStrategyClass}`}
              src={charIcons[fighterOne.name]}
            />
            <img
              className={`fighter fighter-two fighter-${
                match.playerTwoState
              } fighter-${fighterTwoCSSName} opponent-${fighterOneCSSName} ${
                match.playerTwoBonus ? "bonus" : ""
              } ${fighterTwoEdgeguardingClass} ${fighterOneEdgeguardingClass}
                            ${playerTwoStrategyClass} opponent-${playerOneStrategyClass}`}
              src={charIcons[fighterTwo.name]}
            />

            <div className="effects">
              <div
                className={`fighter-one-effects fighter-${match.playerOneState}-effects fighter-${fighterOneCSSName}-effects ${fighterOneEdgeguardingClass} 
                                ${playerOneStrategyClass}-effects charge-${match.playerOneCharge} opponent-${fighterTwoCSSName}-effects opponent-${playerTwoStrategyClass}-effects`}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <div
                className={`fighter-two-effects fighter-${match.playerTwoState}-effects fighter-${fighterTwoCSSName}-effects ${fighterTwoEdgeguardingClass}
                                ${playerTwoStrategyClass}-effects charge-${match.playerTwoCharge} opponent-${fighterOneCSSName}-effects opponent-${playerOneStrategyClass}-effects`}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div className="platform"></div>
          </div>

          <div className="hud">
            <div className="player-one">
              <span className="strategy">[{playerOneNeutralStrategy}]</span>
              <p>{match.playerOne.name}</p>
              <div className="damage">{match.playerOneDamage.toFixed(1)}%</div>
              <div className="stocks">{fighterOneStocks}</div>
            </div>

            <div className="player-two">
              <span className="strategy">[{playerTwoNeutralStrategy}]</span>
              <p>{match.playerTwo.name}</p>
              <div className="damage">{match.playerTwoDamage.toFixed(1)}%</div>
              <div className="stocks">{fighterTwoStocks}</div>
            </div>
          </div>

          <div className="commentary">
            <p>
              {match.commentary[match.commentary.length - 1]}
              &nbsp;
            </p>
          </div>

          {humanPlayer &&
            ((humanPlayer.id === playerOne.id && match.fighterOneAbility) ||
              (humanPlayer.id === playerTwo.id && match.fighterTwoAbility)) && (
              <div className="actions ability">
                <h3>Current ability</h3>
                <p>
                  {humanPlayer.id === playerOne.id
                    ? match.fighterOneAbility.name
                    : match.fighterTwoAbility.name}
                  {humanPlayer.id === playerOne.id
                    ? fighterOne.name === "Kirby"
                      ? " [Copied]"
                      : ""
                    : fighterTwo.name === "Kirby"
                    ? " [Copied]"
                    : ""}
                </p>
              </div>
            )}

          {match.strategyActions && match.strategyActions.length > 0 && (
            <div className="actions">
              <h3>Strategy</h3>
              {match.strategyActions.map((strategy) => (
                <p>
                  {strategy.label}
                  <Dropdown
                    onChange={this.changeStrategy.bind(this, strategy.label)}
                    options={strategy.values.map((value) => ({
                      value,
                      display: value,
                    }))}
                    id={strategy.label}
                  ></Dropdown>
                </p>
              ))}
            </div>
          )}
          {match.abilityActions && match.abilityActions.length > 0 && (
            <div className="actions">
              <h3>In-play actions</h3>
              {match.abilityActions.map((ability) => {
                const charge =
                  humanPlayer && humanPlayer.id === playerOne.id
                    ? match.playerOneCharge
                    : match.playerTwoCharge;

                return (
                  <fieldset key={ability.label}>
                    <label>{ability.label}</label>
                    {ability.options.map((option) => {
                      let label = option.label;
                      const requiresCharge = label.indexOf("{{charge}}") > -1;
                      if (requiresCharge) {
                        if (charge === 0) {
                          label = label.replace(/{{charge}}/g, "Uncharged");
                        } else {
                          label = label.replace(
                            /{{charge}}/g,
                            `Level ${charge}`
                          );
                        }
                      }

                      return (
                        <Button
                          key={`${ability.label}${option.value}`}
                          primary
                          disabled={
                            humanPlayerAbilityInUse !== null ||
                            (requiresCharge && parseInt(charge) === 0)
                          }
                          onClick={this.abilityActions.bind(
                            this,
                            ability.label,
                            option.value
                          )}
                        >
                          {label}
                        </Button>
                      );
                    })}
                  </fieldset>
                );
              })}
            </div>
          )}

          {match.skipping ? (
            <Loader small />
          ) : (
            <Button onClick={this.skip.bind(this)}>Skip</Button>
          )}
        </div>
      </div>
    );
  }
}

export default MatchRenderer;
