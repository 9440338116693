import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../components/page-header/PageHeader";
import { getData } from "../../util";

import "./Sponsors.scss";
class Sponsors extends Component {
    render() {
        const sponsors = getData("sponsors");
        const players = getData("players");
        const humanPlayer = getData("humanPlayer");

        return (
            <div className="sponsors-container">
                <PageHeader>Sponsors</PageHeader>

                {Object.keys(sponsors).map((sponsorKey) => {
                    const sponsor = sponsors[sponsorKey];

                    return (
                        <div className="sponsor">
                            <img src={sponsor.emblem} />
                            <div className="sponsor-details">
                                <h2>{sponsor.name}</h2>
                                <p>{sponsor.description}</p>

                                <dl>
                                    <dt>Values</dt>
                                    <dd>{sponsor.values}</dd>

                                    <dt>Salary offered</dt>
                                    <dd>{(sponsor.salary / 10).toFixed(2)}</dd>

                                    <dt>Open slots</dt>
                                    <dd>
                                        {sponsor.slots - sponsor.players.length}
                                    </dd>

                                    <dt>Current players</dt>
                                    <dd>
                                        {sponsor.players.length === 0 && <>-</>}
                                        {sponsor.players.map((playerId) => {
                                            const player = players.find(
                                                (p) => p.id === playerId
                                            );
                                            const isHuman =
                                                humanPlayer &&
                                                humanPlayer.id === player.id;
                                            return (
                                                <>
                                                    <Link
                                                        className={
                                                            isHuman
                                                                ? "human"
                                                                : ""
                                                        }
                                                        to={`/players/${player.id}`}
                                                    >
                                                        {player.name}
                                                        <img
                                                            src={player.flag}
                                                        />
                                                    </Link>
                                                    <br />
                                                </>
                                            );
                                        })}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Sponsors;
