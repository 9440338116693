import { getData } from "../util";
import { MATCH_STATES } from "../data/MatchStates";

const MODIFIER = 40;
const INTERVAL = 1;

export class Match {
    constructor(playerOne, playerTwo, onFinish) {
        this.complete = false;

        this.playerOne = playerOne;
        this.playerTwo = playerTwo;

        const fighters = getData("fighters");
        this.fighterOne = fighters.find(
            (fighter) => playerOne.main === fighter.id
        );
        this.fighterTwo = fighters.find(
            (fighter) => playerTwo.main === fighter.id
        );

        this.playerOneStocks = 3;
        this.playerTwoStocks = 3;

        this.playerOneDamage = 0;
        this.playerTwoDamage = 0;

        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.NEUTRAL;

        this.onFinish = onFinish;
    }

    play() {
        if (
            this.playerOneState === MATCH_STATES.NEUTRAL ||
            this.playerTwoState === MATCH_STATES.NEUTRAL
        ) {
            this.neutralPhase();
        } else if (
            this.playerOneState === MATCH_STATES.ADVANTAGE ||
            this.playerTwoState === MATCH_STATES.ADVANTAGE
        ) {
            this.advantagePhase();
        } else if (
            this.playerOneState === MATCH_STATES.VULNERABLE ||
            this.playerTwoState === MATCH_STATES.VULNERABLE
        ) {
            this.punishPhase();
        }

        /*console.log(
            `${this.playerOne.name} [${
                this.fighterOne.name
            }]: ${this.playerOneDamage.toFixed(2)}%`
        );
        console.log(
            `${this.playerTwo.name} [${
                this.fighterTwo.name
            }]: ${this.playerTwoDamage.toFixed(2)}%`
        );*/
    }

    neutralPhase() {
        // console.log("----- neutral -----");

        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.NEUTRAL;

        let playerOneNeutral =
            Math.random() * MODIFIER +
            this.playerOne.neutral +
            this.fighterOne.neutral;
        let playerTwoNeutral =
            Math.random() * MODIFIER +
            this.playerTwo.neutral +
            this.fighterTwo.neutral;

        if (playerOneNeutral > playerTwoNeutral) {
            this.playerTwoDamage += playerOneNeutral - playerTwoNeutral;
            this.playerOneState = MATCH_STATES.ADVANTAGE;
            this.playerTwoState = MATCH_STATES.DISADVANTAGE;
        } else if (playerOneNeutral < playerTwoNeutral) {
            this.playerOneDamage += playerTwoNeutral - playerOneNeutral;
            this.playerOneState = MATCH_STATES.DISADVANTAGE;
            this.playerTwoState = MATCH_STATES.ADVANTAGE;
        }

        // setTimeout(() => {
        this.play();
        // }, INTERVAL);
    }

    advantagePhase() {
        // console.log("----- advantage -----");

        let advantage;
        if (this.playerOneState === MATCH_STATES.ADVANTAGE) {
            advantage = this.playerTwoDamage;
            advantage +=
                Math.random() *
                (this.playerOne.advantage + this.fighterOne.advantage);
            advantage -=
                Math.random() *
                (this.playerTwo.disadvantage + this.fighterTwo.disadvantage);

            this.playerTwoDamage += Math.random() * this.playerOne.neutral;
        } else if (this.playerTwoState === MATCH_STATES.ADVANTAGE) {
            advantage = this.playerOneDamage;
            advantage +=
                Math.random() *
                (this.playerTwo.advantage + this.fighterTwo.advantage);
            advantage -=
                Math.random() *
                (this.playerOne.disadvantage + this.fighterOne.disadvantage);

            this.playerOneDamage += Math.random() * this.playerTwo.neutral;
        }

        if (advantage < 100) {
            this.playerOneState = MATCH_STATES.NEUTRAL;
            this.playerTwoState = MATCH_STATES.NEUTRAL;
        } else {
            if (this.playerOneState === MATCH_STATES.ADVANTAGE) {
                this.playerOneState = MATCH_STATES.PUNISHING;
                this.playerTwoState = MATCH_STATES.VULNERABLE;
            } else if (this.playerTwoState === MATCH_STATES.ADVANTAGE) {
                this.playerOneState = MATCH_STATES.VULNERABLE;
                this.playerTwoState = MATCH_STATES.PUNISHING;
            }
        }

        setTimeout(() => {
            this.play();
        }, INTERVAL);
    }

    punishPhase() {
        // console.log("----- punish -----");

        let punish;
        if (this.playerOneState === MATCH_STATES.VULNERABLE) {
            punish =
                Math.random() *
                (this.playerTwo.punish + this.fighterTwo.punish);
            punish -=
                Math.random() *
                (this.playerOne.technique + this.fighterOne.recovery);
        } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
            punish =
                Math.random() *
                (this.playerOne.punish + this.fighterOne.punish);
            punish -=
                Math.random() *
                (this.playerTwo.technique + this.fighterTwo.recovery);
        }

        if (punish > 0) {
            if (this.playerOneState === MATCH_STATES.VULNERABLE) {
                this.playerOneStocks--;
                this.playerOneDamage = 0;
            } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
                this.playerTwoStocks--;
                this.playerTwoDamage = 0;
            }

            /*console.log(
                `KO!!!! Stocks remaining: ${this.playerOneStocks}::${this.playerTwoStocks}`
            );*/
        }

        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.NEUTRAL;

        if (this.playerOneStocks === 0 || this.playerTwoStocks === 0) {
            this.finish();
        } else {
            setTimeout(() => {
                this.play();
            }, INTERVAL);
        }
    }

    finish() {
        this.complete = true;

        if (this.playerOneStocks > this.playerTwoStocks) {
            this.onFinish(this.playerOne.id, this.playerOneStocks);
        } else {
            this.onFinish(this.playerTwo.id, this.playerTwoStocks);
        }
    }
}
