import React, { Component } from "react";
import Button from "../button/Button";

import "./HelpModal.scss";

class HelpModal extends Component {
    state = {
        open: false,
    };

    toggle() {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        const { children } = this.props;
        const { open } = this.state;

        return (
            <>
                <span
                    className="help-modal-toggler"
                    onClick={this.toggle.bind(this)}
                >
                    ?
                </span>
                <div
                    className={
                        open ? "help-modal-mask open" : "help-modal-mask"
                    }
                />
                <div className={open ? "help-modal open" : "help-modal"}>
                    {children}
                    <Button onClick={this.toggle.bind(this)} primary>
                        OK
                    </Button>
                </div>
            </>
        );
    }
}

export default HelpModal;
