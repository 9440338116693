import React, { Component } from "react";

import RoundView from "../../components/round-view/RoundView";
import { getData } from "../../util";
import Loader from "../loader/Loader";
import MatchView from "../match-view/MatchView";
import "./QuickMatchView.scss";

class QuickMatchView extends Component {
  state = {
    selectedTournament: 10000,
    match: null,
  };

  toggleRegion(regionId) {
    this.setState({
      selectedTournament: regionId,
    });
  }

  latestMatch() {
    const { tournaments } = this.props;
    const { selectedTournament } = this.state;

    const humanPlayer = getData("humanPlayer");
    const followedPlayers = getData("followedPlayers");
    const players = getData("players");
    const fighters = getData("fighters");
    const regions = getData("regions");
    const settings = getData("settings");

    let matchToShow = null;
    let region = "";
    let totalRounds = 0;
    let remainingRounds = 0;

    let showFollowedOnly = false;

    tournaments.forEach((tournament) => {
      const containsFollowedPlayers = tournament.players.filter((player) =>
        followedPlayers.map((p) => p.id).includes(player.id)
      );
      if (containsFollowedPlayers.length > 0) showFollowedOnly = true;
    });

    if (settings.matchEngine.indexOf("r") > -1) {
      showFollowedOnly = false;
    }

    tournaments.forEach((tournament) => {
      const containsFollowedPlayers = tournament.players
        .filter((player) =>
          followedPlayers.map((p) => p.id).includes(player.id)
        )
        .map((p) => p.id);

      tournament.rounds
        .sort((a, b) => (a.length > b.length ? -1 : 1))
        .forEach((round) => {
          round
            .filter(
              (roundMatch) =>
                !roundMatch.match ||
                (roundMatch.match && roundMatch.match.complete)
            )
            .forEach((match) => {
              totalRounds++;

              if (tournament.region !== selectedTournament) return;

              if (match.scoreA !== null && match.scoreB !== null) {
                if (round.name === "Final") {
                  matchToShow = match;

                  region = regions.find(
                    (region) => tournament.region === region.id
                  ).tournamentName;
                } else if (
                  showFollowedOnly &&
                  (containsFollowedPlayers.includes(match.competitorA) ||
                    containsFollowedPlayers.includes(match.competitorB))
                ) {
                  if (!matchToShow) {
                    matchToShow = match;

                    region = regions.find(
                      (region) => tournament.region === region.id
                    ).tournamentName;
                  } else if (match.timestamp > matchToShow.timestamp) {
                    matchToShow = match;

                    region = regions.find(
                      (region) => tournament.region === region.id
                    ).tournamentName;
                  }
                } else if (!showFollowedOnly) {
                  matchToShow = match;

                  region = regions.find(
                    (region) => tournament.region === region.id
                  ).tournamentName;
                }
              } else {
                remainingRounds++;
              }
            });
        });
    });

    if (!matchToShow) return;
    const competitorA = players.find(
      (player) => player.id === matchToShow.competitorA
    );
    const competitorB = players.find(
      (player) => player.id === matchToShow.competitorB
    );

    const fighterA = competitorA
      ? fighters.find((fighter) => competitorA.main === fighter.id)
      : null;
    const fighterB = competitorB
      ? fighters.find((fighter) => competitorB.main === fighter.id)
      : null;

    const match = (
      <>
        <span
          className="round-progress"
          style={{
            width: 100 - (remainingRounds / totalRounds) * 100 + "%",
          }}
        ></span>
        <div className="quick-match-wrapper">
          <ul className="quick-match-toggler">
            {tournaments.map((t) => (
              <li
                className={t.region === selectedTournament ? "selected" : ""}
                onClick={this.toggleRegion.bind(this, t.region)}
              >
                {
                  regions.find((region) => t.region === region.id)
                    .tournamentName
                }
              </li>
            ))}
          </ul>
          <div>
            <p>
              {region} {matchToShow.name}
            </p>
            <MatchView
              roundMatch={matchToShow}
              fighterA={fighterA}
              fighterB={fighterB}
              competitorA={competitorA}
              competitorB={competitorB}
            />
            <div className="small-loader">
              <Loader />
            </div>
          </div>
        </div>
      </>
    );

    return match;
  }

  render() {
    const { visible } = this.props;

    const settings = getData("settings");

    if (!settings) return null;

    return (
      <div className={visible ? "quick-view visible" : "quick-view"}>
        {this.latestMatch() || <Loader />}
      </div>
    );
  }
}

export default QuickMatchView;
