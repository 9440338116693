import Fighter, { FIGHTER_TYPES } from "../classes/Fighter";

export const Fighters_20XX = [
  new Fighter(
    "Mario",
    {
      neutral: 7,
      advantage: 6,
      disadvantage: 4,
      punish: 6,
      recovery: 4,
      weight: 9,
      projectiles: 6,
      mobility: 6,
      outofshield: 7,
      hype: 2,
      type: FIGHTER_TYPES.RUSHDOWN,
    },
    null,
    "Meme Edgeguarding"
  ),
  new Fighter(
    "Donkey Kong",
    {
      neutral: 5,
      advantage: 7,
      disadvantage: 3,
      punish: 8,
      recovery: 3,
      weight: 12,
      projectiles: 0,
      mobility: 3,
      outofshield: 6,
      hype: 4,
      type: FIGHTER_TYPES.BAIT_PUNISH,
    },
    null,
    "Cargo Throw"
  ),
  new Fighter(
    "Link",
    {
      neutral: 7,
      advantage: 5,
      disadvantage: 4,
      punish: 6,
      recovery: 7,
      weight: 10,
      projectiles: 9,
      mobility: 4,
      outofshield: 7,
      hype: -2,
      type: FIGHTER_TYPES.ZONER,
    },
    null,
    "Remote Bombs"
  ),

  new Fighter(
    "Samus",
    {
      neutral: 8,
      advantage: 4,
      disadvantage: 4,
      punish: 5,
      recovery: 7,
      weight: 10,
      projectiles: 8,
      mobility: 4,
      outofshield: 7,
      type: FIGHTER_TYPES.ZONER,
      hype: -4,
    },
    null,
    "Charge Shot"
  ),
  new Fighter(
    "Kirby",
    {
      neutral: 7,
      advantage: 7,
      disadvantage: 7,
      punish: 7,
      recovery: 10,
      weight: 7,
      projectiles: 1,
      mobility: 5,
      outofshield: 4,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 1,
    },
    null,
    "Copy"
  ),
  new Fighter(
    "Yoshi",
    {
      neutral: 8,
      advantage: 6,
      disadvantage: 7,
      punish: 4,
      recovery: 9,
      weight: 10,
      projectiles: 5,
      mobility: 9,
      outofshield: 4,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: -1,
    },
    null,
    "Double Jump Super Armour"
  ),
  new Fighter(
    "Fox",
    {
      neutral: 9,
      advantage: 8,
      disadvantage: 3,
      punish: 4,
      recovery: 4,
      weight: 7,
      projectiles: 4,
      mobility: 8,
      outofshield: 5,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 4,
    },
    null,
    "Reflector"
  ),
  new Fighter(
    "Pikachu",
    {
      neutral: 8,
      advantage: 8,
      disadvantage: 5,
      punish: 4,
      recovery: 10,
      weight: 7,
      projectiles: 6,
      mobility: 9,
      outofshield: 4,
      hype: 3,
      type: FIGHTER_TYPES.RUSHDOWN,
    },
    null,
    "Quick Attack"
  ),
  new Fighter(
    "Pokemon Trainer",
    {
      neutral: 8,
      advantage: 8,
      disadvantage: 3,
      punish: 7,
      recovery: 5,
      weight: 0,
      projectiles: 7,
      mobility: 7,
      outofshield: 7,
      hype: 1,
      type: FIGHTER_TYPES.DYNAMIC,
    },
    0,
    "Pokemon Switch",
    [
      new Fighter("Squirtle", {
        neutral: 8,
        advantage: 6,
        disadvantage: 2,
        punish: 2,
        recovery: 5,
        weight: 7,
        projectiles: 3,
        mobility: 8,
        outofshield: 5,
        hype: 1,
        type: FIGHTER_TYPES.RUSHDOWN,
      }),
      new Fighter("Ivysaur", {
        neutral: 7,
        advantage: 8,
        disadvantage: 4,
        punish: 7,
        recovery: 4,
        weight: 9,
        projectiles: 7,
        mobility: 5,
        outofshield: 8,
        hype: -2,
        type: FIGHTER_TYPES.ZONER,
      }),
      new Fighter("Charizard", {
        neutral: 5,
        advantage: 7,
        disadvantage: 2,
        punish: 8,
        recovery: 6,
        weight: 11,
        projectiles: 6,
        mobility: 5,
        outofshield: 7,
        hype: 3,
        type: FIGHTER_TYPES.BAIT_PUNISH,
      }),
    ]
  ),
  new Fighter(
    "Captain Falcon",
    {
      neutral: 6,
      advantage: 8,
      disadvantage: 5,
      punish: 9,
      recovery: 5,
      weight: 10,
      projectiles: 0,
      mobility: 8,
      outofshield: 6,
      hype: 5,
      type: FIGHTER_TYPES.RUSHDOWN,
    },
    1,
    "Hype Master"
  ),
  new Fighter(
    "Pichu",
    {
      neutral: 8,
      advantage: 7,
      disadvantage: 2,
      punish: 5,
      recovery: 8,
      weight: 6,
      projectiles: 7,
      mobility: 9,
      outofshield: 4,
      hype: 3,
      type: FIGHTER_TYPES.RUSHDOWN,
    },
    2,
    "Self-Harm"
  ),
  new Fighter(
    "Falco",
    {
      neutral: 7,
      advantage: 7,
      disadvantage: 6,
      punish: 5,
      recovery: 6,
      weight: 8,
      projectiles: 6,
      mobility: 7,
      outofshield: 5,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 3,
    },
    3,
    "Reflector"
  ),
  new Fighter(
    "Diddy Kong",
    {
      neutral: 9,
      advantage: 7,
      disadvantage: 6,
      punish: 5,
      recovery: 4,
      weight: 9,
      projectiles: 8,
      mobility: 8,
      outofshield: 4,
      hype: 2,
      type: FIGHTER_TYPES.RUSHDOWN,
    },
    4,
    "Banana Trap"
  ),
  new Fighter(
    "Lucario",
    {
      neutral: 4,
      advantage: 4,
      disadvantage: 5,
      punish: 6,
      recovery: 7,
      weight: 9,
      projectiles: 6,
      mobility: 4,
      outofshield: 5,
      hype: -4,
      type: FIGHTER_TYPES.ZONER,
    },
    5,
    "Aura"
  ),
];
