export class TournamentRound {
    constructor(id, competitorA, competitorB, name) {
        this.id = id;
        this.competitorA = competitorA;
        this.competitorB = competitorB;
        this.name = name;
        this.scoreA = null;
        this.scoreB = null;
        this.match = null;
    }
}
