import React, { Component } from "react";

import { FIGHTER_TYPE_ICONS_DARK } from "../../classes/Fighter";
import { Abilities } from "../../data/Abilities";
import { charIcons, charRenders } from "../../data/assets";
import {
  getData,
  fighterStatGrade,
  weightClass,
  fighterHypeGrade,
} from "../../util";

import "./CharacterSelectScreen.scss";

class CharacterSelectScreen extends Component {
  FIGHTERS_PER_ROW = 8;

  state = {
    selectedCharacter: null,
  };

  componentDidMount() {
    const humanPlayer = getData("humanPlayer");

    if (humanPlayer) {
      const fighters = getData("fighters");
      const fighter = fighters.find((f) => f.id === humanPlayer.main);

      this.setState({
        selectedCharacter: fighter,
      });
    }
  }

  selectCharacter(character) {
    const { onCharacterSelect } = this.props;

    onCharacterSelect(character);

    this.setState({
      selectedCharacter: character,
    });
  }

  render() {
    const { selectedCharacter } = this.state;

    const fighters = getData("fighters");
    const settings = getData("settings");

    fighters.sort((a, b) => (a.index > b.index ? 1 : -1));

    const immersiveStatMode = settings.statMode.indexOf("immersive") > -1;
    const overflowCount =
      this.FIGHTERS_PER_ROW - (fighters.length % this.FIGHTERS_PER_ROW);
    const overflow = [];

    if (overflowCount < this.FIGHTERS_PER_ROW) {
      for (let i = 0; i < overflowCount; i++) {
        overflow.push(i);
      }
    }

    let fighterAbility = null;
    if (selectedCharacter)
      fighterAbility = Abilities.find(
        (a) => a.name === selectedCharacter.ability
      );

    return (
      <div className="character-select-screen">
        <div className="selected-fighter">
          {!selectedCharacter && <p>Please select a character</p>}
          {selectedCharacter && (
            <>
              <h2>{selectedCharacter.name}</h2>
              <h3>
                {selectedCharacter.type}
                <img src={FIGHTER_TYPE_ICONS_DARK[selectedCharacter.type]} />
              </h3>
              <div className="render">
                <img src={charRenders[selectedCharacter.name]} />
              </div>
              <dl className="stats">
                <dt>Neutral</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade("neutral", selectedCharacter.history)
                    : selectedCharacter.neutral}
                </dd>

                <dt>Damage output</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade("advantage", selectedCharacter.history)
                    : selectedCharacter.advantage}
                </dd>

                <dt>Projectiles</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade(
                        "projectiles",
                        selectedCharacter.history,
                        selectedCharacter.projectiles
                      )
                    : selectedCharacter.projectiles}
                </dd>

                <dt>Mobility</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade("mobility", selectedCharacter.history)
                    : selectedCharacter.mobility}
                </dd>

                <dt>Out of shield</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade("outofshield", selectedCharacter.history)
                    : selectedCharacter.outofshield}
                </dd>

                <dt>Disadvantage</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade(
                        "disadvantage",
                        selectedCharacter.history
                      )
                    : selectedCharacter.disadvantage}
                </dd>

                <dt>KO options</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade("punish", selectedCharacter.history)
                    : selectedCharacter.punish}
                </dd>

                <dt>Recovery</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterStatGrade("recovery", selectedCharacter.history)
                    : selectedCharacter.recovery}
                </dd>

                <dt>Hypeness</dt>
                <dd>
                  {immersiveStatMode
                    ? fighterHypeGrade(selectedCharacter.hype)
                    : selectedCharacter.hype}
                </dd>

                <dt></dt>
                <dd>{weightClass(selectedCharacter.weight)}</dd>
              </dl>
            </>
          )}
        </div>

        <div className="fighter-ability">
          {fighterAbility && (
            <>
              <h3>
                {fighterAbility.name} <small>[{fighterAbility.type}]</small>
              </h3>
              <p>{fighterAbility.description}</p>
            </>
          )}
        </div>

        <div className="fighters">
          {fighters
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((fighter) => (
              <div
                key={fighter.id}
                className={
                  selectedCharacter && selectedCharacter.id === fighter.id
                    ? "fighter selected"
                    : "fighter"
                }
                onClick={this.selectCharacter.bind(this, fighter)}
              >
                <img src={charIcons[fighter.name]} />
              </div>
            ))}
          {overflow.map((o) => (
            <div className="fighter overflow" key={`overflow_${o}`} />
          ))}
        </div>
      </div>
    );
  }
}

export default CharacterSelectScreen;
