import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { getData, fighterStatGrade } from "../../util";
import { charIcons } from "../../data/assets";
import { FIGHTER_TYPE_ICONS } from "../../classes/Fighter";

import "./Tiers.scss";

class Tiers extends Component {
  render() {
    const fighters = getData("fighters");
    const players = getData("players");
    const settings = getData("settings");
    const time = getData("time");

    const immersiveStatMode = settings.statMode.indexOf("immersive") > -1;
    if (!immersiveStatMode) {
      fighters.sort((a, b) => (a.seed > b.seed ? -1 : 1));
    } else {
      if (time.season > 1) {
        fighters.sort((a, b) =>
          a.history[a.history.length - 1].position >
          b.history[b.history.length - 1].position
            ? -1
            : 1
        );
      }
    }

    let nextTierBoundary = Math.round(fighters[0].seed);
    if (time.season > 1 && immersiveStatMode) {
      nextTierBoundary = Math.round(
        fighters[0].history[fighters[0].history.length - 2].seed
      );
    }

    let tiers = ["S", "A", "B", "C", "D", "E", "U"];
    if (time.season === 1 && immersiveStatMode) tiers = ["?"];
    if (time.season > 1 && immersiveStatMode) tiers.push("?");
    let tierIndex = -1;

    return (
      <div className="tiers-container">
        <table cellSpacing={0}>
          <thead>
            <tr>
              <th></th>
              <th className="section-left">Seed</th>
              <th className="section-right">Type</th>
              <th>
                <table cellSpacing={0}>
                  <tr>
                    <th>Neut.</th>
                    <th>Dmg.</th>
                    <th>Prj.</th>
                    <th>Mob.</th>
                  </tr>
                  <tr>
                    <th>OOS</th>
                    <th>Dis.</th>
                    <th>KO.</th>
                    <th>Rcv.</th>
                  </tr>
                </table>
              </th>
              <th className="section-left">#</th>
              <th>Bal.</th>
            </tr>
          </thead>
          <tbody>
            {fighters.map((fighter, index) => {
              const mains = players.filter(
                (player) => player.main === fighter.id
              );

              let current = fighter.history[fighter.history.length - 1];
              let last = null;
              if (immersiveStatMode) {
                if (fighter.history.length > 2) {
                  current = fighter.history[fighter.history.length - 2];
                  last = fighter.history[fighter.history.length - 3];
                }
              } else {
                if (fighter.history.length > 1) {
                  last = fighter.history[fighter.history.length - 2];
                }
              }

              let positionChangeClassName = "position-change";
              let positionChange = "";

              if (last !== null) {
                positionChange = index - last.position;
                if (positionChange < 0) positionChange *= -1;
                if (last && index < last.position) {
                  positionChangeClassName += " improvement";
                } else if (last && index > last.position) {
                  positionChangeClassName += " fall";
                }
              }

              let balanceClassName = "balance-change";
              if (current.balance && current.balance.indexOf("+") > -1) {
                balanceClassName += " improvement";
              } else if (current.balance && current.balance.indexOf("-") > -1) {
                balanceClassName += " fall";
              }

              let tierBoundary = false;
              if (immersiveStatMode && time.season > 1) {
                if (
                  Math.floor(
                    fighter.history[fighter.history.length - 2].seed
                  ) <= nextTierBoundary
                ) {
                  if (tiers[tierIndex + 1] !== "?") {
                    tierBoundary = true;
                    tierIndex++;
                    nextTierBoundary =
                      fighter.history[fighter.history.length - 2].seed -
                      (fighter.history[fighter.history.length - 2].seed / 100) *
                        25;
                  } else {
                    if (fighterStatGrade("neutral", fighter.history) === "?") {
                      tierBoundary = true;
                      tierIndex++;
                    }
                  }
                } else if (
                  fighterStatGrade("neutral", fighter.history) === "?" &&
                  tierIndex < tiers.length - 1
                ) {
                  tierBoundary = true;
                  tierIndex = tiers.length - 1;
                }
              } else {
                if (Math.floor(fighter.seed) <= nextTierBoundary) {
                  tierBoundary = true;
                  tierIndex++;

                  nextTierBoundary = fighter.seed - (fighter.seed / 100) * 25;
                }
              }

              return (
                <Fragment key={fighter.id}>
                  {tierBoundary && tierIndex < tiers.length && (
                    <tr className="tier-boundary">
                      <td colSpan={11}>{tiers[tierIndex]}</td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <Link
                        to={`${process.env.PUBLIC_URL}/fighters/${fighter.id}`}
                      >
                        <img src={charIcons[fighter.name]} />
                        <span className="fighter-name">{fighter.name}</span>
                        <span className={positionChangeClassName}>
                          {positionChange > 0 && positionChange}
                        </span>
                      </Link>
                    </td>
                    <td className="section-left">
                      {!immersiveStatMode && Math.floor(fighter.seed)}
                      {immersiveStatMode &&
                        time.season > 1 &&
                        Math.floor(
                          fighter.history[fighter.history.length - 2].seed
                        )}
                      {immersiveStatMode && time.season === 1 && "?"}
                    </td>
                    <td className="section-right fighter-type">
                      <img src={FIGHTER_TYPE_ICONS[fighter.type]} />
                    </td>
                    <td>
                      <table cellSpacing={0}>
                        <tr>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade("neutral", fighter.history)
                              : fighter.neutral}
                          </td>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade("advantage", fighter.history)
                              : fighter.advantage}
                          </td>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade(
                                  "projectiles",
                                  fighter.history,
                                  fighter.projectiles
                                )
                              : fighter.projectiles}
                          </td>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade("mobility", fighter.history)
                              : fighter.mobility}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade("outofshield", fighter.history)
                              : fighter.outofshield}
                          </td>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade(
                                  "disadvantage",
                                  fighter.history
                                )
                              : fighter.disadvantage}
                          </td>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade("punish", fighter.history)
                              : fighter.punish}
                          </td>
                          <td>
                            {immersiveStatMode
                              ? fighterStatGrade("recovery", fighter.history)
                              : fighter.recovery}
                          </td>
                        </tr>
                      </table>
                    </td>

                    <td className="section-left">{mains.length}</td>
                    <td>
                      <span className={balanceClassName}></span>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Tiers;
