import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./containers/home/Home";
import Regions from "./containers/regions/Regions";
import NotFound from "./containers/not-found/NotFound";
import Tiers from "./containers/tiers/Tiers";
import News from "./containers/news/News";
import Region from "./containers/region/Region";
import Following from "./containers/following/Following";
import Player from "./containers/player/Player";
import Fighter from "./containers/fighter/Fighter";
import TopPlayers from "./containers/top-players/TopPlayers";
import Settings from "./containers/settings/Settings";
import MatchRenderer from "./components/match-renderer/MatchRenderer";
import Actions from "./containers/actions/Actions";
import Credits from "./containers/credits/Credits";
import Wiki from "./containers/wiki/Wiki";
import Sponsors from "./containers/sponsors/Sponsors";
import PlayTubeUploads from "./containers/playtube-uploads/PlayTubeUploads";
import PlayTubeHistory from "./containers/playtube-history/PlayTubeHistory";

class Routes extends React.Component {
  render() {
    const { tournaments, game } = this.props;

    return (
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL}/`}
          exact
          component={Home}
          key="home-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/regions`}
          exact
          component={Regions}
          key="regions-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/regions/:id`}
          exact
          component={Region}
          key="region-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/players`}
          exact
          component={TopPlayers}
          key="top-players-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/players/following`}
          exact
          component={Following}
          key="following-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/players/:id`}
          exact
          component={Player}
          key="player-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/fighters/:id`}
          exact
          component={Fighter}
          key="fighter-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/fighters`}
          exact
          component={Tiers}
          key="tiers-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/settings`}
          exact
          component={Settings}
          key="settings-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/credits`}
          exact
          component={Credits}
          key="credits-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/wiki`}
          exact
          component={Wiki}
          key="wiki-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/sponsors`}
          exact
          component={Sponsors}
          key="sponsors-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/actions`}
          exact
          render={() => <Actions game={game} />}
          key="actions-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/news`}
          exact
          render={() => <News tournaments={tournaments} />}
          key="news-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/playtube`}
          exact
          component={PlayTubeUploads}
          key="playtube-uploads-route"
        />
        <Route
          path={`${process.env.PUBLIC_URL}/playtube/history`}
          exact
          component={PlayTubeHistory}
          key="playtube-history-route"
        />
        <Route component={NotFound} key="not-found-route" />
      </Switch>
    );
  }
}

export default Routes;
