import { getVideoTitle, VIDEO_VIBES } from "../data/Video";
import { getData, times } from "../util";
import { boostCharacterFamiliarity, PERSONALITIES } from "./Player";

export default class Video {
  constructor(streamer, vibe = null) {
    this.streamer = streamer;
    const fighters = getData("fighters");
    const fighter = fighters.find((f) => f.id === this.streamer.main); // TODO selectable if player
    const baseQuality =
      (this.streamer.controller +
        this.streamer.camera +
        this.streamer.microphone +
        this.streamer.editingEquipment) /
      4;

    this.vibe = vibe;
    if (!vibe) {
      this.chooseVibe();
    }

    let entertainmentValue =
      (Math.random() *
        0.5 *
        (this.streamer.hype + this.streamer.entertainment)) /
      2000;

    if (this.vibe === VIDEO_VIBES.INFORMATIVE) {
      let familiarity = this.streamer.characterFamiliarity[fighter.id];
      if (isNaN(familiarity)) familiarity = 0;
      entertainmentValue += (familiarity + this.streamer.technique) / 100;
    }

    this.quality = baseQuality + entertainmentValue;

    let entertainmentBoost = Math.floor(entertainmentValue * 100);
    if (this.vibe === VIDEO_VIBES.INFORMATIVE) entertainmentBoost *= 0.9;
    if (this.vibe === VIDEO_VIBES.FUNNY) entertainmentBoost *= 1.1;

    let subscriberBoost = Math.floor(Math.random() * (this.quality * 100));
    if (this.vibe === VIDEO_VIBES.CHILL) subscriberBoost *= 1.1;
    if (this.vibe === VIDEO_VIBES.FUNNY) subscriberBoost *= 1.1;

    let financeBoost =
      Math.random() * (this.quality * this.streamer.subscribers);
    if (this.streamer.subscribers === 0)
      financeBoost = Math.random() * this.quality;
    if (this.vibe === VIDEO_VIBES.FUNNY) financeBoost *= 1.1;

    let characterFamiliarityBoost = this.quality;
    if (this.vibe === VIDEO_VIBES.INFORMATIVE) characterFamiliarityBoost *= 1.2;
    if (this.vibe === VIDEO_VIBES.FUNNY) characterFamiliarityBoost = 0;

    this.streamer.entertainment += entertainmentBoost;
    this.streamer.subscribers += Math.ceil(subscriberBoost);
    this.streamer.finance += financeBoost;
    boostCharacterFamiliarity(
      this.streamer,
      fighter.id,
      characterFamiliarityBoost
    );
    this.streamer.experience += Math.ceil(characterFamiliarityBoost);

    this.title = getVideoTitle(this.vibe, fighter.name, this.streamer.name);
    this.thumbnail = this.generateThumbnail();
    this.fighter = fighter;
    this.profit = financeBoost;
    this.views = Math.floor(
      subscriberBoost + this.streamer.subscribers * Math.random()
    );
  }

  generateThumbnail() {
    return {
      background: Math.ceil(Math.random() * 20),
      characterPositionX: 25 + Math.random() * 50,
      streamerPositionX: 25 + Math.random() * 50,
      characterPositionY: 25 + Math.random() * 50,
      streamerPositionY: 25 + Math.random() * 50,
      characterRotation: -60 + Math.random() * 120,
      streamerRotation: -60 + Math.random() * 120,
    };
  }

  chooseVibe() {
    const vibeCheck = [
      VIDEO_VIBES.CHILL,
      VIDEO_VIBES.INFORMATIVE,
      VIDEO_VIBES.FUNNY,
    ];
    switch (this.streamer.personality) {
      case PERSONALITIES.CHILL:
        times(3)(() => {
          vibeCheck.push(VIDEO_VIBES.CHILL);
        });
        break;
      case PERSONALITIES.PROFESSIONAL:
        times(3)(() => {
          vibeCheck.push(VIDEO_VIBES.INFORMATIVE);
        });
        break;
      case PERSONALITIES.CHILDISH:
        times(3)(() => {
          vibeCheck.push(VIDEO_VIBES.FUNNY);
        });
        break;
    }

    this.vibe = vibeCheck[Math.floor(Math.random() * vibeCheck.length)];
  }
}
