import React, { Component } from "react";
import Button from "../button/Button";

import "./StatAdder.scss";

class StatAdder extends Component {
    state = {
        value: 1,
    };

    componentDidMount() {
        const { startValue } = this.props;

        this.setState({
            value: startValue,
        });
    }

    change(modifier) {
        const { value } = this.state;
        const { onChange } = this.props;

        const newValue = value + modifier;

        this.setState({
            value: newValue,
        });

        onChange(newValue, modifier);
    }

    render() {
        const { startValue, max, min, pointsToSpend } = this.props;

        const { value } = this.state;

        const notches = [];
        for (let i = min; i <= max; i++) {
            notches.push(<em className={value >= i ? "selected" : ""}></em>);
        }

        return (
            <div className="stat-adder">
                <Button
                    onClick={this.change.bind(this, -1)}
                    primary
                    disabled={value <= min || value <= startValue}
                >
                    -
                </Button>
                {notches}
                <Button
                    onClick={this.change.bind(this, 1)}
                    primary
                    disabled={pointsToSpend <= 0 || value >= max}
                >
                    +
                </Button>
            </div>
        );
    }
}

export default StatAdder;
