import React, { Component } from "react";
import { FriendlyTournament } from "../../classes/FriendlyTournament";
import Game from "../../classes/Game";
import { MatchV3R } from "../../classes/MatchV3R";
import MatchRenderer from "../../components/match-renderer/MatchRenderer";
import PageHeader from "../../components/page-header/PageHeader";
import { getData } from "../../util";

import "./NotFound.scss";

class NotFound extends Component {
  componentDidMount() {
    this.game = new Game();

    const players = getData("players");
    const humanPlayer = getData("humanPlayer");

    // const entrants = players.splice(0, 32);
    // entrants.unshift(humanPlayer);
    // entrants.pop();

    // const friendlies = new FriendlyTournament(
    //     entrants,
    //     () => {
    //         alert("done");
    //     },
    //     this.game
    // );
    // friendlies.beginTournament();

    // test match engine

    const fighters = getData("fighters");
    const fighter = fighters.find((f) => f.name === "Kirby");
    const playerTwo = players.find((p) => p.main === fighter.id);
    const playerOne = humanPlayer;

    const match = new MatchV3R(playerOne, playerTwo, () => {
      console.log("match v3");
    });

    match.fighterFlexible = true;
    match.showCSS = true;
    match.playerOneStocks = 5;
    match.playerTwoStocks = 5;

    match.region = "Dev";
    match.tournamentName = "Dev";
    match.roundName = "Test";

    this.match = match;
    //this.match.play();

    // const region = getData("regions")[2];
    // this.sortPlayersBySeeding(region.players);
  }

  sortPlayersBySeeding(players) {
    const allPlayers = getData("players");
    let sortedPlayers = [];
    sortedPlayers = players.map((p) =>
      allPlayers.find((player) => player.id === p)
    );
    sortedPlayers.sort((a, b) => {
      if (a.seed === b.seed) return Math.random() - Math.random();
      return a.seed < b.seed ? 1 : -1;
    });

    sortedPlayers.forEach((p, index) => (p.seedIndex = index + 1));

    const ITERATIONS = players.length / 16;
    let brackets = [];
    for (let i = 0; i < ITERATIONS; i++) {
      let bracket = [];

      // first and last once
      bracket.push(...sortedPlayers.splice(0, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length - 1, 1));

      // divide by 2, twice
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 2, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 2, 1));

      // 1/4 and 3/4, twice
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 4, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 4) * 3, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 4, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 4) * 3, 1));

      // 1/8, 3/8, 5/8, 7/8, twice
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 8, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 7, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 3, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 5, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 8, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 7, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 3, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 5, 1));

      brackets.push(bracket);
    }

    this.players = [];
    this.brackets = brackets.sort(() => Math.random() - Math.random());
    brackets.forEach((b) => this.players.push(...b));
  }

  render() {
    //return <PageHeader>Coming soon...</PageHeader>;

    // if (
    //     !this.game ||
    //     !this.game.matchWatching ||
    //     !this.game.matchWatching.renderable
    // )
    //     return null;

    return <MatchRenderer match={this.match} />;

    return (
      <div className="brackets">
        {this.brackets &&
          this.brackets.map((b) => (
            <div>
              {b.map((p, i) => (
                <>
                  <p>
                    {p.name} [{p.seedIndex}]
                  </p>
                  {i % 2 !== 0 && <hr />}
                </>
              ))}
            </div>
          ))}
      </div>
    );
  }
}

export default NotFound;
