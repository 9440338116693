import React, { Component } from "react";
import { FOLLOW_LEVELS } from "../../data/Tweets";
import { getData, setData } from "../../util";
import Button from "../button/Button";

import "./FollowManagement.scss";

class FollowManagement extends Component {
    state = {
        followOptionsOpen: false,
    };

    follow(player, followLevel) {
        const followedPlayers = getData("followedPlayers");
        const isFollowing = followedPlayers.findIndex(
            (p) => p.id === player.id
        );

        player.followLevel = followLevel;

        if (isFollowing < 0) {
            followedPlayers.push(player);
        } else if (
            followLevel === FOLLOW_LEVELS.UNFOLLOW ||
            followLevel === FOLLOW_LEVELS.TOGGLE_TWEETS_UNFOLLOW
        ) {
            followedPlayers.splice(isFollowing, 1);
        } else {
            followedPlayers.splice(isFollowing, 1);
            followedPlayers.push(player);
        }

        this.setState({
            followOptionsOpen: false,
        });

        setData("followedPlayers", followedPlayers);
    }

    toggleFollowOptions() {
        this.setState({
            followOptionsOpen: !this.state.followOptionsOpen,
        });
    }

    render() {
        const { player } = this.props;

        const { followOptionsOpen } = this.state;

        const humanPlayer = getData("humanPlayer");
        const followedPlayers = getData("followedPlayers");
        const isFollowing = followedPlayers.find((p) => p.id === player.id);
        const mode = humanPlayer ? 1 : 0;

        return (
            <div className="follow-management">
                {mode === 1 && player.id === humanPlayer.id && (
                    <Button onClick={() => {}} disabled>
                        You
                    </Button>
                )}
                {mode === 1 && player.id !== humanPlayer.id && (
                    <Button
                        onClick={this.follow.bind(
                            this,
                            player,
                            FOLLOW_LEVELS.TOGGLE_TWEETS_UNFOLLOW
                        )}
                        primary={!isFollowing}
                    >
                        {isFollowing ? "Unfollow" : "Follow"}
                    </Button>
                )}
                {mode === 0 && (
                    <>
                        <Button
                            onClick={this.toggleFollowOptions.bind(this)}
                            primary={!isFollowing}
                        >
                            {isFollowing ? isFollowing.followLevel : "Follow"}
                        </Button>
                        {followOptionsOpen && (
                            <div className="follow-options">
                                {isFollowing && (
                                    <p
                                        onClick={this.follow.bind(
                                            this,
                                            player,
                                            FOLLOW_LEVELS.UNFOLLOW
                                        )}
                                    >
                                        {FOLLOW_LEVELS.UNFOLLOW}
                                    </p>
                                )}
                                <p
                                    onClick={this.follow.bind(
                                        this,
                                        player,
                                        FOLLOW_LEVELS.TWEETS_ONLY
                                    )}
                                >
                                    {FOLLOW_LEVELS.TWEETS_ONLY}
                                </p>
                                <p
                                    onClick={this.follow.bind(
                                        this,
                                        player,
                                        FOLLOW_LEVELS.TOP_8_MATCHES
                                    )}
                                >
                                    {FOLLOW_LEVELS.TOP_8_MATCHES}
                                </p>
                                <p
                                    onClick={this.follow.bind(
                                        this,
                                        player,
                                        FOLLOW_LEVELS.EVERYTHING
                                    )}
                                >
                                    {FOLLOW_LEVELS.EVERYTHING}
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default FollowManagement;
