import structuredClone from "@ungap/structured-clone";

import Ability, { ABILITY_TYPES } from "../classes/Ability";
import { getData } from "../util";
import { MATCH_STATES } from "./MatchStates";
import { STRATEGIES } from "./Strategies";

export const Abilities = [
  new Ability(
    "Meme Edgeguarding",
    "A raft of new edgeguarding options to get some sweet content.",
    ABILITY_TYPES.ACTIVE,
    (match, target) => {
      if (match.showGameStart) {
        const humanPlayer = getData("humanPlayer");
        if (humanPlayer && humanPlayer.id === target.id) {
          const edgeguardingStrategies = match.strategyActions.find(
            (strategy) => strategy.label === STRATEGIES.EDGEGUARDING
          );
          if (edgeguardingStrategies) {
            edgeguardingStrategies.values.splice(1, 2);
            edgeguardingStrategies.values.push(
              STRATEGIES.EDGEGUARDING_OPTIONS.FORWARD_AERIAL
            );
            edgeguardingStrategies.values.push(
              STRATEGIES.EDGEGUARDING_OPTIONS.CAPE
            );
            edgeguardingStrategies.values.push(
              STRATEGIES.EDGEGUARDING_OPTIONS.FLUDD
            );
          }
        } else if (target.id === match.playerOne.id) {
          match.playerOneEdgeguardingOptions.splice(1, 2);
          match.playerOneEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.FORWARD_AERIAL
          );
          match.playerOneEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.CAPE
          );
          match.playerOneEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.FLUDD
          );
        } else if (target.id === match.playerTwo.id) {
          match.playerTwoEdgeguardingOptions.splice(1, 2);
          match.playerTwoEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.FORWARD_AERIAL
          );
          match.playerTwoEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.CAPE
          );
          match.playerTwoEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.FLUDD
          );
        }
      }
    }
  ),
  new Ability(
    "Cargo Throw",
    "A unique strategy strong vs counter and balanced, but weak to projectile spammers.",
    ABILITY_TYPES.ACTIVE,
    (match, target) => {
      if (match.showGameStart) {
        const humanPlayer = getData("humanPlayer");
        if (humanPlayer && humanPlayer.id === target.id) {
          const neutralStrategies = match.strategyActions.find(
            (strategy) => strategy.label === STRATEGIES.NEUTRAL
          );
          if (neutralStrategies) {
            neutralStrategies.values.push(
              STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW
            );
          }
        } else {
          if (target.id === match.playerOne.id)
            match.playerOne.neutralStrategy =
              STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW;
          if (target.id === match.playerTwo.id)
            match.playerTwo.neutralStrategy =
              STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW;
        }
      }
    }
  ),
  new Ability(
    "Remote Bombs",
    "A somewhat lame but very safe and effective edgeguarding tool.",
    ABILITY_TYPES.ACTIVE,
    (match, target) => {
      if (match.showGameStart) {
        const humanPlayer = getData("humanPlayer");
        if (humanPlayer && humanPlayer.id === target.id) {
          const edgeguardingStrategies = match.strategyActions.find(
            (strategy) => strategy.label === STRATEGIES.EDGEGUARDING
          );
          if (edgeguardingStrategies) {
            edgeguardingStrategies.values.push(
              STRATEGIES.EDGEGUARDING_OPTIONS.REMOTE_BOMBS
            );
          }
        } else if (target.id === match.playerOne.id) {
          match.playerOneEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.REMOTE_BOMBS
          );
        } else if (target.id === match.playerTwo.id) {
          match.playerTwoEdgeguardingOptions.push(
            STRATEGIES.EDGEGUARDING_OPTIONS.REMOTE_BOMBS
          );
        }
      }
    }
  ),
  new Ability(
    "Charge Shot",
    "Charge up and blow away your enemies.",
    ABILITY_TYPES.ACTIVE,
    (match, target) => {
      if (match.showGameStart) {
        if (target.id === match.playerOne.id) match.playerOneCharge = 0;
        if (target.id === match.playerTwo.id) match.playerTwoCharge = 0;

        const humanPlayer = getData("humanPlayer");
        if (humanPlayer && humanPlayer.id === target.id) {
          const neutralStrategies = match.strategyActions.find(
            (strategy) => strategy.label === STRATEGIES.NEUTRAL
          );
          if (neutralStrategies) {
            neutralStrategies.values.push(
              STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT
            );
          }
        } else {
          if (target.id === match.playerOne.id) {
            match.playerOne.neutralStrategy =
              STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT;
            match.playerOneUsesAbility = "auto";
          }
          if (target.id === match.playerTwo.id) {
            match.playerTwo.neutralStrategy =
              STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT;
            match.playerTwoUsesAbility = "auto";
          }
        }
      }

      if (target.id === match.playerOne.id) {
        if (
          match.playerOneState === MATCH_STATES.NEUTRAL &&
          match.playerOne.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT
        )
          match.playerOneSkipNeutralInterval = true;
        if (
          match.playerOneUsesAbility !== "auto" &&
          match.playerOneUsesAbility !== null &&
          match.playerOneState === MATCH_STATES.ADVANTAGE
        ) {
          match.playerOneState = MATCH_STATES.RELEASE_CHARGE;
          match.playerTwoState = MATCH_STATES.VULNERABLE;
          match.playerOneUsesAbility = null;
        } else if (
          match.playerOneState === MATCH_STATES.ADVANTAGE &&
          match.playerOne.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT
        ) {
          if (
            match.playerOneCharge > 0 &&
            match.playerOneUsesAbility === "auto" &&
            Math.random() * 3 < 1
          ) {
            match.playerOneState = MATCH_STATES.RELEASE_CHARGE;
            match.playerTwoState = MATCH_STATES.VULNERABLE;
          } else {
            match.playerOneState = MATCH_STATES.CHARGE;
            if (match.playerOneCharge < 4) match.playerOneCharge++;
          }
        }
      } else if (target.id === match.playerTwo.id) {
        if (
          match.playerTwoState === MATCH_STATES.NEUTRAL &&
          match.playerTwo.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT
        )
          match.playerTwoSkipNeutralInterval = true;
        if (
          match.playerTwoUsesAbility !== "auto" &&
          match.playerTwoUsesAbility !== null &&
          match.playerTwoState === MATCH_STATES.ADVANTAGE
        ) {
          match.playerTwoState = MATCH_STATES.RELEASE_CHARGE;
          match.playerOneState = MATCH_STATES.VULNERABLE;
          match.playerTwoUsesAbility = null;
        } else if (
          match.playerTwoState === MATCH_STATES.ADVANTAGE &&
          match.playerTwo.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT
        ) {
          if (
            match.playerTwoCharge > 0 &&
            match.playerTwoUsesAbility === "auto" &&
            Math.random() * 3 < 1
          ) {
            match.playerTwoState = MATCH_STATES.RELEASE_CHARGE;
            match.playerOneState = MATCH_STATES.VULNERABLE;
          } else {
            match.playerTwoState = MATCH_STATES.CHARGE;
            if (match.playerTwoCharge < 4) match.playerTwoCharge++;
          }
        }
      }
    },
    [
      {
        label: "Charge Shot",
        options: [
          {
            label: "Fire Charge Shot [{{charge}}]",
            value: 1,
          },
        ],
      },
    ]
  ),
  new Ability(
    "Copy",
    "Copies your opponent's ability. Does not work on form switching abilities.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      const uncopyable = ["Copy", "Pokemon Switch"];

      if (target.id === match.playerOne.id && match.fighterTwoAbility) {
        if (uncopyable.indexOf(match.fighterTwoAbility.name) > -1) return;
        match.fighterOneAbility = Abilities.find(
          (a) => a.name === match.fighterTwoAbility.name
        );
        match.fighterOneAbility.exec(match, target);
      } else if (target.id === match.playerTwo.id && match.fighterOneAbility) {
        if (uncopyable.indexOf(match.fighterOneAbility.name) > -1) return;
        match.fighterTwoAbility = Abilities.find(
          (a) => a.name === match.fighterOneAbility.name
        );
        match.fighterTwoAbility.exec(match, target);
      }
    }
  ),
  new Ability(
    "Double Jump Super Armour",
    "Tank your way through low-risk edgeguards.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (match.playerOne.id === target.id) {
        match.playerOneEdgeguardSkipThreshold = 3;
      } else {
        match.playerTwoEdgeguardSkipThreshold = 3;
      }
    }
  ),
  new Ability(
    "Reflector",
    "Grants a boost against projectile-based campy strategies.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (target.id === match.playerOne.id) {
        if (!match.reflectorBase) {
          match.reflectorBase = structuredClone(match.fighterOne);
        }

        if (
          match.playerTwo.neutralStrategy ===
          STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM
        ) {
          match.fighterOne.neutral = match.reflectorBase.neutral + 2;
        } else {
          match.fighterOne.neutral = match.reflectorBase.neutral;
        }
      } else if (target.id === match.playerTwo.id) {
        if (!match.reflectorBase) {
          match.reflectorBase = structuredClone(match.fighterTwo);
        }

        if (
          match.playerOne.neutralStrategy ===
          STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM
        ) {
          match.fighterTwo.neutral = match.reflectorBase.neutral + 2;
        } else {
          match.fighterTwo.neutral = match.reflectorBase.neutral;
        }
      }
    }
  ),
  new Ability(
    "Quick Attack",
    "Escape low-risk edgeguards using janky hitboxes.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (match.playerOne.id === target.id) {
        match.playerOneEdgeguardSkipThreshold = 2;
      } else {
        match.playerTwoEdgeguardSkipThreshold = 2;
      }
    }
  ),
  new Ability(
    "Hype Master",
    "All of your moves are hype. Don't let anyone tell you otherwise.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (match.showGameStart) return;

      if (target.id === match.playerOne.id) {
        match.playerOneHype += 0.5;
      } else if (target.id === match.playerTwo.id) {
        match.playerTwoHype += 0.5;
      }
    }
  ),
  new Ability(
    "Self-Harm",
    "Take a small amount of damage in advantage and punish states.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (match.showGameStart) return;

      if (target.id === match.playerOne.id) {
        if (
          match.playerOneState === MATCH_STATES.ADVANTAGE ||
          match.playerOneState === MATCH_STATES.PUNISHING
        )
          match.playerOneDamage += Math.random() * 2.5;
      } else if (target.id === match.playerTwo.id) {
        if (
          match.playerTwoState === MATCH_STATES.ADVANTAGE ||
          match.playerTwoState === MATCH_STATES.PUNISHING
        )
          match.playerTwoDamage += Math.random() * 2.5;
      }
    }
  ),
  new Ability(
    "Pokemon Switch",
    "Switch forms between Squirtle, Ivysaur and Charizard.",
    ABILITY_TYPES.ACTIVE,
    (match, target) => {
      if (target.id === match.playerOne.id) {
        let currentFormIndex = match.fighterOneForms.findIndex(
          (form) => form.id === match.fighterOne.id
        );
        if (currentFormIndex === -1) {
          const humanPlayer = getData("humanPlayer");
          if (!humanPlayer || humanPlayer.id !== target.id)
            match.playerOneUsesAbility = "auto";

          match.fighterOne = match.fighterOneForms[0];
        } else if (
          match.playerOneUsesAbility !== "auto" &&
          match.playerOneUsesAbility !== null &&
          match.playerOneState === MATCH_STATES.NEUTRAL
        ) {
          match.playerOneUsesAbility = null;
          match.playerOneState = MATCH_STATES.FORM_SWITCH;

          currentFormIndex++;
          if (currentFormIndex >= match.fighterOneForms.length) {
            currentFormIndex = 0;
          }

          match.fighterOne = match.fighterOneForms[currentFormIndex];
        } else if (
          match.playerOneUsesAbility === "auto" &&
          match.playerOneState === MATCH_STATES.NEUTRAL
        ) {
          if (
            (match.playerTwoDamage < Math.random() * 10 + 33 &&
              currentFormIndex === match.fighterOneForms.length - 1 &&
              Math.random() < 0.5) ||
            (match.playerTwoDamage > Math.random() * 10 + 30 &&
              match.playerTwoDamage < Math.random() * 10 + 66 &&
              currentFormIndex === 0 &&
              Math.random() < 0.5) ||
            (match.playerTwoDamage > Math.random() * 10 + 60 &&
              currentFormIndex < match.fighterOneForms.length - 1 &&
              Math.random() < 0.5) ||
            Math.random() < 0.1
          ) {
            currentFormIndex++;
            if (currentFormIndex >= match.fighterOneForms.length)
              currentFormIndex = 0;
            match.playerOneState = MATCH_STATES.FORM_SWITCH;
            match.fighterOne = match.fighterOneForms[currentFormIndex];
          }
        }
      } else if (target.id === match.playerTwo.id) {
        let currentFormIndex = match.fighterTwoForms.findIndex(
          (form) => form.id === match.fighterTwo.id
        );
        if (currentFormIndex === -1) {
          const humanPlayer = getData("humanPlayer");
          if (!humanPlayer || humanPlayer.id !== target.id)
            match.playerTwoUsesAbility = "auto";

          match.fighterTwo = match.fighterTwoForms[0];
        } else if (
          match.playerTwoUsesAbility !== "auto" &&
          match.playerTwoUsesAbility !== null &&
          match.playerTwoState === MATCH_STATES.NEUTRAL
        ) {
          match.playerTwoUsesAbility = null;
          match.playerTwoState = MATCH_STATES.FORM_SWITCH;

          currentFormIndex++;
          if (currentFormIndex >= match.fighterTwoForms.length) {
            currentFormIndex = 0;
          }

          match.fighterTwo = match.fighterTwoForms[currentFormIndex];
        } else if (
          match.playerTwoUsesAbility === "auto" &&
          match.playerTwoState === MATCH_STATES.NEUTRAL
        ) {
          if (
            (match.playerOneDamage < Math.random() * 10 + 33 &&
              currentFormIndex === match.fighterTwoForms.length - 1 &&
              Math.random() < 0.25) ||
            (match.playerOneDamage > Math.random() * 10 + 30 &&
              match.playerOneDamage < Math.random() * 10 + 66 &&
              currentFormIndex === 0 &&
              Math.random() < 0.25) ||
            (match.playerOneDamage > Math.random() * 10 + 60 &&
              currentFormIndex < match.fighterTwoForms.length - 1 &&
              Math.random() < 0.25) ||
            Math.random() < 0.1
          ) {
            currentFormIndex++;
            if (currentFormIndex >= match.fighterTwoForms.length)
              currentFormIndex = 0;
            match.playerTwoState = MATCH_STATES.FORM_SWITCH;
            match.fighterTwo = match.fighterTwoForms[currentFormIndex];
          }
        }
      }
    },
    [
      {
        label: "Pokemon Switch",
        options: [
          {
            label: "Next Pokemon",
            value: 1,
          },
        ],
      },
    ]
  ),
  new Ability(
    "Aura",
    "Increase damage, kill potential and recovery as you take more damage.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (match.showGameStart) return;

      const MAX_AURA_INCREASE = 2.5;

      if (target.id === match.playerOne.id) {
        if (!match.lucarioBase) {
          match.lucarioBase = structuredClone(match.fighterOne);
        }

        let increase = match.playerOneDamage / 50;
        if (increase > MAX_AURA_INCREASE) increase = MAX_AURA_INCREASE;

        match.fighterOne.advantage = match.lucarioBase.advantage + increase;
        match.fighterOne.punish = match.lucarioBase.punish + increase;
        match.fighterOne.recovery = match.lucarioBase.recovery + increase;
      } else if (target.id === match.playerTwo.id) {
        if (!match.lucarioBase) {
          match.lucarioBase = match.fighterTwo;
        }

        let increase = match.playerTwoDamage / 50;
        if (increase > MAX_AURA_INCREASE) increase = MAX_AURA_INCREASE;

        match.fighterTwo.advantage = match.lucarioBase.advantage + increase;
        match.fighterTwo.punish = match.lucarioBase.punish + increase;
        match.fighterTwo.recovery = match.lucarioBase.recovery + increase;
      }
    }
  ),

  new Ability(
    "Banana Trap",
    "Grants a boost against aggressive pressure players.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (target.id === match.playerOne.id) {
        if (!match.bananaBase) {
          match.bananaBase = structuredClone(match.fighterOne);
        }

        if (
          match.playerTwo.neutralStrategy ===
          STRATEGIES.NEUTRAL_OPTIONS.PRESSURE
        ) {
          match.fighterOne.neutral = match.bananaBase.neutral + 2;
        } else {
          match.fighterOne.neutral = match.bananaBase.neutral;
        }
      } else if (target.id === match.playerTwo.id) {
        if (!match.bananaBase) {
          match.bananaBase = structuredClone(match.fighterTwo);
        }

        if (
          match.playerOne.neutralStrategy ===
          STRATEGIES.NEUTRAL_OPTIONS.PRESSURE
        ) {
          match.fighterTwo.neutral = match.bananaBase.neutral + 2;
        } else {
          match.fighterTwo.neutral = match.bananaBase.neutral;
        }
      }
    }
  ),

  // Melody Melee
  new Ability(
    "Viking Raid",
    "Hype up the crowd with your epic riffs and get them to invade the stage!",
    ABILITY_TYPES.ACTIVE,
    (match, target) => {
      if (match.showGameStart) {
        if (target.id === match.playerOne.id) match.playerOneCharge = 0;
        if (target.id === match.playerTwo.id) match.playerTwoCharge = 0;

        const humanPlayer = getData("humanPlayer");
        if (humanPlayer && humanPlayer.id === target.id) {
          const neutralStrategies = match.strategyActions.find(
            (strategy) => strategy.label === STRATEGIES.NEUTRAL
          );
          if (neutralStrategies) {
            neutralStrategies.values.push(
              STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID
            );
          }
        } else {
          if (target.id === match.playerOne.id) {
            match.playerOne.neutralStrategy =
              STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID;
            match.playerOneUsesAbility = "auto";
          }
          if (target.id === match.playerTwo.id) {
            match.playerTwo.neutralStrategy =
              STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID;
            match.playerTwoUsesAbility = "auto";
          }
        }
      }

      if (target.id === match.playerOne.id) {
        if (
          match.playerOneState === MATCH_STATES.NEUTRAL &&
          match.playerOne.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID
        )
          match.playerOneSkipNeutralInterval = true;
        if (
          match.playerOneUsesAbility !== "auto" &&
          match.playerOneUsesAbility !== null &&
          match.playerOneState === MATCH_STATES.ADVANTAGE
        ) {
          match.playerOneState = MATCH_STATES.RELEASE_CHARGE;
          match.playerTwoState = MATCH_STATES.VULNERABLE;
          match.playerOneUsesAbility = null;
        } else if (
          match.playerOneState === MATCH_STATES.ADVANTAGE &&
          match.playerOne.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID
        ) {
          if (
            match.playerOneCharge > 0 &&
            match.playerOneUsesAbility === "auto" &&
            Math.random() * 3 < 1
          ) {
            match.playerOneState = MATCH_STATES.RELEASE_CHARGE;
            match.playerTwoState = MATCH_STATES.VULNERABLE;
          } else {
            match.playerOneState = MATCH_STATES.CHARGE;
            if (match.playerOneCharge < 4) match.playerOneCharge++;
          }
        }
      } else if (target.id === match.playerTwo.id) {
        if (
          match.playerTwoState === MATCH_STATES.NEUTRAL &&
          match.playerTwo.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID
        )
          match.playerTwoSkipNeutralInterval = true;
        if (
          match.playerTwoUsesAbility !== "auto" &&
          match.playerTwoUsesAbility !== null &&
          match.playerTwoState === MATCH_STATES.ADVANTAGE
        ) {
          match.playerTwoState = MATCH_STATES.RELEASE_CHARGE;
          match.playerOneState = MATCH_STATES.VULNERABLE;
          match.playerTwoUsesAbility = null;
        } else if (
          match.playerTwoState === MATCH_STATES.ADVANTAGE &&
          match.playerTwo.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID
        ) {
          if (
            match.playerTwoCharge > 0 &&
            match.playerTwoUsesAbility === "auto" &&
            Math.random() * 3 < 1
          ) {
            match.playerTwoState = MATCH_STATES.RELEASE_CHARGE;
            match.playerOneState = MATCH_STATES.VULNERABLE;
          } else {
            match.playerTwoState = MATCH_STATES.CHARGE;
            if (match.playerTwoCharge < 4) match.playerTwoCharge++;
          }
        }
      }
    },
    [
      {
        label: "Crowd Hype",
        options: [
          {
            label: "Stage Rush!! [{{charge}}]",
            value: 1,
          },
        ],
      },
    ]
  ),
  new Ability(
    "Stay Chill, Get The Kill",
    "Grants an additional boost to punish when you're at low percent.",
    ABILITY_TYPES.PASSIVE,
    (match, target) => {
      if (match.showGameStart) return;

      if (target.id === match.playerOne.id) {
        // TODO
      } else if (target.id === match.playerTwo.id) {
        // TODO
      }
    }
  ),
  new Ability(
    "Drop The Bass",
    "Build up to the big breakdown and knock your opponent off with a massive punish.",
    ABILITY_TYPES.ACTIVE,
    (match, target) => {
      if (match.showGameStart) return;

      if (target.id === match.playerOne.id) {
        // TODO
      } else if (target.id === match.playerTwo.id) {
        // TODO
      }
    }
  ),
];
