import NewsItem from "../classes/NewsItem";
import Sponsor, { SPONSOR_VALUES } from "../classes/Sponsor";
import { getData } from "../util";

const SXLA = new Sponsor(
  1,
  "Smash XL Association",
  10000,
  "commemorative t-shirt",
  SPONSOR_VALUES.CHAMPIONS,
  1,
  "Smash XL Association is the international board for anything to do with competitive Super Smash Bros.",
  1,
  100
);

const Panther = new Sponsor(
  1001,
  "Panther",
  2300,
  "ultimate controller",
  SPONSOR_VALUES.RESULTS
);
const TeamPlasma = new Sponsor(
  1002,
  "Team Plasma",
  2150,
  "bluetooth headset",
  SPONSOR_VALUES.HYPE,
  2
);
const GamerJuice = new Sponsor(
  1003,
  "Gamer Juice",
  2700,
  "bottle of Gamer Juice",
  SPONSOR_VALUES.HYPE,
  2
);
const Desmashify = new Sponsor(
  1004,
  "Desmashify",
  1950,
  "deoderant",
  SPONSOR_VALUES.MEMES
);
const Elemental = new Sponsor(
  1005,
  "Elemental",
  2000,
  "bag of totally legal prescription drugs",
  SPONSOR_VALUES.RESULTS
);
const XTyres = new Sponsor(
  1006,
  "X Tyres",
  1800,
  "car tyre",
  SPONSOR_VALUES.RESULTS
);

export const SponsorData = {
  SXLA,
  Panther,
  TeamPlasma,
  GamerJuice,
  // Desmashify,
  Elemental,
  // XTyres,
};

export const dropPlayerFromSponsor = (player, sponsor, reason) => {
  const time = getData("time");
  const timeString = `S${time ? time.season : ""} M${time ? time.month : ""} W${
    time ? time.week : ""
  }`;

  player.sponsor = null;
  sponsor.players = sponsor.players.filter((sp) => sp !== player.id);

  sponsor.blacklist.push(player.id);
  player.strikes = 0;

  return new NewsItem(
    `${player.name} loses sponsorship`,
    `<p>Following ${reason}, ${player.name} has been dropped by their sponsor ${sponsor.name}. This will open a slot up for a new candidate!</p>`,
    timeString,
    "sponsorship",
    `/players/${player.id}`
  );
};
