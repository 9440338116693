import React, { Component } from "react";
import { Link } from "react-router-dom";

import { charIcons } from "../../data/assets";
import Button from "../../components/button/Button";
import { getData, setData } from "../../util";

import "./Following.scss";
import Switcher from "../../components/switcher/Switcher";
import FollowManagement from "../../components/follow-management/FollowManagement";

class Following extends Component {
    getFollowing() {
        const sponsors = getData("sponsors");
        const fighters = getData("fighters");
        const humanPlayer = getData("humanPlayer");
        const players = getData("players");
        const followedPlayers = getData("followedPlayers");

        return (
            <>
                <Switcher>
                    <Link to="/players">
                        <span>Top 128</span>
                    </Link>
                    <span className="active">Following</span>
                </Switcher>
                <ul className="following">
                    <ul>
                        {followedPlayers
                            .filter((p) =>
                                humanPlayer ? p.id !== humanPlayer.id : true
                            )
                            .map((followedPlayer) => {
                                const player = players.find(
                                    (p) => p.id === followedPlayer.id
                                );
                                const main = fighters.find(
                                    (fighter) => fighter.id === player.main
                                );

                                const sponsorKey = player.sponsor
                                    ? Object.keys(sponsors).find((key) => {
                                          const sponsor = sponsors[key];
                                          return sponsor.id === player.sponsor;
                                      })
                                    : null;

                                const sponsor = sponsors[sponsorKey];
                                return (
                                    <li
                                        key={followedPlayer.id}
                                        className={sponsor ? "has-sponsor" : ""}
                                    >
                                        {main ? (
                                            <img src={charIcons[main.name]} />
                                        ) : (
                                            ""
                                        )}
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/players/${player.id}`}
                                        >
                                            <div>
                                                <strong>
                                                    {player.name}
                                                    <img src={player.flag} />
                                                </strong>

                                                {sponsor && (
                                                    <div className="sponsor">
                                                        <img
                                                            src={sponsor.emblem}
                                                            className="sponsor-emblem"
                                                        />
                                                        <span>
                                                            {sponsor.name}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </Link>
                                        [{player.seed.toFixed(2)}]
                                        <FollowManagement player={player} />
                                    </li>
                                );
                            })}
                    </ul>
                </ul>
            </>
        );
    }

    render() {
        return <div className="following-container">{this.getFollowing()}</div>;
    }
}

export default Following;
