import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import structuredClone from "@ungap/structured-clone";

import packageJson from "../../../package.json";

import Dropdown from "../../components/dropdown/Dropdown";
import HelpModal from "../../components/help-modal/HelpModal";
import CharacterSelectScreen from "../../components/character-select-screen/CharacterSelectScreen";
import Button from "../../components/button/Button";
import TextInput from "../../components/text-input/TextInput";
import StatAdder from "../../components/stat-adder/StatAdder";

import NewsItem from "../../classes/NewsItem";
import Player from "../../classes/Player";

import { Fighters_MM } from "../../data/Fighters_MM";
import { Fighters_SSBU } from "../../data/Fighters_SSBU";
import { Fighters_20XX } from "../../data/Fighters_20XX";
import { topPlayers } from "../../data/Players";
import { RegionData } from "../../data/Regions";
import { settingData } from "../../data/Settings";
import { charIcons } from "../../data/assets";
import { FOLLOW_LEVELS } from "../../data/Tweets";

import { clearData, getData, setData } from "../../util";

import "./Home.scss";
import { SponsorData } from "../../data/Sponsors";

class Home extends Component {
  state = {
    gameStarted: false,
    settingsOpen: false,
    characterCreationOpen: false,
    playerStub: {
      name: "",
      main: null,
      region: 100,
      stats: {
        neutral: 1,
        advantage: 1,
        disadvantage: 1,
        punish: 1,
        technique: 1,
      },
    },
    database: "MM",
    matchEngine: "v3r",
    statMode: "immersive",
    dlcPolicy: "real",
    balancePatchPolicy: "standard",
    pointsRemaining: 10,
  };

  changeDatabase(database) {
    this.setState({
      database,
    });
  }

  changeMatchEngine(matchEngine) {
    this.setState({
      matchEngine,
    });
  }

  changeStatMode(statMode) {
    this.setState({
      statMode,
    });
  }

  changeBalancePatchPolicy(balancePatchPolicy) {
    this.setState({
      balancePatchPolicy,
    });
  }

  changeDLCPolicy(dlcPolicy) {
    this.setState({
      dlcPolicy,
    });
  }

  openCharacterSettings() {
    this.setState({
      characterCreationOpen: true,
    });
    this.initSim();
  }

  initSim() {
    const {
      database,
      matchEngine,
      statMode,
      dlcPolicy,
      balancePatchPolicy,
      humanPlayers,
    } = this.state;

    clearData();
    let fighters = Fighters_MM;
    if (database === "SSBU") fighters = Fighters_SSBU;
    if (database === "20XX") fighters = Fighters_20XX;
    let dlcFighters = [];
    fighters.forEach((fighter, index) => (fighter.index = index));

    fighters.forEach((fighter, index) => {
      fighter.index = index;
    });

    if (statMode.indexOf("Random") > 0) {
      fighters.forEach((fighter) => fighter.generateTechnicalStats());
    }

    if (dlcPolicy === "real" || dlcPolicy === "random") {
      dlcFighters = fighters
        .filter((fighter) => fighter.dlcIndex !== null)
        .sort((a, b) => (a.dlcIndex > b.dlcIndex ? 1 : -1));
      fighters = fighters.filter((fighter) => fighter.dlcIndex === null);

      if (dlcPolicy === "random") {
        dlcFighters.sort(() => Math.random() - Math.random());
      }
    }
    if (dlcPolicy === "totalRandom") {
      fighters.sort(() => Math.random() - Math.random());
      dlcFighters = fighters.splice(0, 11);
    }
    setData("fighters", fighters);
    setData("dlcFighters", dlcFighters);
    setData("news", []);
    setData("playTubeWatchHistory", []);
    setData("playTubeUploadHistory", []);
    setData("settings", {
      database,
      matchEngine,
      statMode,
      dlcPolicy,
      balancePatchPolicy,
    });
    this.mapPlayersToRegion(fighters, humanPlayers);
  }

  startNewGame(players = 0) {
    setData("humanPlayer", null);

    this.setState({
      settingsOpen: true,
      humanPlayers: players,
    });
  }

  onCharacterSelect(character) {
    const { playerStub } = this.state;

    this.setState({
      playerStub: {
        ...playerStub,
        main: character,
      },
    });
  }

  changePlayerName(value) {
    const { playerStub } = this.state;

    this.setState({
      playerStub: {
        ...playerStub,
        name: value,
      },
    });
  }

  changePlayerRegion(value) {
    const { playerStub } = this.state;

    this.setState({
      playerStub: {
        ...playerStub,
        region: value,
      },
    });
  }

  changePlayerStartingStats(stat, value, pointsUsed) {
    const { pointsRemaining, playerStub } = this.state;
    this.setState({
      pointsRemaining: pointsRemaining - pointsUsed,
      playerStub: {
        ...playerStub,
        stats: {
          ...playerStub.stats,
          [stat]: value,
        },
      },
    });
  }

  mapPlayersToRegion(fighterPool, humanPlayers) {
    const parentRegions = {};
    const players = [];
    const followedPlayers = [];
    const regions = [];
    const sponsors = structuredClone(SponsorData);

    let id = 10000;

    Object.keys(RegionData).forEach((key) => {
      const region = structuredClone(RegionData[key]);
      regions.push(region);
      if (region.id === 1000 || region.id === 10000) return;

      const parentRegion = region.parentRegion.name;

      if (!parentRegions[parentRegion]) {
        parentRegions[parentRegion] = [];
      }

      topPlayers.forEach((topPlayer) => {
        if (topPlayer.region === region.id) {
          id++;
          const mainRecord = fighterPool.find(
            (fighter) => fighter.name === topPlayer.main
          );
          if (!mainRecord) return;

          const playerStats = {};

          const playerMain = mainRecord.id;
          const player = new Player(id, playerMain, region, topPlayer.name, {
            neutral: 5,
            advantage: 5,
            disadvantage: 5,
            punish: 5,
            technique: 5,
          });
          player.useStatPoints(15);
          player.mainPreference = topPlayer.mainPreference;
          player.seed = 5;
          player.subscribers = 100 + Math.floor(Math.random() * 100);
          player.controller = 2;
          player.camera = 2;
          player.microphone = 2;
          player.editingEquipment = 2;
          region.players.push(player.id);
          region.parentRegion.players.push(player.id);
          const availableSponsors = Object.keys(sponsors)
            .filter((sponsorKey) => {
              const sponsor = sponsors[sponsorKey];
              return sponsor.id !== 1 && sponsor.players.length < sponsor.slots;
            })
            .sort(() => Math.random() - Math.random());

          if (availableSponsors.length > 0) {
            sponsors[availableSponsors[0]].players.push(id);
            player.sponsor = sponsors[availableSponsors[0]].id;
          }

          players.push(player);

          player.followLevel = FOLLOW_LEVELS.TWEETS_ONLY;
          followedPlayers.push(player);
        }
      });

      while (region.players.length < 128) {
        id++;
        const playerMain =
          fighterPool[Math.floor(Math.random() * fighterPool.length)].id;
        const player = new Player(id, playerMain, region);

        if (players.find((p) => p.name === player.name)) {
          player.name += Math.floor(Math.random() * 2000);
        }

        region.players.push(player.id);
        region.parentRegion.players.push(player.id);
        players.push(player);
      }

      parentRegions[parentRegion].push(region);
    });

    setData("sponsors", sponsors);
    setData("parentRegions", parentRegions);
    setData("regions", regions);
    setData("players", players);
    setData("followedPlayers", followedPlayers);
    setData("time", { week: 1, month: 1, season: 1 });

    if (humanPlayers === 0) {
      this.startGame();
    }
  }

  startGame() {
    this.setState({
      gameStarted: true,
    });
  }

  beginSinglePlayer() {
    const { playerStub } = this.state;

    const parentRegions = getData("parentRegions");
    const regions = getData("regions");
    const players = getData("players");
    const followedPlayers = getData("followedPlayers");
    const region = regions.find((r) => r.id === parseInt(playerStub.region));

    const humanPlayer = new Player(
      1,
      playerStub.main.id,
      region,
      playerStub.name,
      playerStub.stats
    );

    followedPlayers.push(humanPlayer);

    setData("humanPlayer", humanPlayer);
    setData("followedPlayers", followedPlayers);

    region.players.pop();
    region.players.unshift(humanPlayer.id);

    players.push(humanPlayer);

    Object.keys(parentRegions).forEach((key) => {
      parentRegions[key].forEach((subregion) => {
        if (region.id === subregion.id) {
          subregion.players.pop();
          subregion.players.unshift(1);
        }
      });
    });

    setData("players", players);
    setData("regions", regions);
    setData("parentRegions", parentRegions);

    const executive = players.find((p) => p.name === "Deane");
    const introContent = `<img src="${executive.avatar}" />
            <p>
                The year is 20XX. The latest Super Smash Bros. has just been released.
                The people have been clamoring and we have listened: we're going to
                focus on providing a competitive environment for our players.
            </p>
            <p>
                My name is ${executive.name}, and I've been tasked with creating
                the Smash XL Association, a centralised body for competitive Super Smash Bros.
                Now's your chance to prove yourself - you're one of the lucky thousand people
                we've invited to participate in the official tournaments. Become one of the
                best players in your region and you'll be invited to the SXLA Worldwide tournament
                to crown yourself best player in the world.
            </p>
            <p>
                We're all learning this new experience. The best place to learn how to play
                and how the new game works is on the <a class="internal-link" href="/wiki">wiki</a>.
            </p>
            <p>
                Now get out there and Smash!
            </p>`;
    const tweet = new NewsItem(
      `@${executive.name}`,
      introContent,
      "Welcome to 20XX",
      "tweet",
      `/players/${executive.id}`
    );

    const news = getData("news");
    news.unshift(tweet);
    setData("news", news);

    this.startGame();
  }

  render() {
    const {
      gameStarted,
      settingsOpen,
      humanPlayers,
      characterCreationOpen,
      playerStub,
      pointsRemaining,
      stats,
    } = this.state;

    if (gameStarted) return <Redirect to="/news" />;

    const time = getData("time");
    const regions = getData("regions");
    const humanPlayer = getData("humanPlayer");
    const fighters = getData("fighters");

    return (
      <section className="start">
        <div
          className={settingsOpen ? "logo-container small" : "logo-container"}
        >
          <span>2</span>
          <img src={`${process.env.PUBLIC_URL}/shine.png`} className="logo" />
          <span>XX</span>
          <p>Smash Manager</p>
        </div>

        {!settingsOpen && (
          <div className="actions">
            {time && (
              <>
                <Button href="/news" primary>
                  Resume game in progress
                </Button>
                <div className="resume">
                  {humanPlayer && (
                    <p>
                      {humanPlayer.name}
                      {humanPlayer.main && (
                        <img
                          src={
                            charIcons[
                              fighters.find((f) => f.id === humanPlayer.main)
                                .name
                            ]
                          }
                        />
                      )}
                      <img src={humanPlayer.flag} />
                    </p>
                  )}
                  {!humanPlayer && <p>Zero-player</p>}
                  <p>
                    S{time.season} M{time.month} W{time.week}
                  </p>
                </div>
              </>
            )}

            <Button onClick={this.startNewGame.bind(this, 1)}>
              Start new single player game
            </Button>
            <Button onClick={this.startNewGame.bind(this)}>
              Start new zero-player simulation
            </Button>
          </div>
        )}
        {settingsOpen && !characterCreationOpen && (
          <div className="actions">
            <fieldset>
              <label>
                Database
                <HelpModal>
                  <h2>Database</h2>
                  {Object.keys(settingData.database)
                    .filter((key) => key !== "label")
                    .map((key) => (
                      <Fragment key={key}>
                        <h3>{settingData.database[key].name}</h3>
                        <p>{settingData.database[key].description}</p>
                      </Fragment>
                    ))}
                </HelpModal>
              </label>
              <Dropdown
                onChange={this.changeDatabase.bind(this)}
                options={Object.keys(settingData.database)
                  .filter((key) => key !== "label")
                  .map((key) => ({
                    value: key,
                    display: settingData.database[key].name,
                    disabled: settingData.database[key].disabled,
                  }))}
                id="database"
              ></Dropdown>
            </fieldset>
            <fieldset>
              <label>
                Match engine
                <HelpModal>
                  <h2>Match engine</h2>
                  {humanPlayers > 0 && (
                    <p>Single player mode required an [R] match engine.</p>
                  )}
                  {Object.keys(settingData.matchEngine)
                    .filter((key) => {
                      let retVal = true;

                      if (key === "label") retVal = false;
                      else if (
                        humanPlayers > 0 &&
                        settingData.matchEngine[key].name.indexOf("R") === -1
                      ) {
                        retVal = false;
                      }
                      return retVal;
                    })
                    .map((key) => (
                      <Fragment key={key}>
                        <h3>{settingData.matchEngine[key].name}</h3>
                        <p>{settingData.matchEngine[key].description}</p>
                      </Fragment>
                    ))}
                </HelpModal>
              </label>
              <Dropdown
                onChange={this.changeMatchEngine.bind(this)}
                options={Object.keys(settingData.matchEngine)
                  .filter((key) => {
                    let retVal = true;

                    if (key === "label") retVal = false;
                    else if (
                      humanPlayers > 0 &&
                      settingData.matchEngine[key].name.indexOf("R") === -1
                    ) {
                      retVal = false;
                    }
                    return retVal;
                  })
                  .map((key) => ({
                    value: key,
                    display: settingData.matchEngine[key].name,
                    disabled: settingData.matchEngine[key].disabled,
                  }))}
                id="matchengine"
              ></Dropdown>
            </fieldset>
            <fieldset>
              <label>
                Stat mode
                <HelpModal>
                  <h2>Stat mode</h2>
                  {Object.keys(settingData.statMode)
                    .filter((key) => key !== "label")
                    .map((key) => (
                      <Fragment key={key}>
                        <h3>{settingData.statMode[key].name}</h3>
                        <p>{settingData.statMode[key].description}</p>
                      </Fragment>
                    ))}
                </HelpModal>
              </label>
              <Dropdown
                onChange={this.changeStatMode.bind(this)}
                options={Object.keys(settingData.statMode)
                  .filter((key) => key !== "label")
                  .map((key) => ({
                    value: key,
                    display: settingData.statMode[key].name,
                    disabled: settingData.statMode[key].disabled,
                  }))}
                id="statmode"
              ></Dropdown>
            </fieldset>
            <fieldset>
              <label>
                Balance patch policy
                <HelpModal>
                  <h2>Balance patch policy</h2>
                  {Object.keys(settingData.balancePatchPolicy)
                    .filter((key) => key !== "label")
                    .map((key) => (
                      <Fragment key={key}>
                        <h3>{settingData.balancePatchPolicy[key].name}</h3>
                        <p>{settingData.balancePatchPolicy[key].description}</p>
                      </Fragment>
                    ))}
                </HelpModal>
              </label>
              <Dropdown
                onChange={this.changeBalancePatchPolicy.bind(this)}
                options={Object.keys(settingData.balancePatchPolicy)
                  .filter((key) => key !== "label")
                  .map((key) => ({
                    value: key,
                    display: settingData.balancePatchPolicy[key].name,
                    disabled: settingData.balancePatchPolicy[key].disabled,
                  }))}
                id="balancepatchpolicy"
              ></Dropdown>
            </fieldset>
            <fieldset>
              <label>
                DLC policy
                <HelpModal>
                  <h2>DLC policy</h2>
                  {Object.keys(settingData.dlcPolicy)
                    .filter((key) => key !== "label")
                    .map((key) => (
                      <Fragment key={key}>
                        <h3>{settingData.dlcPolicy[key].name}</h3>
                        <p>{settingData.dlcPolicy[key].description}</p>
                      </Fragment>
                    ))}
                </HelpModal>
              </label>
              <Dropdown
                onChange={this.changeDLCPolicy.bind(this)}
                options={Object.keys(settingData.dlcPolicy)
                  .filter((key) => key !== "label")
                  .map((key) => ({
                    value: key,
                    display: settingData.dlcPolicy[key].name,
                    disabled: settingData.dlcPolicy[key].disabled,
                  }))}
                id="dlcpolicy"
              ></Dropdown>
            </fieldset>
            {humanPlayers === 0 && (
              <Button onClick={this.initSim.bind(this)} primary>
                Begin game
              </Button>
            )}

            {humanPlayers > 0 && (
              <Button onClick={this.openCharacterSettings.bind(this)} primary>
                Define your player
              </Button>
            )}
          </div>
        )}

        {characterCreationOpen && (
          <div className="actions">
            <fieldset>
              <label>Player name</label>
              <TextInput
                name="player-name"
                onInput={this.changePlayerName.bind(this)}
              />
            </fieldset>
            <fieldset>
              <label>Region</label>
              <Dropdown
                onChange={this.changePlayerRegion.bind(this)}
                options={regions
                  .filter(
                    (region) =>
                      region.name !== "Worldwide" && region.name !== "WiFi"
                  )
                  .map((region) => ({
                    value: region.id,
                    display: region.name,
                  }))}
                id="region"
              ></Dropdown>
            </fieldset>
            <fieldset>
              <label>
                Distribute your abilities
                <br />
                <small>Points remaining: {pointsRemaining}</small>
                <HelpModal>
                  <h2>Abilities</h2>
                  <p>
                    These 5 abilities are the core of your player. Distribute
                    your points wisely. You will have the opportunity to improve
                    later on.
                  </p>
                  <h3>Neutral</h3>
                  <p>Your ability to win the neutral state of play.</p>
                  <h3>Advantage</h3>
                  <p>
                    How well you can follow up winning the neutral state of
                    play.
                  </p>
                  <h3>Disadvantage</h3>
                  <p>
                    How well you can defend yourself and break out of
                    disadvantage state having lost neutral.
                  </p>
                  <h3>Punish</h3>
                  <p>How good you are at closing out a stock.</p>
                  <h3>Technique</h3>
                  <p>Your ability to DI and recover.</p>
                </HelpModal>
              </label>

              <dl>
                <dt>Neutral</dt>
                <dd>
                  <StatAdder
                    startValue={1}
                    max={24}
                    min={1}
                    onChange={this.changePlayerStartingStats.bind(
                      this,
                      "neutral"
                    )}
                    pointsToSpend={pointsRemaining}
                  />
                </dd>

                <dt>Advantage</dt>
                <dd>
                  <StatAdder
                    startValue={1}
                    max={24}
                    min={1}
                    pointsToSpend={pointsRemaining}
                    onChange={this.changePlayerStartingStats.bind(
                      this,
                      "advantage"
                    )}
                  />
                </dd>

                <dt>Disadvantage</dt>
                <dd>
                  <StatAdder
                    startValue={1}
                    max={24}
                    min={1}
                    pointsToSpend={pointsRemaining}
                    onChange={this.changePlayerStartingStats.bind(
                      this,
                      "disadvantage"
                    )}
                  />
                </dd>

                <dt>Punish</dt>
                <dd>
                  <StatAdder
                    startValue={1}
                    max={24}
                    min={1}
                    pointsToSpend={pointsRemaining}
                    onChange={this.changePlayerStartingStats.bind(
                      this,
                      "punish"
                    )}
                  />
                </dd>

                <dt>Technique</dt>
                <dd>
                  <StatAdder
                    startValue={1}
                    max={24}
                    min={1}
                    pointsToSpend={pointsRemaining}
                    onChange={this.changePlayerStartingStats.bind(
                      this,
                      "technique"
                    )}
                  />
                </dd>
              </dl>
            </fieldset>
            <fieldset>
              <label>Choose your main</label>
              <CharacterSelectScreen
                onCharacterSelect={this.onCharacterSelect.bind(this)}
              />
            </fieldset>

            <Button
              onClick={this.beginSinglePlayer.bind(this)}
              primary
              disabled={playerStub.name === "" || playerStub.main === null}
            >
              Begin game
            </Button>
          </div>
        )}
        <footer>v{packageJson.version}</footer>
      </section>
    );
  }
}

export default Home;
