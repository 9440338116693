import Fighter, { FIGHTER_TYPES } from "../classes/Fighter";

export const Fighters_SSBU = [
  new Fighter(
    "Mario",
    {
      neutral: 7,
      advantage: 6,
      disadvantage: 4,
      punish: 6,
      recovery: 4,
      weight: 9,
      projectiles: 6,
      mobility: 6,
      outofshield: 7,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 2,
    },
    null,
    "Meme Edgeguarding"
  ),
  new Fighter(
    "Donkey Kong",
    {
      neutral: 5,
      advantage: 7,
      disadvantage: 3,
      punish: 8,
      recovery: 3,
      weight: 12,
      projectiles: 0,
      mobility: 3,
      outofshield: 6,
      type: FIGHTER_TYPES.BAIT_PUNISH,
      hype: 4,
    },
    null,
    "Cargo Throw"
  ),
  new Fighter(
    "Link",
    {
      neutral: 7,
      advantage: 5,
      disadvantage: 4,
      punish: 6,
      recovery: 7,
      weight: 10,
      projectiles: 9,
      mobility: 4,
      outofshield: 7,
      type: FIGHTER_TYPES.ZONER,
      hype: -3,
    },
    null,
    "Remote Bombs"
  ),
  new Fighter(
    "Samus",
    {
      neutral: 8,
      advantage: 4,
      disadvantage: 4,
      punish: 5,
      recovery: 7,
      weight: 10,
      projectiles: 8,
      mobility: 4,
      outofshield: 7,
      type: FIGHTER_TYPES.ZONER,
      hype: -4,
    },
    null,
    "Charge Shot"
  ),
  new Fighter(
    "Yoshi",
    {
      neutral: 8,
      advantage: 6,
      disadvantage: 7,
      punish: 4,
      recovery: 9,
      weight: 10,
      projectiles: 5,
      mobility: 9,
      outofshield: 4,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: -1,
    },
    null,
    "Double Jump Super Armour"
  ),
  new Fighter(
    "Kirby",
    {
      neutral: 6,
      advantage: 6,
      disadvantage: 7,
      punish: 7,
      recovery: 9,
      weight: 7,
      projectiles: 1,
      mobility: 5,
      outofshield: 4,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 1,
    },
    null,
    "Copy"
  ),
  new Fighter(
    "Fox",
    {
      neutral: 9,
      advantage: 8,
      disadvantage: 3,
      punish: 4,
      recovery: 4,
      weight: 7,
      projectiles: 4,
      mobility: 8,
      outofshield: 5,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 4,
    },
    null,
    "Reflector"
  ),
  new Fighter(
    "Pikachu",
    {
      neutral: 7,
      advantage: 8,
      disadvantage: 5,
      punish: 4,
      recovery: 10,
      weight: 7,
      projectiles: 6,
      mobility: 9,
      outofshield: 4,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 4,
    },
    null,
    "Quick Attack"
  ),

  new Fighter("Luigi", {
    neutral: 8,
    advantage: 9,
    disadvantage: 5,
    punish: 7,
    recovery: 4,
    weight: 9,
    projectiles: 4,
    mobility: 5,
    outofshield: 8,
    type: FIGHTER_TYPES.ZONER,
    hype: 1,
  }),
  new Fighter("Ness", {
    neutral: 7,
    advantage: 6,
    disadvantage: 6,
    punish: 8,
    recovery: 3,
    weight: 9,
    projectiles: 8,
    mobility: 5,
    outofshield: 5,
    type: FIGHTER_TYPES.ZONER,
    hype: -1,
  }),
  new Fighter(
    "Captain Falcon",
    {
      neutral: 6,
      advantage: 8,
      disadvantage: 5,
      punish: 9,
      recovery: 5,
      weight: 10,
      projectiles: 0,
      mobility: 8,
      outofshield: 6,
      hype: 5,
      type: FIGHTER_TYPES.RUSHDOWN,
    },
    null,
    "Hype Master"
  ),
  new Fighter("Jigglypuff", {
    neutral: 4,
    advantage: 7,
    disadvantage: 5,
    punish: 10,
    recovery: 10,
    weight: 6,
    projectiles: 0,
    mobility: 9,
    outofshield: 3,
    type: FIGHTER_TYPES.ZONER,
    hype: 1,
  }),
  new Fighter("Peach", {
    neutral: 8,
    advantage: 6,
    disadvantage: 5,
    punish: 8,
    recovery: 8,
    weight: 8,
    projectiles: 8,
    mobility: 7,
    outofshield: 5,
    type: FIGHTER_TYPES.ZONER,
    hype: -1,
  }),
  new Fighter("Bowser", {
    neutral: 6,
    advantage: 7,
    disadvantage: 7,
    punish: 8,
    recovery: 6,
    weight: 13,
    projectiles: 6,
    mobility: 6,
    outofshield: 9,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 4,
  }),
  new Fighter("Ice Climbers", {
    neutral: 6,
    advantage: 10,
    disadvantage: 6,
    punish: 10,
    recovery: 6,
    weight: 9,
    projectiles: 2,
    mobility: 4,
    outofshield: 4,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 2,
  }),
  new Fighter("Sheik", {
    neutral: 10,
    advantage: 9,
    disadvantage: 4,
    punish: 2,
    recovery: 6,
    weight: 7,
    projectiles: 7,
    mobility: 9,
    outofshield: 3,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 2,
  }),
  new Fighter("Zelda", {
    neutral: 4,
    advantage: 5,
    disadvantage: 6,
    punish: 7,
    recovery: 6,
    weight: 8,
    projectiles: 6,
    mobility: 5,
    outofshield: 7,
    type: FIGHTER_TYPES.ZONER,
    hype: -4,
  }),
  new Fighter("Dr. Mario", {
    neutral: 4,
    advantage: 6,
    disadvantage: 4,
    punish: 8,
    recovery: 2,
    weight: 9,
    projectiles: 6,
    mobility: 4,
    outofshield: 6,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 3,
  }),
  new Fighter(
    "Pichu",
    {
      neutral: 8,
      advantage: 7,
      disadvantage: 2,
      punish: 5,
      recovery: 8,
      weight: 6,
      projectiles: 7,
      mobility: 9,
      outofshield: 3,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 4,
    },
    null,
    "Self-Harm"
  ),
  new Fighter(
    "Falco",
    {
      neutral: 7,
      advantage: 7,
      disadvantage: 6,
      punish: 5,
      recovery: 6,
      weight: 8,
      projectiles: 6,
      mobility: 7,
      outofshield: 5,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 3,
    },
    null,
    "Reflector"
  ),
  new Fighter("Marth", {
    neutral: 7,
    advantage: 6,
    disadvantage: 5,
    punish: 8,
    recovery: 5,
    weight: 9,
    projectiles: 0,
    mobility: 7,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Lucina", {
    neutral: 8,
    advantage: 6,
    disadvantage: 5,
    punish: 7,
    recovery: 5,
    weight: 9,
    projectiles: 0,
    mobility: 7,
    outofshield: 6,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: -3,
  }),
  new Fighter("Young Link", {
    neutral: 8,
    advantage: 7,
    disadvantage: 4,
    punish: 5,
    recovery: 6,
    weight: 8,
    projectiles: 7,
    mobility: 7,
    outofshield: 7,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Ganondorf", {
    neutral: 2,
    advantage: 8,
    disadvantage: 2,
    punish: 10,
    recovery: 2,
    weight: 11,
    projectiles: 0,
    mobility: 2,
    outofshield: 4,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 5,
  }),
  new Fighter("Mewtwo", {
    neutral: 4,
    advantage: 8,
    disadvantage: 5,
    punish: 8,
    recovery: 8,
    weight: 7,
    projectiles: 4,
    mobility: 5,
    outofshield: 5,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 2,
  }),
  new Fighter("Roy", {
    neutral: 8,
    advantage: 7,
    disadvantage: 4,
    punish: 9,
    recovery: 4,
    weight: 9,
    projectiles: 0,
    mobility: 8,
    outofshield: 6,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 2,
  }),
  new Fighter("Chrom", {
    neutral: 9,
    advantage: 7,
    disadvantage: 4,
    punish: 8,
    recovery: 3,
    weight: 9,
    projectiles: 0,
    mobility: 8,
    outofshield: 7,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 2,
  }),
  new Fighter("Mr. Game & Watch", {
    neutral: 7,
    advantage: 9,
    disadvantage: 5,
    punish: 10,
    recovery: 9,
    weight: 7,
    projectiles: 5,
    mobility: 5,
    outofshield: 9,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Meta Knight", {
    neutral: 6,
    advantage: 7,
    disadvantage: 6,
    punish: 7,
    recovery: 9,
    weight: 8,
    projectiles: 0,
    mobility: 7,
    outofshield: 7,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 1,
  }),
  new Fighter("Pit", {
    neutral: 6,
    advantage: 6,
    disadvantage: 6,
    punish: 6,
    recovery: 8,
    weight: 9,
    projectiles: 7,
    mobility: 6,
    outofshield: 3,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 0,
  }),
  new Fighter("Zero Suit Samus", {
    neutral: 8,
    advantage: 8,
    disadvantage: 6,
    punish: 7,
    recovery: 6,
    weight: 8,
    projectiles: 5,
    mobility: 8,
    outofshield: 7,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 1,
  }),
  new Fighter("Wario", {
    neutral: 5,
    advantage: 8,
    disadvantage: 3,
    punish: 8,
    recovery: 7,
    weight: 10,
    projectiles: 1,
    mobility: 7,
    outofshield: 7,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 2,
  }),
  new Fighter("Snake", {
    neutral: 9,
    advantage: 6,
    disadvantage: 4,
    punish: 8,
    recovery: 6,
    weight: 10,
    projectiles: 8,
    mobility: 4,
    outofshield: 5,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Ike", {
    neutral: 6,
    advantage: 5,
    disadvantage: 7,
    punish: 7,
    recovery: 5,
    weight: 10,
    projectiles: 0,
    mobility: 5,
    outofshield: 6,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 1,
  }),
  new Fighter(
    "Pokemon Trainer",
    {
      neutral: 8,
      advantage: 8,
      disadvantage: 3,
      punish: 7,
      recovery: 5,
      weight: 0,
      projectiles: 7,
      mobility: 7,
      outofshield: 7,
      type: FIGHTER_TYPES.DYNAMIC,
      hype: 2,
    },
    null,
    "Pokemon Switch",
    [
      new Fighter("Squirtle", {
        neutral: 8,
        advantage: 6,
        disadvantage: 2,
        punish: 2,
        recovery: 5,
        weight: 7,
        projectiles: 3,
        mobility: 8,
        outofshield: 5,
        type: FIGHTER_TYPES.RUSHDOWN,
        hype: 1,
      }),
      new Fighter("Ivysaur", {
        neutral: 7,
        advantage: 8,
        disadvantage: 4,
        punish: 7,
        recovery: 4,
        weight: 9,
        projectiles: 7,
        mobility: 5,
        outofshield: 8,
        type: FIGHTER_TYPES.ZONER,
        hype: -1,
      }),
      new Fighter("Charizard", {
        neutral: 5,
        advantage: 7,
        disadvantage: 2,
        punish: 8,
        recovery: 6,
        weight: 11,
        projectiles: 6,
        mobility: 5,
        outofshield: 7,
        type: FIGHTER_TYPES.BAIT_PUNISH,
        hype: 3,
      }),
    ]
  ),
  new Fighter("Diddy Kong", {
    neutral: 9,
    advantage: 7,
    disadvantage: 6,
    punish: 5,
    recovery: 4,
    weight: 9,
    projectiles: 8,
    mobility: 8,
    outofshield: 4,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 2,
  }),
  new Fighter("Lucas", {
    neutral: 6,
    advantage: 6,
    disadvantage: 5,
    punish: 6,
    recovery: 4,
    weight: 9,
    projectiles: 7,
    mobility: 6,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Sonic", {
    neutral: 8,
    advantage: 6,
    disadvantage: 7,
    punish: 4,
    recovery: 7,
    weight: 8,
    projectiles: 0,
    mobility: 10,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -4,
  }),
  new Fighter("King Dedede", {
    neutral: 6,
    advantage: 4,
    disadvantage: 2,
    punish: 8,
    recovery: 7,
    weight: 12,
    projectiles: 8,
    mobility: 2,
    outofshield: 4,
    type: FIGHTER_TYPES.ZONER,
    hype: 1,
  }),
  new Fighter("Olimar", {
    neutral: 7,
    advantage: 4,
    disadvantage: 9,
    punish: 4,
    recovery: 8,
    weight: 7,
    projectiles: 8,
    mobility: 5,
    outofshield: 7,
    type: FIGHTER_TYPES.ZONER,
    hype: -5,
  }),
  new Fighter(
    "Lucario",
    {
      neutral: 4,
      advantage: 5,
      disadvantage: 5,
      punish: 6,
      recovery: 7,
      weight: 9,
      projectiles: 6,
      mobility: 4,
      outofshield: 5,
      type: FIGHTER_TYPES.ZONER,
      hype: -4,
    },
    null,
    "Aura"
  ),
  new Fighter("R.O.B.", {
    neutral: 8,
    advantage: 7,
    disadvantage: 4,
    punish: 7,
    recovery: 10,
    weight: 10,
    projectiles: 8,
    mobility: 6,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -1,
  }),
  new Fighter("Toon Link", {
    neutral: 6,
    advantage: 5,
    disadvantage: 5,
    punish: 6,
    recovery: 7,
    weight: 9,
    projectiles: 8,
    mobility: 6,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Wolf", {
    neutral: 8,
    advantage: 8,
    disadvantage: 4,
    punish: 7,
    recovery: 3,
    weight: 9,
    projectiles: 8,
    mobility: 5,
    outofshield: 7,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 2,
  }),
  new Fighter("Villager", {
    neutral: 7,
    advantage: 6,
    disadvantage: 5,
    punish: 6,
    recovery: 9,
    weight: 9,
    projectiles: 8,
    mobility: 5,
    outofshield: 5,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Mega Man", {
    neutral: 7,
    advantage: 7,
    disadvantage: 6,
    punish: 7,
    recovery: 6,
    weight: 10,
    projectiles: 9,
    mobility: 6,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -4,
  }),
  new Fighter("Wii Fit Trainer", {
    neutral: 7,
    advantage: 6,
    disadvantage: 6,
    punish: 8,
    recovery: 6,
    weight: 9,
    projectiles: 7,
    mobility: 8,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -1,
  }),
  new Fighter("Rosalina & Luma", {
    neutral: 8,
    advantage: 5,
    disadvantage: 9,
    punish: 6,
    recovery: 5,
    weight: 8,
    projectiles: 6,
    mobility: 5,
    outofshield: 4,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Little Mac", {
    neutral: 5,
    advantage: 5,
    disadvantage: 2,
    punish: 11,
    recovery: 1,
    weight: 8,
    projectiles: 0,
    mobility: 8,
    outofshield: 8,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 3,
  }),
  new Fighter("Greninja", {
    neutral: 8,
    advantage: 7,
    disadvantage: 5,
    punish: 5,
    recovery: 8,
    weight: 8,
    projectiles: 7,
    mobility: 8,
    outofshield: 7,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 2,
  }),
  new Fighter("Mii Brawler", {
    neutral: 6,
    advantage: 6,
    disadvantage: 5,
    punish: 7,
    recovery: 4,
    weight: 9,
    projectiles: 4,
    mobility: 6,
    outofshield: 8,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 3,
  }),
  new Fighter("Mii Swordfighter", {
    neutral: 6,
    advantage: 5,
    disadvantage: 5,
    punish: 5,
    recovery: 6,
    weight: 10,
    projectiles: 7,
    mobility: 5,
    outofshield: 7,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Mii Gunner", {
    neutral: 7,
    advantage: 4,
    disadvantage: 5,
    punish: 5,
    recovery: 6,
    weight: 10,
    projectiles: 7,
    mobility: 4,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -3,
  }),
  new Fighter("Palutena", {
    neutral: 8,
    advantage: 8,
    disadvantage: 6,
    punish: 5,
    recovery: 6,
    weight: 9,
    projectiles: 6,
    mobility: 6,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("PAC-MAN", {
    neutral: 8,
    advantage: 8,
    disadvantage: 6,
    punish: 4,
    recovery: 9,
    weight: 9,
    projectiles: 8,
    mobility: 7,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: 4,
  }),
  new Fighter("Robin", {
    neutral: 7,
    advantage: 7,
    disadvantage: 5,
    punish: 7,
    recovery: 7,
    weight: 9,
    projectiles: 8,
    mobility: 3,
    outofshield: 4,
    type: FIGHTER_TYPES.ZONER,
    hype: -4,
  }),
  new Fighter("Shulk", {
    neutral: 6,
    advantage: 7,
    disadvantage: 11, // SHIELD
    punish: 11, // SMASH
    recovery: 7,
    weight: 9,
    projectiles: 0,
    mobility: 6,
    outofshield: 7,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: -1,
  }),
  new Fighter("Bowser Jr.", {
    neutral: 5,
    advantage: 5,
    disadvantage: 6,
    punish: 6,
    recovery: 6,
    weight: 10,
    projectiles: 6,
    mobility: 6,
    outofshield: 7,
    type: FIGHTER_TYPES.ZONER,
    hype: 1,
  }),
  new Fighter("Duck Hunt Duo", {
    neutral: 7,
    advantage: 7,
    disadvantage: 5,
    punish: 6,
    recovery: 6,
    weight: 8,
    projectiles: 9,
    mobility: 7,
    outofshield: 4,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Ryu", {
    neutral: 6,
    advantage: 7,
    disadvantage: 3,
    punish: 8,
    recovery: 4,
    weight: 10,
    projectiles: 6,
    mobility: 7,
    outofshield: 6,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 2,
  }),
  new Fighter("Ken", {
    neutral: 6,
    advantage: 8,
    disadvantage: 3,
    punish: 7,
    recovery: 4,
    weight: 10,
    projectiles: 6,
    mobility: 7,
    outofshield: 6,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 3,
  }),
  new Fighter("Cloud", {
    neutral: 7,
    advantage: 6,
    disadvantage: 6,
    punish: 7,
    recovery: 4,
    weight: 10,
    projectiles: 7,
    mobility: 5,
    outofshield: 6,
    type: FIGHTER_TYPES.ZONER,
    hype: -2,
  }),
  new Fighter("Corrin", {
    neutral: 6,
    advantage: 6,
    disadvantage: 6,
    punish: 6,
    recovery: 6,
    weight: 9,
    projectiles: 6,
    mobility: 7,
    outofshield: 5,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: -2,
  }),
  new Fighter("Bayonetta", {
    neutral: 7,
    advantage: 9,
    disadvantage: 10, // BAT WITHIN
    punish: 6,
    recovery: 7,
    weight: 8,
    projectiles: 7,
    mobility: 7,
    outofshield: 6,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: 1,
  }),
  new Fighter("Inkling", {
    neutral: 8,
    advantage: 10, // INK
    disadvantage: 7,
    punish: 7,
    recovery: 8,
    weight: 9,
    projectiles: 8,
    mobility: 8,
    outofshield: 6,
    type: FIGHTER_TYPES.RUSHDOWN,
    hype: -1,
  }),
  new Fighter("Ridley", {
    neutral: 5,
    advantage: 6,
    disadvantage: 4,
    punish: 11, // SKEWER
    recovery: 7,
    weight: 10,
    projectiles: 6,
    mobility: 6,
    outofshield: 5,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 4,
  }),
  new Fighter("Belmonts", {
    neutral: 8,
    advantage: 5,
    disadvantage: 5,
    punish: 7,
    recovery: 2,
    weight: 10,
    projectiles: 8,
    mobility: 4,
    outofshield: 5,
    type: FIGHTER_TYPES.ZONER,
    hype: -3,
  }),
  new Fighter("King K. Rool", {
    neutral: 6,
    advantage: 7,
    disadvantage: 5,
    punish: 9,
    recovery: 9,
    weight: 13,
    projectiles: 7,
    mobility: 4,
    outofshield: 5,
    type: FIGHTER_TYPES.ZONER,
    hype: 3,
  }),
  new Fighter("Isabelle", {
    neutral: 7,
    advantage: 6,
    disadvantage: 7,
    punish: 4,
    recovery: 9,
    weight: 8,
    projectiles: 6,
    mobility: 5,
    outofshield: 5,
    type: FIGHTER_TYPES.ZONER,
    hype: -3,
  }),
  new Fighter("Incineroar", {
    neutral: 4,
    advantage: 8,
    disadvantage: 10, // REVENGE
    punish: 7,
    recovery: 3,
    weight: 11,
    projectiles: 0,
    mobility: 5,
    outofshield: 6,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 4,
  }),
  new Fighter("Piranha Plant", {
    neutral: 5,
    advantage: 6,
    disadvantage: 6,
    punish: 7,
    recovery: 8,
    weight: 11,
    projectiles: 8,
    mobility: 5,
    outofshield: 5,
    type: FIGHTER_TYPES.BAIT_PUNISH,
    hype: 1,
  }),
  new Fighter(
    "Joker",
    {
      neutral: 8,
      advantage: 8,
      disadvantage: 10, // ARSENE
      punish: 6,
      recovery: 6,
      weight: 9,
      type: FIGHTER_TYPES.RUSHDOWN,
      projectiles: 8,
      mobility: 8,
      outofshield: 6,
      hype: 2,
    },
    0
  ),
  new Fighter(
    "Hero",
    {
      neutral: 5,
      advantage: 7, // TODO Random lol
      disadvantage: 6,
      punish: 7, // TODO Random lol
      recovery: 6,
      weight: 10,
      projectiles: 6,
      mobility: 4,
      outofshield: 5,
      type: FIGHTER_TYPES.BAIT_PUNISH,
      hype: 4,
    },
    1
  ),
  new Fighter(
    "Banjo & Kazooie",
    {
      neutral: 7,
      advantage: 6,
      disadvantage: 8,
      punish: 8,
      recovery: 7,
      weight: 10,
      projectiles: 7,
      mobility: 6,
      outofshield: 6,
      type: FIGHTER_TYPES.ZONER,
      hype: -2,
    },
    2
  ),
  new Fighter(
    "Terry",
    {
      neutral: 6,
      advantage: 9,
      disadvantage: 5,
      punish: 11, // BUSTAH WOLF
      recovery: 4,
      weight: 10,
      type: FIGHTER_TYPES.BAIT_PUNISH,
      projectiles: 6,
      mobility: 6,
      outofshield: 6,
      hype: 1,
    },
    3
  ),
  new Fighter(
    "Byleth",
    {
      neutral: 6,
      advantage: 7,
      disadvantage: 5,
      punish: 8,
      recovery: 5,
      weight: 9,
      projectiles: 4,
      mobility: 4,
      outofshield: 5,
      type: FIGHTER_TYPES.BAIT_PUNISH,
      hype: 1,
    },
    4
  ),
  new Fighter(
    "Min Min",
    {
      neutral: 7,
      advantage: 9,
      disadvantage: 4,
      punish: 8,
      recovery: 4,
      weight: 10,
      projectiles: 7, // technically not projectiles but let's be realistic
      mobility: 6,
      outofshield: 4,
      type: FIGHTER_TYPES.ZONER,
      hype: -4,
    },
    5
  ),
  new Fighter(
    "Steve",
    {
      neutral: 7, // TODO improve as match goes on due to mining resources
      advantage: 7,
      disadvantage: 6,
      punish: 6,
      recovery: 7,
      weight: 9,
      projectiles: 4,
      mobility: 6,
      outofshield: 6,
      type: FIGHTER_TYPES.ZONER,
      hype: 1,
    },
    6
  ),
  new Fighter(
    "Sephiroth",
    {
      neutral: 7,
      advantage: 6,
      disadvantage: 7,
      punish: 9,
      recovery: 6,
      weight: 7,
      projectiles: 7,
      mobility: 5,
      outofshield: 6,
      type: FIGHTER_TYPES.BAIT_PUNISH,
      hype: 3,
    },
    7
  ),
  new Fighter(
    "Pyra / Mythra",
    {
      // TODO Form swaps
      neutral: 8,
      advantage: 8,
      disadvantage: 5,
      punish: 8,
      recovery: 6,
      weight: 9,
      projectiles: 3,
      mobility: 8,
      outofshield: 6,
      type: FIGHTER_TYPES.RUSHDOWN,
      hype: 1,
    },
    8
  ),
  new Fighter(
    "Kazuya",
    {
      neutral: 8,
      advantage: 7,
      disadvantage: 4,
      punish: 7,
      recovery: 5,
      weight: 11,
      projectiles: 4,
      mobility: 5,
      outofshield: 8,
      type: FIGHTER_TYPES.BAIT_PUNISH,
      hype: 1,
    },
    9
  ),
  new Fighter(
    "Sora",
    {
      neutral: 7,
      advantage: 8,
      disadvantage: 7,
      punish: 6,
      recovery: 9,
      type: FIGHTER_TYPES.RUSHDOWN,
      weight: 8,
      projectiles: 7,
      mobility: 9,
      outofshield: 4,
      hype: -3,
    },
    10
  ),
];
