import React, { Component } from "react";
import Button from "../button/Button";

import "./Notification.scss";

class Notification extends Component {
  state = {
    open: true,
  };

  toggle() {
    const { game } = this.props;

    this.setState({
      open: !this.state.open,
    });

    setTimeout(() => {
      game.clearNotification();
    }, 1500);
  }

  render() {
    const { game } = this.props;
    const { open } = this.state;

    return (
      <>
        <div
          className={open ? "notification-mask open" : "notification-mask"}
        />
        <div className={open ? "notification open" : "notification"}>
          {game.notification}
          <Button onClick={this.toggle.bind(this)} primary>
            OK
          </Button>
        </div>
      </>
    );
  }
}

export default Notification;
