export const SPONSOR_VALUES = {
    HYPE: "Hype",
    RESULTS: "Results",
    MEMES: "Memes",
    CHAMPIONS: "Champions",
};

export const SPONSOR_HYPE_TOLERANCE = 50;

export default class Sponsor {
    constructor(
        id,
        name,
        salary,
        product,
        values,
        strikes = 3,
        description = "",
        slots = 8,
        tournaments = []
    ) {
        this.id = id;
        this.name = name;
        this.shortcode = name
            .split(" ")
            .map((a) => a[0])
            .join("")
            .toUpperCase();
        this.product = product;
        this.salary = salary;
        this.values = values;
        this.description = description;
        this.players = [];
        this.slots = slots;
        this.strikes = strikes;
        this.tournaments = tournaments;
        this.emblem = `${
            process.env.PUBLIC_URL
        }/sponsors/${this.name.toLowerCase().replace(/ /g, "_")}.png`;
        this.blacklist = [];
    }
}
