
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
  names,
} from "unique-names-generator";
import { STRATEGIES } from "../data/Strategies";
import { getData } from "../util";

export const MAIN_PREFERENCE_TYPES = {
  TIER_WHORE: "Tier Whore", // 100% chance of changing character if not in top 20 to a top 20 character
  BUFF_CHASER: "Buff Chaser", // 40% chance of changing after each balance patch to a buffed character
  LOW_TIER_HERO: "Low Tier Hero", // 40% chance of changing character if not in bottom 20 to a bottom 20 character
  FIXED: "Fixed Main", // Will never change character
  FLUID: "Fluid", // 10% chance of changing to a different random character
  ZONER_FLUID: "Zoner", // 10% chance of changing to a zoner
  RUSHDOWN_FLUID: "Rushdown", // 10% chance of changing to a rushdown
  BAIT_PUNISH_FLUID: "Bait and Punish", // 10% chance of changing to a bait+punish
  DLC_JUNKIE: "DLC Junkie", // 20% chance to pick up the latest DLC character
};

export const PERSONALITIES = {
  CHILL: "CHILL",
  PROFESSIONAL: "PROFESSIONAL",
  CHILDISH: "CHILDISH",
};

export const TRAINING_STYLES = {};

export const boostCharacterFamiliarity = (player, character, amount) => {
  player.characterFamiliarity[character] = player.characterFamiliarity[
    character
  ]
    ? player.characterFamiliarity[character] + amount
    : amount;
};

export default class Player {
  constructor(id, main = null, region = null, name = null, stats = null) {
    this.id = id;
    this.sponsor = null;

    if (region) {
      this.flag = region.flag;
      this.region = region.id;
    }

    if (!name) this.generateName();
    else this.name = name;

    // const avatar = createAvatar(style, {
    //   seed: this.name,
    //   dataUri: true
    // });
    this.avatar = `https://avatars.dicebear.com/api/open-peeps/${this.name}.svg?background=%23ffffff66`;

    const personalityIndex = Math.floor(
      Math.random() * Object.keys(PERSONALITIES).length
    );
    const personality = Object.keys(PERSONALITIES)[personalityIndex];

    const neutralStrategyIndex = Math.floor(Math.random() * 4);
    const neutralStrategyKey = Object.keys(STRATEGIES.NEUTRAL_OPTIONS)[
      neutralStrategyIndex
    ];
    this.neutralStrategy = STRATEGIES.NEUTRAL_OPTIONS[neutralStrategyKey];
    this.personality = PERSONALITIES[personality];

    this.characterFamiliarity = {};

    // Technical
    if (!stats) {
      this.neutral = 1;
      this.advantage = 1;
      this.disadvantage = 1;
      this.punish = 1;
      this.technique = 1;

      this.useStatPoints(10);
    } else {
      this.neutral = stats.neutral;
      this.advantage = stats.advantage;
      this.disadvantage = stats.disadvantage;
      this.punish = stats.punish;
      this.technique = stats.technique;
    }

    // Personal
    this.seed = 1;
    this.strikes = 0;
    this.finance = 1000;
    this.hype = 1000;
    this.entertainment = 1000;
    this.subscribers = 0;
    // this.volatility = 100;

    // Characters
    if (!this.main) {
      this.main = main;
      const mainPreferenceType = Math.floor(
        Math.random() * Object.keys(MAIN_PREFERENCE_TYPES).length
      );
      this.mainPreference =
        MAIN_PREFERENCE_TYPES[
          Object.keys(MAIN_PREFERENCE_TYPES)[mainPreferenceType]
        ];
    }

    this.history = [
      {
        main: this.main,
        globalRanking: null,
        tournamentResults: [],
      },
    ];

    this.experience = 0;

    // equipment
    this.controller = 1;
    this.camera = 1;
    this.microphone = 1;
    this.editingEquipment = 1;
  }

  useStatPoints(points = 1) {
    const stats = [
      this.neutral,
      this.advantage,
      this.disadvantage,
      this.punish,
      this.technique,
    ];

    for (let i = 0; i < points; i++) {
      const index = Math.floor(Math.random() * stats.length);
      if (stats[index] < 16) stats[index]++;
      else stats[index]--;
    }

    this.neutral = stats[0];
    this.advantage = stats[1];
    this.disadvantage = stats[2];
    this.punish = stats[3];
    this.technique = stats[4];
  }

  mapJson(json) {
    this.id = json.id;
    this.avatar = json.avatar;
    this.flag = json.flag;
    this.main = json.main;
    this.mainPreference = json.mainPreference;
    this.name = json.name;
    this.region = json.region;
    this.neutral = json.neutral;
    this.advantage = json.advantage;
    this.disadvantage = json.disadvantage;
    this.punish = json.punish;
    this.technique = json.technique;
    this.history = json.history;
    this.seed = json.seed;
    this.sponsor = json.sponsor;
    this.strikes = json.strikes;
    this.hype = json.hype;
    this.experience = json.experience;
    this.entertainment = json.entertainment;
    this.subscribers = json.subscribers;
    this.controller = json.controller;
    this.camera = json.camera;
    this.microphone = json.microphone;
    this.editingEquipment = json.editingEquipment;
  }

  generateName() {
    let prefix =
      Math.random() > 0.66 ? adjectives : Math.random() > 0.5 ? colors : [];
    let suffixSeed = Math.random();
    let suffix;

    if (suffixSeed > 0.66) {
      suffix = animals;
    } else if (suffixSeed > 0.05 || prefix.length === 0) {
      suffix = names;
    } else {
      // TODO put this in state
      const fighters = getData("fighters");
      const fighterSelection = fighters
        .map((fighter) => {
          let mangledName = fighter.name;
          if (mangledName.split(" ").length > 1) {
            mangledName = mangledName
              .split(" ")
              .map((word) => word[0])
              .join("");
          }

          return {
            name: mangledName,
            sort: Math.random(),
            id: fighter.id,
          };
        })
        .sort((a, b) => a.sort - b.sort);

      suffix = [fighterSelection[0].name];
      this.main = fighterSelection[0].id;
      this.mainPreference = MAIN_PREFERENCE_TYPES.FIXED;
    }

    let baseName = uniqueNamesGenerator({
      dictionaries: prefix.length > 0 ? [prefix, suffix] : [suffix],
      separator: "",
      length: prefix.length > 0 ? 2 : 1,
      style: "capital",
    });
    let name = "";

    for (let letterIndex = 0; letterIndex < baseName.length; letterIndex++) {
      const letter = baseName.charAt(letterIndex);

      const transformChance = Math.floor(Math.random() * 100);
      let transformedLetter = letter;

      if (transformChance > 95) {
        transformedLetter = letter.toUpperCase();
      } else if (transformChance > 90) {
        switch (letter) {
          case "a":
            transformedLetter = "4";
            break;
          case "e":
            transformedLetter = "3";
            break;
          case "i":
            transformedLetter = "1";
            break;
          case "o":
            transformedLetter = "0";
            break;
        }
      }

      name += transformedLetter;
    }

    this.name = name;
  }

  setMain(main) {
    this.main = main;
  }
}
