import Region from "../classes/Region";

const Worldwide = new Region(1000, "Worldwide", null, null, "SXLA Worldwide");
const WiFi = new Region(10000, "WiFi", null, null, "Friendlies");

const NorthAmerica = new Region(1, "North America", null);

const Canada = new Region(100, "Canada", NorthAmerica, "ca", "Canada National");
const USA = new Region(101, "USA", NorthAmerica, "us", "US National");

const LatinAmerica = new Region(2, "Latin America", null);

const Mexico = new Region(200, "Mexico", LatinAmerica, "mx", "Mexico National");
const Argentina = new Region(
    201,
    "Argentina",
    LatinAmerica,
    "ar",
    "Argentina National"
);

const Europe = new Region(3, "Europe", null);

const UKIreland = new Region(300, "UK", Europe, "gb", "UK National");
const EuropeanUnion = new Region(301, "EU", Europe, "eu", "EU National");

const Asia = new Region(5, "Asia", null);

const Japan = new Region(500, "Japan", Asia, "jp", "Japan National");
const AustraliaNZ = new Region(
    501,
    "Australia",
    Asia,
    "au",
    "Australia National"
);

export const ParentRegions = {
    NorthAmerica,
    Europe,
    LatinAmerica,
    Asia,
};

export const RegionData = {
    Worldwide,
    Canada,
    USA,
    Mexico,
    Argentina,
    UKIreland,
    EuropeanUnion,
    Japan,
    AustraliaNZ,
    WiFi,
};
