import { getData, setData } from "../util";
import { PERSONALITIES } from "../classes/Player";
import NewsItem from "../classes/NewsItem";

export const FOLLOW_LEVELS = {
    UNFOLLOW: "Unfollow",
    TOGGLE_TWEETS_UNFOLLOW: "Toggle Tweets Unfollow",
    TWEETS_ONLY: "Tweets",
    TOP_8_MATCHES: "Top 8",
    EVERYTHING: "Everything",
};

export const TWEET_SITUATIONS = {
    MAIN_BUFFED: "MAIN_BUFFED",
    MAIN_NERFED: "MAIN_NERFED",
    DLC_REACTION: "DLC_REACTION",
    TIER_WHORE_CHARACTER_CHANGE: "TIER_WHORE_CHARACTER_CHANGE",
    BUFF_CHASER_CHARACTER_CHANGE: "BUFF_CHASER_CHARACTER_CHANGE",
    FINAL_LOSS: "FINAL_LOSS",
    TOP_8_LOSS: "TOP_8_LOSS",
    OUTSIDE_TOP_8: "OUTSIDE_TOP_8",
    REGIONAL_WINNER: "REGIONAL_WINNER",
    WORLDWIDE_WINNER: "WORLDWIDE_WINNER",
    RANDOM_STUFF: "RANDOM_STUFF",
};

export const tweets = {
    [TWEET_SITUATIONS.MAIN_BUFFED]: {
        [PERSONALITIES.CHILL]: [
            "Nice, {{main}} got buffed. About time.",
            "can't wait to try out {{main}} buffs",
            "If anyone needed buffs, it was {{main}}. Happy with this",
            "{{main}} BUFFS LETS GOOOM NEW COMBO TREES AND KILL CONFIRMS",
            "Glad my main got buffed, even if it is useless since {{main}} already combos well",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "I don't think {{main}} necessarily needed the buffs but I'm hardly going to complain.",
            "Expect to see {{main}} dominate the meta now.",
            "I got lucky - {{main}} got buffed! Grands here I come!",
        ],
        [PERSONALITIES.CHILDISH]: [
            "looool they buffed {{main}} the fools i'm going to dominate now",
            "about fkin time they buffed {{main}} gives me a reason to keep playing them",
            "everyone bitching about {{main}} buffs - stfu",
            "Wow, can't believe Nintendo made Melee HD and BUFFED {{main}}!",
        ],
    },
    [TWEET_SITUATIONS.MAIN_NERFED]: {
        [PERSONALITIES.CHILL]: [
            "Eurgh, I don't know if I want to play {{main}} anymore after these nerfs...",
            "I guess {{main}} nerfs were warranted, but I'm gutted.",
            "Of course they nerf {{main}} just as I want to start playing them more.",
            "No Johns. They can nerf whoever they want, player skill matters more.",
            "Sakurai why he's already bottom 3 you don't have to do this",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "I don't think {{main}} necessarily warranted the nerfs, shame as they need more support.",
            "Expect to see {{main}} drop off from the meta now.",
            "Might need to stop playing {{main}} if I'm going to be competitive from now on.",
        ],
        [PERSONALITIES.CHILDISH]: [
            "typical sakurai bias bullshit nerfin {{main}}",
            "ytf would you nerf {{main}} they suck already",
            "dropping {{main}} lol",
            "That's ok my back hurt anyway from carrying {{main}}'s position on the tier list",
        ],
    },
    [TWEET_SITUATIONS.TOP_8_LOSS]: {
        [PERSONALITIES.CHILL]: [
            "Better than going 0-2 lol",
            "Loading Johns, please wait.",
            "Screw it, top 8 with {{main}} is an achievement in itself!",
            "pot bonus is tiny for top 8 of {{tournament}}",
            "{{taggedMain}}....... BROKEN",
            "brah why is {{main}} so fun?",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "I think I'll be looking for a new main next season at this rate. {{main}} just has too many bad matchups.",
            "Not too bad really. I'll be back next time to train and compete again.",
            "Congratulations to our {{tournament}} champion, {{taggedPlayer}}!",
            "That grand finals was ridiculous holy shit. Congrats {{taggedPlayer}} you played out of your mind. Wow",
            "{{taggedPlayer}} ur schnasty at what you do, haters aint worth shit",
        ],
        [PERSONALITIES.CHILDISH]: [
            "LOL everyone know's i'm actually the best",
            "who the fuck even is {{taggedPlayer}}",
            "oh wow you won a tournament with {{taggedMain}} you must be so great",
            "no jhons bt ive ben drunking",
            "{{main}} sucks",
        ],
    },
    [TWEET_SITUATIONS.OUTSIDE_TOP_8]: {
        [PERSONALITIES.CHILL]: [
            "lmao shit {{clip}}",
            "62,528 hours in and i still suck lol",
            "the skill ceiling in this game is immense, even the low seeds are so good",
            "guess that's what I get for goimg {{main}}",
            "I'm just a n00b lol see you next week",
            "who threw a crab at me?",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "I hope {{sponsor}} don't drop me after that performance.",
            "Need to make sure this won't affect my seeding!",
            "Bad day at the office but I know I can bounce back.",
            "Bummed out because you went 0-2 in a bracket? Look around. 25% of all the people here also went 0-2. The question is: Are you gonna hang up the controller? Or are you gonna enter again next week?",
        ],
        [PERSONALITIES.CHILDISH]: [
            "fuck all y'all i'm better than the lot of you and i'll prove it next month",
            "i hate this chucklefuck game",
            "{{main}} BLOWS LMAOOOO what an unviable trash character",
            "{{main}} actually sucks",
            "do i have a child's understanding of how the world works? yes. is my brain no longer capable of generating interesting or useful thoughts? yes. but am i at least good at gaming, my singular passion and hobby? not really, fucko!",
        ],
    },
    [TWEET_SITUATIONS.FINAL_LOSS]: {
        [PERSONALITIES.CHILL]: [
            "Man, so close. Hopefully I'll win next time.",
            "Really good game with {{taggedPlayer}}, shame I couldn't win but I'll try again another time!",
            "ggs {{taggedPlayer}}, i'll get you next time",
            "back to the lab I guess",
            "Losing to {{taggedPlayer}} makes me want to pick up {{taggedMain}} lol",
            "No Johns but I definitely teched that",
            "No Johns but the sun was in my eyes",
            "No Johns but I needed to scratch my nose",
            "No Johns but {{taggedPlayer}} was just too damn good today",
            "{{taggedPlayer}}'s {{taggedMain}} looking kinda schwifty though",
            "ah ggs I got top playered",
            "best player in {{region}} is probably {{taggedPlayer}}",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "{{taggedPlayer}} deserved the win today, but next time, I'll take them down.",
            "Got to take the losses with the victories. Next time, victory.",
            "Wondering if {{main}} is the right character to take me forward.",
            "I need a new GameCube controller.",
            "Not a good finish, must do better.",
            "I didn't win today but you can win if you pick up a {{sponsoredItem}}! Like and retweet for your chance to win a new GameCube controller courtesy of {{sponsor}}",
            "Fun times today, frustrating to lose in such a way but all things considered I'm happy with how I performed. GGs {{taggedPlayer}}, I'll be back stronger next time.",
            "Close games with {{taggedPlayer}}! Better luck next time I guess",
        ],
        [PERSONALITIES.CHILDISH]: [
            "fuck you {{taggedPlayer}} you hacking cheat",
            "we all know who really won today",
            "money match {{taggedPlayer}}? then we can see who the real champ is",
            "We all know that {{taggedPlayer}} is just carried by {{taggedMain}}",
            "I think one of the reasons we love {{taggedPlayer}} so much is that we can't help but see ourselves in him. It's something about his raw vulnerability, his passion for competition, and the fact that his skull is so perfectly luminescent that we can literally see ourselves in its reflection.",
            "fucking carried",
            "You got lucky this time, {{taggedPlayer}} but I'll beat you in our next match.",
            "No Johns but my balls itched lol",
            "{{taggedMain}} busted",
            "jesus {{taggedPlayer}} take a shower you sweaty tryhard",
            "I saw {{taggedPlayer}} at the gym, holding a 45 pound dumbbell. He said, 'you can take the rat out of the gym, but you can't take the gym out of the rat, isn't that right buddy?' I laughed as a courtesy. 'Hey would you mind spotting me?' he asked. 'Im doing weighted pop offs today'",
            "somebody check the setup, underdog boost must have been on for that schlub {{taggedPlayer}} to beat me",
            "{{region}} was already fucked and now {{taggedPlayer}} wins a tournament, i might just move to Antarctica",
            "{{main}} suuuccckkksss",
        ],
    },
    [TWEET_SITUATIONS.REGIONAL_WINNER]: {
        [PERSONALITIES.CHILL]: [
            "THANK YOU EVERYONE FOR CHEERING ME ON! {{clip}}",
            "Happy to have won, gg {{taggedPlayer}}",
            "{{main}} is so much fun to win with",
            "Nice! Onto the next battle",
            "No Johns {{taggedPlayer}}",
            "Got the win but got to respect that {{taggedPlayer}}'s {{taggedMain}} looking kinda schwifty though",
            "Best motherfucker in {{region}} bitches!",
            "optimal performance",
            "Damn I'm kind of good at this video game huh?",
            "I just got told 'ah ggs I got top playerd' and I don’t know what that is even supposed to mean",
            "This was fun. I went {{main}}",
            "friendly reminder: life is meant to be lived. unless it's your life, in which case it was meant to give me a free pass to winners side pools.",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "{{taggedPlayer}} will get their chance.",
            "Incredibly happy after that win.",
            "Let's gooooo!",
            "Came first at {{tournament}} tonight!",
            "I won today and you can win too if you pick up a {{sponsoredItem}}! Like and retweet for your chance to win a new GameCube controller courtesy of {{sponsor}}",
            "How did I win? glad you asked. I can be sweaty without actually sweating thanks to my {{sponsoredItem}}. Get involved today, courtesy of {{sponsor}}!",
            "Yeah, {{main}} is busted lol",
            "I feel as confident as ever in myself and {{main}}. Hopefully I can keep moving up",
            "🦀",
        ],
        [PERSONALITIES.CHILDISH]: [
            "I am the greatest",
            "hey {{taggedPlayer}} -- all you fucking do is underperform, rage and make pathetic ass attempts at 'trolling' and THEN you're somehow upset and cry all day that no one likes you.",
            "fuck the haters",
            "so sick of ya'll acting like the {{tournament}} is just some tournament i made up to get people to respect me",
            "{{taggedPlayer}}'s {{taggedMain}} looking kinda shitty though",
            "gonna teabag {{taggedPlayer}}'s mom, girlfriend and {{taggedMain}} plushie tonight",
            "{{taggedMain}}'s back must kill from carrying {{taggedPlayer}}'s fat ass",
            "nice {{taggedMain}}, {{taggedPlayer}} ROFL",
            "i woke up from a coma at the hospital. i asked the doctors where {{taggedPlayer}} was and they said, 'buddy. {{taggedPlayer}} died 14 years ago.' i began to cry. they said, 'oh i'm sorry we got that wrong. {{taggedPlayer}} just looks like he died 14 years ago'",
            "friendly reminder: life is meant to be lived. unless it's your life, in which case it was meant to give me a free pass to winners side pools.",
        ],
    },
    [TWEET_SITUATIONS.WORLDWIDE_WINNER]: {
        [PERSONALITIES.CHILL]: [
            "I'd like to thank my mother, Sakurai, my cat...",
            "I am {{player}}, champion of the world.",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "Moments like this are why I became a professional player. Couldn't be happier!",
            "I have truly peaked as a player, and I may consider retirement.",
        ],
        [PERSONALITIES.CHILDISH]: [
            "Who's a childish toxic shit now, bitches?!",
            "Congratulations, me. You're so great, me.",
            "Guess what, {{taggedPlayer}}. You're second best. Number two. Deuce.",
            "I'm the fucking king fuck all you haters",
        ],
    },
    [TWEET_SITUATIONS.DLC_REACTION]: {
        [PERSONALITIES.CHILL]: [
            "So hype for {{taggedFighter}}!",
            "Yeeeesssss, I've been asking for {{taggedFighter}} for years, so glad they're finally in.",
            "Eh.... I know some people have been asking for {{taggedFighter}}, so I'm glad for them at least.",
            "{{taggedFighter}}? Really?",
            "Who?",
            "{{taggedFighter}} is a dope addition!",
            "looks low tier af LMAO",
            "when does the {{taggedFighter}} patch go live?",
            "{{taggedFighter}} is the best DLC fighter for a looong time.",
            "Oh wow, really? How did they manage to get {{taggedFighter}} in the game, that's incredible!",
            "LETS GOOOOO, LETS GOOOOO",
        ],
        [PERSONALITIES.PROFESSIONAL]: [
            "I wonder what {{taggedFighter}}'s neutral will be like.",
            "I'll be streaming {{taggedFighter}} later if anyone wants to join so we can figure them out together!",
            "{{taggedFighter}} looks kind of OP, just saying...",
            "Anyone got the {{taggedFighter}} frame data?",
            "{{taggedFighter}} looks hype",
            "{{taggedFighter}} will be best character in the game, calling it now.",
        ],
        [PERSONALITIES.CHILDISH]: [
            "who the fuck asked for {{taggedFighter}}, lame",
            "fuckin told you it'd be {{taggedFighter}} shoulda listened",
            "{{taggedFighter}} is gay and anyone who likes {{taggedFighter}} is also gay",
            "Oh great another fucking broken pay-to-win character, great",
            "Who gives a shit about {{taggedFighter}}",
            "waste of a slot don't @ me",
            "WHAT THE FUCK No way {{taggedFighter}} whaaaat",
            "PM died for this",
        ],
    },
};

export const generateTweet = (
    player,
    scenario,
    taggedPlayer = null,
    taggedFighter = null,
    tournament = null,
    region = null,
    type = "tweet"
) => {
    const humanPlayer = getData("humanPlayer");
    if (humanPlayer && humanPlayer.id === player.id) return;

    const fighters = getData("fighters");
    const regions = getData("regions");
    const sponsors = getData("sponsors");

    const sponsorKey = player.sponsor
        ? Object.keys(sponsors).find((key) => {
              const sponsor = sponsors[key];
              return sponsor.id === player.sponsor;
          })
        : null;

    const sponsor = sponsors[sponsorKey];

    const main = fighters.find((f) => f.id === player.main);
    let taggedPlayerMain = null;
    if (taggedPlayer)
        taggedPlayerMain = fighters.find((f) => f.id === taggedPlayer.main);

    let personality = player.personality;
    if (typeof personality === "undefined") {
        const personalityIndex = Math.floor(
            Math.random() * Object.keys(PERSONALITIES).length
        );
        personality =
            PERSONALITIES[Object.keys(PERSONALITIES)[personalityIndex]];
    }

    let tweetOptions = tweets[scenario][personality];
    if (!sponsor) {
        tweetOptions = tweetOptions.filter(
            (template) =>
                template.indexOf("{{sponsor}}") === -1 &&
                template.indexOf("{{sponsoredItem}}") === -1
        );
    }
    let tweetContent =
        tweetOptions[Math.floor(Math.random() * tweetOptions.length)];
    tweetContent = tweetContent.replace(/{{player}}/g, player.name);
    tweetContent = tweetContent.replace(/{{main}}/g, main.name);
    if (taggedPlayer) {
        tweetContent = tweetContent.replace(
            /{{taggedPlayer}}/g,
            `<a class="internal-link" href="/players/${taggedPlayer.id}">@${taggedPlayer.name}</a>`
        );
        tweetContent = tweetContent.replace(
            /{{taggedMain}}/g,
            taggedPlayerMain.name
        );
    }
    if (sponsor) {
        tweetContent = tweetContent.replace(/{{sponsor}}/g, sponsor.name);
        tweetContent = tweetContent.replace(
            /{{sponsoredItem}}/g,
            sponsor.product
        );
    }
    if (tournament) {
        tweetContent = tweetContent.replace(/{{tournament}}/g, tournament);
    }
    if (region) {
        tweetContent = tweetContent.replace(/{{region}}/g, region.name);
    }
    if (taggedFighter) {
        tweetContent = tweetContent.replace(
            /{{taggedFighter}}/g,
            taggedFighter.name
        );
    }
    tweetContent = tweetContent.replace(/{{clip}}/g, ""); // TODO Clips
    tweetContent = `<img src="${player.avatar}" /> <p>${tweetContent}</p>`;

    const time = getData("time");
    const timeString = `S${time.season} M${time.month} W${time.week}`;

    let playerDisplay = `@${player.name}`;
    if (sponsor) {
        playerDisplay = `<img class="sponsor-emblem" src="${sponsor.emblem}" /><span>${sponsor.name} |</span>&nbsp;${playerDisplay}`;
    }

    const tweet = new NewsItem(
        playerDisplay,
        tweetContent,
        timeString,
        type,
        `/players/${player.id}`
    );

    const news = getData("news");
    news.unshift(tweet);
    setData("news", news);
};

export const generateReactionTweet = (
    player,
    scenario,
    taggedPlayer = null,
    taggedFighter = null,
    tournament = null,
    region = null
) => {
    generateTweet(
        player,
        scenario,
        taggedPlayer,
        taggedFighter,
        tournament,
        region,
        "reaction_tweet"
    );
};
