import React, { Component } from "react";
import { Link } from "react-router-dom";
import Switcher from "../../components/switcher/Switcher";
import VideoThumbnail from "../../components/video-thumbnail/VideoThumbnail";
import { getData } from "../../util";

import "./PlayTubeHistory.scss";

class PlayTubeHistory extends Component {
  render() {
    const watchHistory = getData("playTubeWatchHistory");

    return (
      <div className="playtube-container">
        <Switcher>
          <Link to="/playtube">
            <span>Uploads</span>
          </Link>
          <span className="active">Watch history</span>
        </Switcher>

        {watchHistory.slice(0, 25).map((video) => (
          <VideoThumbnail
            quality={video.quality}
            streamer={video.streamer}
            title={video.title}
            fighter={video.fighter}
            thumbnail={video.thumbnail}
          />
        ))}
      </div>
    );
  }
}

export default PlayTubeHistory;
