export const settingData = {
  database: {
    label: "Database",
    MM: {
      name: "Melody Melee",
      description:
        "Enter a world of vikings, demons and tentacled beasts, where music is your weapon.",
    },
    "20XX": {
      name: "Super Smash Bros. 20XX",
      description: "The 20XX Experience",
    },
    SSBU: {
      name: "Super Smash Bros. Ultimate",
      description:
        "The classic SSBU database. Contains more characters, but many will not have animations or abilities ready.",
    },
  },

  matchEngine: {
    label: "Match engine",
    v3r: {
      name: "V3R",
      description:
        "Damage rework across all phases. Hone all archetype bonuses. Character abilities. Punish phase rewritten.",
    },
    v3: {
      name: "V3",
      description:
        "Damage rework across all phases. Hone all archetype bonuses. Character abilities. Punish phase rewritten.",
    },
    v2r: {
      name: "V2R",
      description:
        "The V2 engine but with modifications for live rendering. Character archetypes. Neutral still most important stat. Zoners weak.",
    },
    v2: {
      name: "V2",
      description:
        "Character archetypes. Neutral still most important stat. Zoners weak.",
    },
    v1: {
      name: "V1",
      description: "Base 5 stats. Neutral strongly favoured.",
    },
    v4: {
      disabled: true,
      name: "V4",
      description:
        "Further neutral breakdown by player playstyles and abilities.",
    },
  },

  statMode: {
    label: "Stat mode",
    immersive: {
      name: "Immersive/Default",
      description:
        "Stats are obfuscated and fighter information will only be updated once per season.",
    },
    immersiveRandom: {
      name: "Immersive/Randomised",
      description:
        "Stats are obfuscated and fighter information will only be updated once per season. Character stats are randomised.",
    },
    open: {
      name: "Open/Default",
      description: "Stats are visible.",
    },
    openRandom: {
      name: "Open/Randomised",
      description: "Stats are visible. Character stats are randomised.",
    },
  },

  balancePatchPolicy: {
    label: "Balance patch policy",
    standard: {
      name: "Standard",
      description: "High performers are nerfed, low performers are buffed.",
    },
    chaotic: {
      name: "Chaotic",
      description: "Random buffs and nerfs with little to no reasoning.",
    },
  },

  dlcPolicy: {
    label: "DLC policy",
    real: {
      name: "Standard",
      description: "Follows the standard order of release.",
    },
    nodlc: {
      name: "All Available",
      description: "All DLC is available from game start.",
    },
    random: {
      name: "Random Order",
      description: "DLC is selected from default pool in random order.",
    },
    totalRandom: {
      name: "Totally Random",
      description: "Any character can be DLC instead of base game.",
    },
  },
};
