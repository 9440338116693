import React, { Component } from "react";

import "./PageHeader.scss";

class PageHeader extends Component {
    render() {
        return <h1 className="page-header">{this.props.children}</h1>;
    }
}

export default PageHeader;
