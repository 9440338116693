export const ABILITY_TYPES = {
    PASSIVE: "Passive",
    ACTIVE: "Active",
};

class Ability {
    constructor(name, description, type, exec, actions = []) {
        this.name = name;
        this.description = description;
        this.type = type;
        this.exec = exec;
        this.actions = actions;
    }
}

export default Ability;
