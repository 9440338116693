import { Match } from "./Match";
import { MatchV2 } from "./MatchV2";
import { getData, setData } from "../util";
import NewsItem from "./NewsItem";
import { TournamentRound } from "./TournamentRound";
import { charRenders } from "../data/assets";
import {
  FOLLOW_LEVELS,
  generateReactionTweet,
  generateTweet,
  TWEET_SITUATIONS,
} from "../data/Tweets";
import { MatchV2R } from "./MatchV2R";
import { MatchV3 } from "./MatchV3";
import { MatchV3R } from "./MatchV3R";
import { SPONSOR_VALUES, SPONSOR_HYPE_TOLERANCE } from "./Sponsor";
import { dropPlayerFromSponsor } from "../data/Sponsors";
import { boostCharacterFamiliarity } from "./Player";

export class Tournament {
  matchEngines = {
    v2: {
      quick: MatchV2,
    },
    v1: {
      quick: Match,
    },
    v2r: {
      quick: MatchV2,
      rendered: MatchV2R,
    },
    v3: {
      quick: MatchV3,
    },
    v3r: {
      quick: MatchV3,
      rendered: MatchV3R,
    },
  };

  constructor(
    players,
    region,
    baseSeedIncrement = 1,
    prizePot = 100,
    callback = () => {},
    game = null
  ) {
    this.game = game;
    this.settings = getData("settings");
    this.region = region;
    this.prizePot = prizePot;
    let roundStubs = [];

    let playerCount = players.length;
    while (playerCount > 1) {
      playerCount = playerCount / 2;
      roundStubs.push(playerCount);
    }

    this.callback = callback;
    this.complete = false;
    this.currentRound = 0;
    this.baseSeedIncrement = baseSeedIncrement;

    this.sortPlayersBySeeding(players);

    this.rounds = roundStubs.map((roundEntrants, index) => {
      const roundMatches = [];
      for (let i = 0; i < roundEntrants * 2; i += 2) {
        let roundMatch;
        let name = `Round ${index + 1}`;
        switch (roundEntrants) {
          case 1:
            name = "Final";
            break;
          case 2:
            name = "Semi-Final";
            break;
          case 4:
            name = "Quarter-Final";
        }

        if (index === 0) {
          // first round
          roundMatch = new TournamentRound(
            `round_${index}_${i}`,
            this.players[i / 2].id,
            this.players[roundEntrants + i / 2].id,
            name
          );
        } else {
          roundMatch = new TournamentRound(
            `round_${index}_${i}`,
            `winner_round_${index}_${i}`,
            `winner_round_${index}_${i + 1}`,
            name
          );
        }
        roundMatches.push(roundMatch);
      }
      return roundMatches;
    });
  }

  sortPlayersBySeeding(players) {
    const allPlayers = getData("players");
    let sortedPlayers = [];
    sortedPlayers = players.map((p) =>
      allPlayers.find((player) => player.id === p)
    );
    sortedPlayers.sort((a, b) => {
      if (a.seed === b.seed) return Math.random() - Math.random();
      return a.seed < b.seed ? 1 : -1;
    });

    sortedPlayers.forEach((p, index) => (p.seedIndex = index + 1));

    const ITERATIONS = players.length / 16;
    let brackets = [];
    for (let i = 0; i < ITERATIONS; i++) {
      let bracket = [];

      // first and last once
      bracket.push(...sortedPlayers.splice(0, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length - 1, 1));

      // divide by 2, twice
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 2, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 2, 1));

      // 1/4 and 3/4, twice
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 4, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 4) * 3, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 4, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 4) * 3, 1));

      // 1/8, 3/8, 5/8, 7/8, twice
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 8, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 7, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 3, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 5, 1));
      bracket.push(...sortedPlayers.splice(sortedPlayers.length / 8, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 7, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 3, 1));
      bracket.push(...sortedPlayers.splice((sortedPlayers.length / 8) * 5, 1));

      brackets.push(bracket);
    }
    this.players = [];
    brackets.forEach((b) => this.players.push(...b));
  }

  endTournament() {
    this.complete = true;
    if (this.game.matchWatching && this.game.matchWatching.complete)
      this.game.matchWatching = null;

    const time = getData("time");
    const regions = getData("regions");
    const players = getData("players");
    const humanPlayer = getData("humanPlayer");
    const fighters = getData("fighters");
    const region = regions.find((region) => region.id === this.region);

    const timeString = `S${time ? time.season : ""} M${
      time ? time.month : ""
    } W${time ? time.week : ""}`;

    const rounds = this.rounds.sort((a, b) => (a.length < b.length ? -1 : 1));
    const final = rounds[0][0];
    const winner =
      final.scoreA > final.scoreB
        ? players.find((p) => p.id === final.competitorA)
        : players.find((p) => p.id === final.competitorB);
    const runnerUp =
      final.scoreA > final.scoreB
        ? players.find((p) => p.id === final.competitorB)
        : players.find((p) => p.id === final.competitorA);
    const semiFinalists = [];
    rounds[1].forEach((semiFinal) => {
      if (semiFinal.scoreA > semiFinal.scoreB) {
        semiFinalists.push(players.find((p) => p.id === semiFinal.competitorB));
      } else {
        semiFinalists.push(players.find((p) => p.id === semiFinal.competitorA));
      }
    });
    const quarterFinalists = [];
    rounds[2].forEach((quarterFinal) => {
      if (quarterFinal.scoreA > quarterFinal.scoreB) {
        quarterFinalists.push(
          players.find((p) => p.id === quarterFinal.competitorB)
        );
      } else {
        quarterFinalists.push(
          players.find((p) => p.id === quarterFinal.competitorA)
        );
      }
    });

    let prize = this.prizePot / 2;
    winner.finance += prize;
    prize = prize / 2;
    runnerUp.finance += prize;
    prize = prize / 4;
    semiFinalists.forEach((sf) => (sf.finance += prize));
    prize = prize / 2;
    quarterFinalists.forEach((qf) => (qf.finance += prize));

    const reportBody = `
            <div class="result-container">
            <ol>
                <a class="internal-link" href="/players/${
                  winner.id
                }"><li><span><img src="${winner.avatar}"/>${
      winner.name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === winner.main).name]
    }" /></li></a>
            </ol>
            <ol>
            <a class="internal-link" href="/players/${
              runnerUp.id
            }"><li value="2"><span><img src="${runnerUp.avatar}"/>${
      runnerUp.name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === runnerUp.main).name]
    }" /></li></a>
            <a class="internal-link" href="/players/${
              semiFinalists[0].id
            }"><li value="3"><span>${
      semiFinalists[0].name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === semiFinalists[0].main).name]
    }" /></li></a>
                <a class="internal-link" href="/players/${
                  semiFinalists[1].id
                }"><li value="3"><span>${
      semiFinalists[1].name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === semiFinalists[1].main).name]
    }" /></li></a>
        </ol>
        <ol>
            <a class="internal-link" href="/players/${
              quarterFinalists[0].id
            }"><li value="5"><span>${
      quarterFinalists[0].name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === quarterFinalists[0].main).name]
    }" /></li></a>
        <a class="internal-link" href="/players/${
          quarterFinalists[1].id
        }"><li value="5"><span>${
      quarterFinalists[1].name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === quarterFinalists[1].main).name]
    }" /></li></a>
        <a class="internal-link" href="/players/${
          quarterFinalists[2].id
        }"><li value="5"><span>${
      quarterFinalists[2].name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === quarterFinalists[2].main).name]
    }" /></li></a>
        <a class="internal-link" href="/players/${
          quarterFinalists[3].id
        }"><li value="5"><span>${
      quarterFinalists[3].name
    }</span> <img class="char-icon" src="${
      charRenders[fighters.find((f) => f.id === quarterFinalists[3].main).name]
    }" /></li></a>
            </ol>
            </div>
        `;

    const report = new NewsItem(
      `Top 8 results for ${region.tournamentName}`,
      reportBody,
      timeString,
      "results"
    );

    const followedPlayers = getData("followedPlayers");
    followedPlayers.forEach((followedPlayer) => {
      const player = players.find((p) => p.id === followedPlayer.id);

      if (player.id === winner.id)
        if (region.id === 1000) {
          generateReactionTweet(
            player,
            TWEET_SITUATIONS.WORLDWIDE_WINNER,
            runnerUp
          );
        } else {
          generateReactionTweet(
            player,
            TWEET_SITUATIONS.REGIONAL_WINNER,
            runnerUp,
            null,
            region.tournamentName,
            region
          );
        }
      else if (player.id === runnerUp.id)
        generateReactionTweet(
          player,
          TWEET_SITUATIONS.FINAL_LOSS,
          winner,
          null,
          region.tournamentName,
          region
        );
      else if (
        semiFinalists.find((sf) => sf.id === player.id) ||
        quarterFinalists.find((qf) => qf.id === player.id)
      )
        generateReactionTweet(
          player,
          TWEET_SITUATIONS.TOP_8_LOSS,
          winner,
          null,
          region.tournamentName,
          region
        );
      else if (this.players.find((p) => p.id === player.id))
        generateReactionTweet(
          player,
          TWEET_SITUATIONS.OUTSIDE_TOP_8,
          winner,
          null,
          region.tournamentName,
          region
        );
    });

    const news = getData("news");
    const sponsors = getData("sponsors");
    news.unshift(report);

    this.players.forEach((player) => {
      const playerRecord = players.find((p) => p.id === player.id);

      if (this.region === 1000 && player.id === winner.id) {
        sponsors.SXLA.players = [player.id];

        const existingSponsorKey = Object.keys(sponsors).find((key) => {
          const sponsor = sponsors[key];
          return sponsor.id === player.sponsor;
        });

        if (existingSponsorKey && existingSponsorKey !== "SXLA") {
          sponsors[existingSponsorKey].players.splice(
            sponsors[existingSponsorKey].players.indexOf(player.id),
            1
          );
        }

        player.sponsor = sponsors.SXLA.id;

        const sxlaSponsorshipReport = new NewsItem(
          `${player.name} gets SXLA sponsorship`,
          `<p>As winners of the SXLA Worldwide Tournament, ${
            player.name
          } has gained official SXLA Sponsorship! ${
            existingSponsorKey
              ? `They are no longer sponsored by ${sponsors[existingSponsorKey].name}.`
              : ""
          }</p>`,
          timeString,
          "sponsorship",
          `/players/${player.id}`
        );

        playerRecord.strikes = 0;
        news.unshift(sxlaSponsorshipReport);
      }

      playerRecord.seed = player.seed;
      playerRecord.history = player.history;
      playerRecord.characterFamiliarity = player.characterFamiliarity;
      playerRecord.sponsor = player.sponsor;

      if (player.sponsor !== null) {
        const sponsorKey = Object.keys(sponsors).find((key) => {
          const sponsor = sponsors[key];
          return sponsor.id === player.sponsor;
        });

        let reason = "";
        const sponsor = sponsors[sponsorKey];
        if (sponsor.values === SPONSOR_VALUES.RESULTS) {
          reason = "a poor run of competitive results";
          if (
            winner.id !== player.id &&
            runnerUp.id !== player.id &&
            !semiFinalists.find((p) => p.id === player.id) &&
            !semiFinalists.find((p) => p.id === player.id)
          ) {
            playerRecord.strikes++;
          }
        } else if (sponsor.values === SPONSOR_VALUES.HYPE) {
          reason = "some shockingly lame gameplay";
          const moreHypeThanPlayer = players.filter(
            (p) => p.hype > player.hype
          );
          if (
            player.hype <= playerRecord.hype - SPONSOR_HYPE_TOLERANCE ||
            moreHypeThanPlayer.length > 50
          ) {
            playerRecord.strikes++;
          }
        }

        if (playerRecord.strikes >= sponsor.strikes) {
          news.unshift(dropPlayerFromSponsor(playerRecord, sponsor, reason));
        }
      }

      playerRecord.hype = player.hype;
    });

    if (humanPlayer) {
      const humanPlayerRecord = players.find((p) => p.id === humanPlayer.id);
      humanPlayer.finance = humanPlayerRecord.finance;
    }

    setData("news", news);
    setData("players", players);
    setData("humanPlayer", humanPlayer);
    setData("sponsors", sponsors);

    this.callback();
  }

  playCurrentRound() {
    const humanPlayer = getData("humanPlayer");

    const followedPlayers = getData("followedPlayers");
    if (this.currentRound >= this.rounds.length) {
      this.endTournament();
      return;
    }

    let currentRoundMatchIndex = 0;
    const roundMatches = [];
    this.rounds.sort((a, b) => (a.length > b.length ? -1 : 1));
    this.rounds[this.currentRound].forEach((roundMatch, index) => {
      let nextRound = null;
      let proceedsTo;
      if (this.currentRound + 1 < this.rounds.length) {
        proceedsTo = `winner_round_${this.currentRound + 1}_${index}`;
        nextRound = this.rounds[this.currentRound + 1].find(
          (round) =>
            round.competitorA === proceedsTo || round.competitorB === proceedsTo
        );
      }

      const playerOne = this.players.find(
        (player) => player.id === roundMatch.competitorA
      );
      const playerTwo = this.players.find(
        (player) => player.id === roundMatch.competitorB
      );

      const matchEngine = this.matchEngines[this.settings.matchEngine];
      let thisMatchEngine = matchEngine.quick;
      if (
        matchEngine.rendered &&
        ((humanPlayer &&
          (humanPlayer.id === playerOne.id ||
            humanPlayer.id === playerTwo.id)) ||
          (roundMatch.name.indexOf("Final") > -1 &&
            (followedPlayers.find(
              (p) =>
                p.id === playerOne.id &&
                p.followLevel === FOLLOW_LEVELS.TOP_8_MATCHES
            ) ||
              followedPlayers.find(
                (p) =>
                  p.id === playerTwo.id &&
                  p.followLevel === FOLLOW_LEVELS.TOP_8_MATCHES
              ))) ||
          followedPlayers.find(
            (p) =>
              p.id === playerOne.id &&
              p.followLevel === FOLLOW_LEVELS.EVERYTHING
          ) ||
          followedPlayers.find(
            (p) =>
              p.id === playerTwo.id &&
              p.followLevel === FOLLOW_LEVELS.EVERYTHING
          ))
      ) {
        thisMatchEngine = matchEngine.rendered;
      }

      const regions = getData("regions");
      const region = regions.find((region) => region.id === this.region);

      const match = new thisMatchEngine(
        playerOne,
        playerTwo,
        (winner, winnerStocks, winnerHype, loserHype) => {
          roundMatch.scoreA =
            winner === roundMatch.competitorA ? winnerStocks : 0;
          roundMatch.scoreB =
            winner === roundMatch.competitorB ? winnerStocks : 0;
          roundMatch.timestamp = new Date().getTime();

          const loser =
            winner === roundMatch.competitorA
              ? roundMatch.competitorB
              : roundMatch.competitorA;

          const fighters = getData("fighters");
          const player = this.players.find((player) => winner === player.id);
          const losingPlayer = this.players.find(
            (player) => loser === player.id
          );

          const time = getData("time");
          const timeString = `S${time ? time.season : ""} M${
            time ? time.month : ""
          } W${time ? time.week : ""}`;

          if (roundMatch.name !== "Final") {
            const losingHistoryEntry = {
              region: this.region,
              date: timeString,
              roundReached: roundMatch.name,
            };
            losingPlayer.history[
              losingPlayer.history.length - 1
            ].tournamentResults.push(losingHistoryEntry);
          } else {
            const losingHistoryEntry = {
              region: this.region,
              date: timeString,
              roundReached: "Runner up",
            };
            const winningHistoryEntry = {
              region: this.region,
              date: timeString,
              roundReached: "Winner",
            };
            losingPlayer.history[
              losingPlayer.history.length - 1
            ].tournamentResults.push(losingHistoryEntry);
            player.history[player.history.length - 1].tournamentResults.push(
              winningHistoryEntry
            );
          }

          const fighter = fighters.find(
            (fighter) => player.main === fighter.id
          );
          let seedIncrement = this.baseSeedIncrement / 8;
          if (roundMatch.name === "Quarter-Final")
            seedIncrement = this.baseSeedIncrement / 4;
          if (roundMatch.name === "Semi-Final")
            seedIncrement = this.baseSeedIncrement / 2;
          if (roundMatch.name === "Final")
            seedIncrement = this.baseSeedIncrement;

          player.seed += winnerStocks * seedIncrement;
          player.hype += winnerHype;
          losingPlayer.hype += loserHype;

          boostCharacterFamiliarity(player, player.main, 1);
          boostCharacterFamiliarity(player, losingPlayer.main, 0.75);

          boostCharacterFamiliarity(losingPlayer, losingPlayer.main, 1);
          boostCharacterFamiliarity(losingPlayer, player.main, 0.75);

          fighter.seed += winnerStocks * seedIncrement;
          setData("fighters", fighters);

          if (nextRound) {
            if (nextRound.competitorA === proceedsTo)
              nextRound.competitorA = winner;
            if (nextRound.competitorB === proceedsTo)
              nextRound.competitorB = winner;
          }
          currentRoundMatchIndex++;

          if (
            !this.game.matchWatching ||
            !this.game.matchWatching.renderable ||
            (this.game.matchWatching.renderable &&
              this.game.matchWatching.complete)
          ) {
            this.game.matchWatching = roundMatches[currentRoundMatchIndex];
          } else if (roundMatches[currentRoundMatchIndex]) {
            roundMatches[currentRoundMatchIndex].skip();
          }

          if (currentRoundMatchIndex === roundMatches.length) {
            this.playCurrentRound();
          } else if (
            !this.game.matchWatching ||
            !this.game.matchWatching.renderable ||
            this.game.matchWatching.tournamentName !== region.tournamentName
          ) {
            roundMatches[currentRoundMatchIndex].play();
          }
        }
      );

      match.region = region;
      match.tournamentName = region.tournamentName;
      match.roundName = roundMatch.name;

      roundMatches.push(match);
    });

    if (
      !this.game.matchWatching ||
      !this.game.matchWatching.renderable ||
      (this.game.matchWatching.renderable && this.game.matchWatching.complete)
    ) {
      this.game.matchWatching = roundMatches[currentRoundMatchIndex];
    } else if (roundMatches[currentRoundMatchIndex]) {
      roundMatches[currentRoundMatchIndex].skip();
    }
    roundMatches[currentRoundMatchIndex].play();

    if (this.currentRound < this.rounds.length) this.currentRound++;
  }
}
