import { MAIN_PREFERENCE_TYPES } from "../classes/Player";
export const topPlayers = [
  {
    name: "Salmon",
    region: 100,
    main: "Zero Suit Samus",
    mainPreference: MAIN_PREFERENCE_TYPES.TIER_WHORE,
  },
  {
    name: "MarxistMeggie",
    region: 100,
    main: "Roy",
    mainPreference: MAIN_PREFERENCE_TYPES.FLUID,
  },
  {
    name: "Nari",
    region: 100,
    main: "Peach",
    mainPreference: MAIN_PREFERENCE_TYPES.BUFF_CHASER,
  },
  {
    name: "Llllllllllama!",
    region: 100,
    main: "Duck Hunt Duo",
    mainPreference: MAIN_PREFERENCE_TYPES.ZONER_FLUID,
  },
  {
    name: "Deane",
    region: 101,
    main: "Pikachu",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "Deane",
    region: 101,
    main: "Octavia",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "swisscheeseisvile",
    region: 101,
    main: "Toon Link",
    mainPreference: MAIN_PREFERENCE_TYPES.BUFF_CHASER,
  },
  {
    name: "Hufff",
    region: 101,
    main: "Pichu",
    mainPreference: MAIN_PREFERENCE_TYPES.BUFF_CHASER,
  },
  {
    name: "ToT3344",
    region: 101,
    main: "Luigi",
    mainPreference: MAIN_PREFERENCE_TYPES.BUFF_CHASER,
  },
  {
    name: "Anitra",
    region: 200,
    main: "Sheik",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "Matt1",
    region: 200,
    main: "Fox",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "Emu",
    region: 200,
    main: "Ganondorf",
    mainPreference: MAIN_PREFERENCE_TYPES.LOW_TIER_HERO,
  },
  {
    name: "Mongoose",
    region: 200,
    main: "Pyra / Mythra",
    mainPreference: MAIN_PREFERENCE_TYPES.TIER_WHORE,
  },
  {
    name: "Mercedes",
    region: 201,
    main: "Diddy Kong",
    mainPreference: MAIN_PREFERENCE_TYPES.RUSHDOWN_FLUID,
  },
  {
    name: "Yak",
    region: 201,
    main: "Chrom",
    mainPreference: MAIN_PREFERENCE_TYPES.RUSHDOWN_FLUID,
  },
  {
    name: "SuperFalcon",
    region: 201,
    main: "Captain Falcon",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "TheMemecromancer",
    region: 201,
    main: "Ken",
    mainPreference: MAIN_PREFERENCE_TYPES.BAIT_PUNISH_FLUID,
  },
  {
    name: "Kaja",
    region: 300,
    main: "Kazuya",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "Ingleborough",
    region: 300,
    main: "Mii Brawler",
    mainPreference: MAIN_PREFERENCE_TYPES.LOW_TIER_HERO,
  },
  {
    name: "DIRECTORTWENA",
    region: 300,
    main: "Kirby",
    mainPreference: MAIN_PREFERENCE_TYPES.LOW_TIER_HERO,
  },
  {
    name: "Pasty",
    region: 300,
    main: "King K. Rool",
    mainPreference: MAIN_PREFERENCE_TYPES.RUSHDOWN_FLUID,
  },
  {
    name: "Ethel",
    region: 301,
    main: "Pokemon Trainer",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "Hurrikane",
    region: 301,
    main: "Wolf",
    mainPreference: MAIN_PREFERENCE_TYPES.TIER_WHORE,
  },
  {
    name: "Geneva",
    region: 301,
    main: "Wario",
    mainPreference: MAIN_PREFERENCE_TYPES.TIER_WHORE,
  },
  {
    name: "Popstar",
    region: 301,
    main: "Young Link",
    mainPreference: MAIN_PREFERENCE_TYPES.BUFF_CHASER,
  },
  {
    name: "C3nt1p3d3",
    region: 500,
    main: "Ryu",
    mainPreference: MAIN_PREFERENCE_TYPES.FLUID,
  },
  {
    name: "Cord",
    region: 500,
    main: "Mega Man",
    mainPreference: MAIN_PREFERENCE_TYPES.ZONER_FLUID,
  },
  {
    name: "Shoto-kun",
    region: 500,
    main: "Terry",
    mainPreference: MAIN_PREFERENCE_TYPES.BAIT_PUNISH_FLUID,
  },
  {
    name: "HardPlant",
    region: 500,
    main: "Piranha Plant",
    mainPreference: MAIN_PREFERENCE_TYPES.LOW_TIER_HERO,
  },
  {
    name: "Kiwi",
    region: 501,
    main: "R.O.B.",
    mainPreference: MAIN_PREFERENCE_TYPES.FLUID,
  },
  {
    name: "FatLaura",
    region: 501,
    main: "Palutena",
    mainPreference: MAIN_PREFERENCE_TYPES.FIXED,
  },
  {
    name: "DirtyMarsupial",
    region: 501,
    main: "Donkey Kong",
    mainPreference: MAIN_PREFERENCE_TYPES.BUFF_CHASER,
  },
  {
    name: "ToxicBug",
    region: 501,
    main: "Mr. Game & Watch",
    mainPreference: MAIN_PREFERENCE_TYPES.FLUID,
  },
];
