export const MATCH_STATES = {
    VULNERABLE: "vulnerable",
    DISADVANTAGE: "disadvantage",
    NEUTRAL: "neutral",
    ADVANTAGE: "advantage",
    PUNISHING: "punishing",
    FORM_SWITCH: "switching_form",
    CHARGE: "charging",
    RELEASE_CHARGE: "release_charge",
    RELEASE_CHARGE_ADVANTAGE: "release_charge_advantage",
    RELEASE_CHARGE_PUNISHING: "release_charge_punishing",
    RECOVERING: "recovering",
    EDGEGUARDING: "edgeguarding",
    EDGEGUARDED: "edgeguarded",
    LEDGETRAPPING: "ledgetrapping",
    SELF_DESTRUCTING: "self-destructing",
    // these are visual only
    RESPAWNING: "respawning",
    RESPAWNING_FROM_BOTTOM: "respawning-from-bottom",
    DEFEATED: "defeated",
};
