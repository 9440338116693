import Fighter, { FIGHTER_TYPES } from "../classes/Fighter";

export const Fighters_MM = [
  new Fighter(
    "Bjørn Ironstrings",
    {
      neutral: 6,
      advantage: 6,
      disadvantage: 3,
      punish: 8,
      recovery: 5,
      weight: 10,
      projectiles: 6,
      mobility: 5,
      outofshield: 7,
      hype: 3,
      type: FIGHTER_TYPES.BAIT_PUNISH,
    },
    null,
    "Viking Raid"
  ),
  new Fighter(
    "Octavia",
    {
      neutral: 6,
      advantage: 7,
      disadvantage: 3,
      punish: 5,
      recovery: 6,
      weight: 9,
      projectiles: 7,
      mobility: 5,
      outofshield: 3,
      hype: 1,
      type: FIGHTER_TYPES.ZONER,
    },
    null,
    "Stay Chill, Get The Kill"
  ),
  new Fighter(
    "Demonix",
    {
      neutral: 4,
      advantage: 9,
      disadvantage: 9,
      punish: 9,
      recovery: 8,
      weight: 12,
      projectiles: 5,
      mobility: 3,
      outofshield: 3,
      hype: 4,
      type: FIGHTER_TYPES.BAIT_PUNISH,
    },
    null,
    "Drop The Bass"
  ),
];
