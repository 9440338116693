import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getData } from "../../util";

import "./Navigation.scss";

class Navigation extends Component {
  next() {
    this.props.next();
  }

  render() {
    const { readyToProceed } = this.props;

    const time = getData("time");
    const humanPlayer = getData("humanPlayer");

    return (
      <>
        <header>
          <ul>
            <li className="logo-container">
              <span>2</span>
              <img
                src={`${process.env.PUBLIC_URL}/shine.png`}
                className="logo"
              />
              <span>XX</span>
            </li>
            <li>
              <div className="time">
                S{time ? time.season : ""} M{time ? time.month : ""} W
                {time ? time.week : ""}
                {humanPlayer && (
                  <span>${(humanPlayer.finance / 10).toFixed(2)}</span>
                )}
              </div>

              <button onClick={this.next.bind(this)} disabled={!readyToProceed}>
                Next
              </button>
            </li>
          </ul>
        </header>
        <nav>
          <ul>
            <Link
              to="/news"
              className={window.location.pathname === "/news" ? "active" : ""}
            >
              <li>
                <img src={`${process.env.PUBLIC_URL}/inbox.svg`} />
                <span>Inbox</span>
              </li>
            </Link>

            <Link
              to="/regions"
              className={
                window.location.pathname.indexOf("/regions") > -1
                  ? "active"
                  : ""
              }
            >
              <li>
                <img src={`${process.env.PUBLIC_URL}/globe.svg`} />
                <span>World</span>
              </li>
            </Link>

            <Link
              to="/fighters"
              className={
                window.location.pathname.indexOf("/fighters") > -1
                  ? "active"
                  : ""
              }
            >
              <li>
                <img src={`${process.env.PUBLIC_URL}/fist.svg`} />
                <span>Fighters</span>
              </li>
            </Link>

            <Link
              to="/players"
              className={
                window.location.pathname.indexOf("/players") > -1 &&
                window.location.pathname !== "/players/1"
                  ? "active"
                  : ""
              }
            >
              <li>
                <img src={`${process.env.PUBLIC_URL}/people.svg`} />
                <span>Players</span>
              </li>
            </Link>

            {humanPlayer && (
              <Link
                to="/players/1"
                className={
                  window.location.pathname === "/players/1" ? "active" : ""
                }
              >
                <li>
                  <img src={`${process.env.PUBLIC_URL}/person.svg`} />
                  <span>Profile</span>
                </li>
              </Link>
            )}

            <Link
              to="/sponsors"
              className={
                window.location.pathname.indexOf("/sponsors") > -1
                  ? "active"
                  : ""
              }
            >
              <li>
                <img src={`${process.env.PUBLIC_URL}/coins.png`} />
                <span>Sponsors</span>
              </li>
            </Link>

            {humanPlayer && (
              <Link
                to="/playtube"
                className={
                  window.location.pathname.indexOf("/playtube") > -1
                    ? "active"
                    : ""
                }
              >
                <li>
                  <img src={`${process.env.PUBLIC_URL}/tv.png`} />
                  <span>PlayTube</span>
                </li>
              </Link>
            )}

            <Link
              to="/settings"
              className={
                window.location.pathname.indexOf("/settings") > -1
                  ? "active"
                  : ""
              }
            >
              <li>
                <img src={`${process.env.PUBLIC_URL}/settings.svg`} />
                <span>Settings</span>
              </li>
            </Link>
          </ul>
        </nav>
      </>
    );
  }
}

export default Navigation;
