import { getData } from "../util";
import { FIGHTER_TYPES } from "./Fighter";
import { MATCH_STATES } from "../data/MatchStates";
import { commentate } from "../data/Commentary";
import { Abilities } from "../data/Abilities";
import { STRATEGIES, STRATEGY_EFFECTIVENESS } from "../data/Strategies";

const NEUTRAL_MODIFIER = 10;
const ADVANTAGE_MODIFIER = 10;
const PUNISH_MODIFIER = 10;
const DEFAULT_INTERVAL = 1500;
const INTERVAL = DEFAULT_INTERVAL;

export class MatchV3R {
  constructor(
    playerOne,
    playerTwo,
    onFinish,
    fighterOne = null,
    fighterTwo = null
  ) {
    this.initialise(playerOne, playerTwo, onFinish, fighterOne, fighterTwo);

    this.fighterFlexible = false;
    this.showCSS = false;
  }

  shuffleFighters() {
    const fighters = getData("fighters");
    const playerOnePlaysMain = Math.random() < 0.7;
    const playerTwoPlaysMain = Math.random() < 0.7;
    const playerOneAlternativeFighter =
      fighters[Math.floor(Math.random() * fighters.length)];
    const playerTwoAlternativeFighter =
      fighters[Math.floor(Math.random() * fighters.length)];

    const fighterOne = playerOnePlaysMain
      ? fighters.find((f) => f.id === this.playerOne.main)
      : playerOneAlternativeFighter;
    const fighterTwo = playerTwoPlaysMain
      ? fighters.find((f) => f.id === this.playerTwo.main)
      : playerTwoAlternativeFighter;

    this.changeFighters(fighterOne, fighterTwo);
  }

  changeFighters(fighterOne, fighterTwo) {
    this.initialise(
      this.playerOne,
      this.playerTwo,
      this.onFinish,
      fighterOne,
      fighterTwo
    );
  }

  initialise(playerOne, playerTwo, onFinish, fighterOne, fighterTwo) {
    const fighters = getData("fighters");

    this.renderable = true;
    this.complete = false;
    this.showGameStart = true;
    this.showGameEnd = false;

    this.interval = INTERVAL;
    this.playerOneSkipNeutralInterval = false;
    this.playerTwoSkipNeutralInterval = false;

    this.playerOne = playerOne;
    this.playerTwo = playerTwo;

    this.fighterOne = fighterOne;
    this.fighterTwo = fighterTwo;

    if (!this.fighterOne) {
      this.fighterOne = fighters.find(
        (fighter) => playerOne.main === fighter.id
      );
    }

    if (!this.fighterTwo) {
      this.fighterTwo = fighters.find(
        (fighter) => playerTwo.main === fighter.id
      );
    }

    this.playerOneStocks = 3;
    this.playerTwoStocks = 3;

    this.playerOneDamage = 0;
    this.playerTwoDamage = 0;

    this.playerOneState = MATCH_STATES.NEUTRAL;
    this.playerTwoState = MATCH_STATES.NEUTRAL;

    this.fighterOneForms = this.fighterOne.forms;
    this.fighterTwoForms = this.fighterTwo.forms;

    this.onFinish = onFinish;

    this.commentary = [];
    this.skipping = false;

    this.playerOneEdgeguardingStrategy = null;
    this.playerTwoEdgeguardingStrategy = null;

    this.playerOneEdgeguardingOptions = [
      STRATEGIES.EDGEGUARDING_OPTIONS.NONE,
      STRATEGIES.EDGEGUARDING_OPTIONS.LOW_RISK,
      STRATEGIES.EDGEGUARDING_OPTIONS.HIGH_RISK,
    ];

    this.playerTwoEdgeguardingOptions = [
      STRATEGIES.EDGEGUARDING_OPTIONS.NONE,
      STRATEGIES.EDGEGUARDING_OPTIONS.LOW_RISK,
      STRATEGIES.EDGEGUARDING_OPTIONS.HIGH_RISK,
    ];

    this.fighterOneAbility = this.fighterOne.ability
      ? Abilities.find((a) => a.name === this.fighterOne.ability)
      : null;
    this.fighterTwoAbility = this.fighterTwo.ability
      ? Abilities.find((a) => a.name === this.fighterTwo.ability)
      : null;

    this.playerOneUsesAbility = null;
    this.playerTwoUsesAbility = null;

    this.initialiseAbility();

    this.playerOneBonus = false;
    this.playerTwoBonus = false;

    let playerOneFamiliarityBonus =
      this.playerOne.characterFamiliarity[this.fighterOne.id] || 0;
    if (this.playerOne.characterFamiliarity[this.fighterTwo.id])
      playerOneFamiliarityBonus +=
        this.playerOne.characterFamiliarity[this.fighterTwo.id];
    this.playerOneFamiliarityBonus = playerOneFamiliarityBonus / 10;

    let playerTwoFamiliarityBonus =
      this.playerTwo.characterFamiliarity[this.fighterTwo.id] || 0;
    if (this.playerTwo.characterFamiliarity[this.fighterOne.id])
      playerTwoFamiliarityBonus +=
        this.playerTwo.characterFamiliarity[this.fighterOne.id];
    this.playerTwoFamiliarityBonus = playerTwoFamiliarityBonus / 10;

    this.playerOneHype = this.fighterOne.hype;
    this.playerTwoHype = this.fighterTwo.hype;

    this.playerOneCharge = 0;
    this.playerTwoCharge = 0;

    this.playerOneEdgeguardSkipThreshold = null;
    this.playerTwoEdgeguardSkipThreshold = null;
  }

  initialiseAbility() {
    const humanPlayer = getData("humanPlayer");

    this.strategyActions = [];
    this.selectedStrategyActions = {};
    if (
      humanPlayer &&
      (humanPlayer.id === this.playerOne.id ||
        humanPlayer.id === this.playerTwo.id)
    ) {
      const humanFighter =
        humanPlayer.id === this.playerOne.id
          ? this.fighterOne
          : this.fighterTwo;
      const neutralStrategies = [
        STRATEGIES.NEUTRAL_OPTIONS.BALANCED,
        STRATEGIES.NEUTRAL_OPTIONS.PRESSURE,
        STRATEGIES.NEUTRAL_OPTIONS.COUNTER,
      ];
      if (humanFighter.projectiles > 0)
        neutralStrategies.push(STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM);

      this.strategyActions.push({
        label: STRATEGIES.NEUTRAL,
        values: neutralStrategies,
      });
      this.selectedStrategyActions[STRATEGIES.NEUTRAL] =
        STRATEGIES.NEUTRAL_OPTIONS.BALANCED;

      this.strategyActions.push({
        label: STRATEGIES.EDGEGUARDING,
        values: [
          STRATEGIES.EDGEGUARDING_OPTIONS.NONE,
          STRATEGIES.EDGEGUARDING_OPTIONS.LOW_RISK,
          STRATEGIES.EDGEGUARDING_OPTIONS.HIGH_RISK,
        ],
      });
      this.selectedStrategyActions[STRATEGIES.EDGEGUARDING] =
        STRATEGIES.EDGEGUARDING_OPTIONS.NONE;
    }

    this.abilityActions = [];
    if (humanPlayer && humanPlayer.id === this.playerOne.id)
      this.abilityActions = this.fighterOneAbility
        ? this.fighterOneAbility.actions
        : [];
    if (humanPlayer && humanPlayer.id === this.playerTwo.id)
      this.abilityActions = this.fighterTwoAbility
        ? this.fighterTwoAbility.actions
        : [];
  }

  skip() {
    this.skipping = true;
    this.interval = 1;
  }

  activeAbility(activity, value, playerPerformingAction) {
    if (playerPerformingAction.id === this.playerOne.id) {
      this.playerOneUsesAbility = value;
    } else if (playerPerformingAction.id === this.playerTwo.id) {
      this.playerTwoUsesAbility = value;
    }
  }

  play() {
    if (this.fighterOneAbility) {
      this.fighterOneAbility.exec(this, this.playerOne);
    }

    if (this.fighterTwoAbility) {
      this.fighterTwoAbility.exec(this, this.playerTwo);
    }

    if (this.showGameStart) {
      this.showCSS = false;

      setTimeout(() => {
        this.showGameStart = false;
        this.play();
      }, this.interval);
    } else {
      if (
        this.playerOneState === MATCH_STATES.FORM_SWITCH ||
        this.playerOneState === MATCH_STATES.CHARGE ||
        this.playerTwoState === MATCH_STATES.FORM_SWITCH ||
        this.playerTwoState === MATCH_STATES.CHARGE
      ) {
        setTimeout(() => {
          this.resetPhase();
        }, this.interval);
      } else if (
        this.playerOneState === MATCH_STATES.RELEASE_CHARGE ||
        this.playerTwoState === MATCH_STATES.RELEASE_CHARGE
      ) {
        this.releaseChargePhase();
      } else if (
        this.playerOneState === MATCH_STATES.SELF_DESTRUCTING ||
        this.playerTwoState === MATCH_STATES.SELF_DESTRUCTING ||
        this.playerOneState === MATCH_STATES.EDGEGUARDED ||
        this.playerTwoState === MATCH_STATES.EDGEGUARDED
      ) {
        this.playerOneState =
          this.playerOneState === MATCH_STATES.SELF_DESTRUCTING ||
          this.playerOneState === MATCH_STATES.EDGEGUARDED
            ? MATCH_STATES.RESPAWNING_FROM_BOTTOM
            : MATCH_STATES.NEUTRAL;
        this.playerTwoState =
          this.playerTwoState === MATCH_STATES.SELF_DESTRUCTING ||
          this.playerTwoState === MATCH_STATES.EDGEGUARDED
            ? MATCH_STATES.RESPAWNING_FROM_BOTTOM
            : MATCH_STATES.NEUTRAL;
        setTimeout(() => {
          this.resetPhase();
        }, this.interval);
      } else if (
        this.playerOneState === MATCH_STATES.RESPAWNING ||
        this.playerTwoState === MATCH_STATES.RESPAWNING
      ) {
        this.commentate(MATCH_STATES.RESPAWNING);
        this.resetPhase();
      } else if (
        this.playerOneState === MATCH_STATES.RECOVERING ||
        this.playerTwoState === MATCH_STATES.RECOVERING
      ) {
        this.commentate(MATCH_STATES.RECOVERING);
        this.resetPhase();
      } else if (
        this.playerOneState === MATCH_STATES.NEUTRAL ||
        this.playerTwoState === MATCH_STATES.NEUTRAL
      ) {
        this.neutralPhase();
      } else if (
        this.playerOneState === MATCH_STATES.ADVANTAGE ||
        this.playerTwoState === MATCH_STATES.ADVANTAGE ||
        this.playerOneState === MATCH_STATES.RELEASE_CHARGE_ADVANTAGE ||
        this.playerTwoState === MATCH_STATES.RELEASE_CHARGE_ADVANTAGE
      ) {
        this.advantagePhase();
      } else if (
        this.playerOneState === MATCH_STATES.VULNERABLE ||
        this.playerTwoState === MATCH_STATES.VULNERABLE
      ) {
        this.punishPhase();
      }
    }
  }

  commentate(phase) {
    let winningPlayer = this.playerOne;
    let winningFighter = this.fighterOne;
    let losingPlayer = this.playerTwo;
    let losingFighter = this.fighterTwo;

    if (
      (phase === MATCH_STATES.NEUTRAL &&
        this.playerOneStocks < this.playerTwoStocks) ||
      (phase === MATCH_STATES.ADVANTAGE &&
        this.playerTwoState === MATCH_STATES.ADVANTAGE) ||
      (phase === MATCH_STATES.PUNISHING &&
        this.playerTwoState === MATCH_STATES.PUNISHING) ||
      (phase === MATCH_STATES.RESPAWNING &&
        this.playerOneState === MATCH_STATES.RESPAWNING) ||
      (phase === MATCH_STATES.RECOVERING &&
        this.playerOneState === MATCH_STATES.RECOVERING)
    ) {
      winningPlayer = this.playerTwo;
      winningFighter = this.fighterTwo;
      losingPlayer = this.playerOne;
      losingFighter = this.fighterOne;
    }

    this.commentary.push(
      commentate(
        winningPlayer,
        winningFighter,
        losingPlayer,
        losingFighter,
        phase
      )
    );
  }

  resetPhase() {
    this.playerOneState = MATCH_STATES.NEUTRAL;
    this.playerTwoState = MATCH_STATES.NEUTRAL;

    this.playerOneBonus = false;
    this.playerTwoBonus = false;

    setTimeout(() => {
      this.play();
    }, this.interval);
  }

  releaseChargePhase() {
    let chargeSuccess = false;
    if (this.playerOneState === MATCH_STATES.VULNERABLE) {
      chargeSuccess = this.playerTwoCharge * 20 + this.playerOneDamage > 125;

      this.playerOneState = chargeSuccess
        ? MATCH_STATES.VULNERABLE
        : MATCH_STATES.DISADVANTAGE;
      this.playerTwoState = chargeSuccess
        ? MATCH_STATES.RELEASE_CHARGE_PUNISHING
        : MATCH_STATES.RELEASE_CHARGE_ADVANTAGE;

      setTimeout(() => {
        this.playerTwoCharge = 0;
      }, this.interval + 100);
    } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
      chargeSuccess = this.playerOneCharge * 20 + this.playerTwoDamage > 125;

      this.playerOneState = chargeSuccess
        ? MATCH_STATES.RELEASE_CHARGE_PUNISHING
        : MATCH_STATES.RELEASE_CHARGE_ADVANTAGE;
      this.playerTwoState = chargeSuccess
        ? MATCH_STATES.VULNERABLE
        : MATCH_STATES.DISADVANTAGE;

      setTimeout(() => {
        this.playerOneCharge = 0;
      }, this.interval + 100);
    }
    setTimeout(() => {
      this.play();
    }, this.interval);
  }

  neutralPhase() {
    this.commentate(MATCH_STATES.NEUTRAL);

    const humanPlayer = getData("humanPlayer");

    this.playerOneState = MATCH_STATES.NEUTRAL;
    this.playerTwoState = MATCH_STATES.NEUTRAL;

    this.playerOneBonus = false;
    this.playerTwoBonus = false;

    let playerOneStrategyBonus, playerTwoStrategyBonus;

    if (humanPlayer && humanPlayer.id === this.playerOne.id) {
      this.playerOne.neutralStrategy =
        this.selectedStrategyActions[STRATEGIES.NEUTRAL];
      switch (this.selectedStrategyActions[STRATEGIES.NEUTRAL]) {
        case STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW:
        case STRATEGIES.NEUTRAL_OPTIONS.BALANCED:
          playerOneStrategyBonus =
            (this.fighterOne.projectiles +
              this.fighterOne.mobility +
              this.fighterOne.outofshield) /
            3;
          this.playerOneHype += 1;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT:
        case STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID:
        case STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM:
          if (this.fighterOne.projectiles > 0)
            playerOneStrategyBonus = this.fighterOne.projectiles;
          else playerOneStrategyBonus = this.fighterOne.outofshield;
          this.playerOneHype -= 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PRESSURE:
          playerOneStrategyBonus = this.fighterOne.mobility;
          this.playerOneHype += 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.COUNTER:
          playerOneStrategyBonus = this.fighterOne.outofshield;
          this.playerOneHype -= 1;
          break;
      }

      switch (this.playerTwo.neutralStrategy) {
        case STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW:
        case STRATEGIES.NEUTRAL_OPTIONS.BALANCED:
          playerTwoStrategyBonus =
            (this.fighterTwo.projectiles +
              this.fighterTwo.mobility +
              this.fighterTwo.outofshield) /
            3;
          this.playerTwoHype += 1;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT:
        case STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID:
        case STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM:
          if (this.fighterTwo.projectiles > 0)
            playerTwoStrategyBonus = this.fighterTwo.projectiles;
          else playerTwoStrategyBonus = this.fighterTwo.outofshield;
          this.playerTwoHype -= 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PRESSURE:
          playerTwoStrategyBonus = this.fighterTwo.mobility;
          this.playerTwoHype += 2;

          break;
        case STRATEGIES.NEUTRAL_OPTIONS.COUNTER:
          playerTwoStrategyBonus = this.fighterTwo.outofshield;
          this.playerTwoHype -= 1;

          break;
      }
    } else if (humanPlayer && humanPlayer.id === this.playerTwo.id) {
      this.playerTwo.neutralStrategy =
        this.selectedStrategyActions[STRATEGIES.NEUTRAL];
      switch (this.selectedStrategyActions[STRATEGIES.NEUTRAL]) {
        case STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW:
        case STRATEGIES.NEUTRAL_OPTIONS.BALANCED:
          playerTwoStrategyBonus =
            (this.fighterTwo.projectiles +
              this.fighterTwo.mobility +
              this.fighterTwo.outofshield) /
            3;
          this.playerTwoHype += 1;

          break;
        case STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT:
        case STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID:
        case STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM:
          if (this.fighterTwo.projectiles > 0)
            playerTwoStrategyBonus = this.fighterTwo.projectiles;
          else playerTwoStrategyBonus = this.fighterTwo.outofshield;
          this.playerTwoHype -= 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PRESSURE:
          playerTwoStrategyBonus = this.fighterTwo.mobility;
          this.playerTwoHype += 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.COUNTER:
          playerTwoStrategyBonus = this.fighterTwo.outofshield;
          this.playerTwoHype -= 1;

          break;
      }

      switch (this.playerOne.neutralStrategy) {
        case STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW:
        case STRATEGIES.NEUTRAL_OPTIONS.BALANCED:
          playerOneStrategyBonus =
            (this.fighterOne.projectiles +
              this.fighterOne.mobility +
              this.fighterOne.outofshield) /
            3;
          this.playerOneHype += 1;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT:
        case STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID:
        case STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM:
          if (this.fighterOne.projectiles > 0)
            playerOneStrategyBonus = this.fighterOne.projectiles;
          else playerOneStrategyBonus = this.fighterOne.outofshield;
          this.playerOneHype -= 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PRESSURE:
          playerOneStrategyBonus = this.fighterOne.mobility;
          this.playerOneHype += 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.COUNTER:
          playerOneStrategyBonus = this.fighterOne.outofshield;
          this.playerOneHype -= 1;
          break;
      }
    } else {
      switch (this.playerOne.neutralStrategy) {
        case STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW:
        case STRATEGIES.NEUTRAL_OPTIONS.BALANCED:
          playerOneStrategyBonus =
            (this.fighterOne.projectiles +
              this.fighterOne.mobility +
              this.fighterOne.outofshield) /
            3;
          this.playerOneHype += 1;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT:
        case STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID:
        case STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM:
          if (this.fighterOne.projectiles > 0)
            playerOneStrategyBonus = this.fighterOne.projectiles;
          else playerOneStrategyBonus = this.fighterOne.outofshield;
          this.playerOneHype -= 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PRESSURE:
          playerOneStrategyBonus = this.fighterOne.mobility;
          this.playerOneHype += 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.COUNTER:
          playerOneStrategyBonus = this.fighterOne.outofshield;
          this.playerOneHype -= 1;
          break;
      }

      switch (this.playerTwo.neutralStrategy) {
        case STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW:
        case STRATEGIES.NEUTRAL_OPTIONS.BALANCED:
          playerTwoStrategyBonus =
            (this.fighterTwo.projectiles +
              this.fighterTwo.mobility +
              this.fighterTwo.outofshield) /
            3;
          this.playerTwoHype += 1;

          break;
        case STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT:
        case STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID:
        case STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM:
          if (this.fighterTwo.projectiles > 0)
            playerTwoStrategyBonus = this.fighterTwo.projectiles;
          else playerTwoStrategyBonus = this.fighterTwo.outofshield;
          this.playerTwoHype -= 2;

          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PRESSURE:
          playerTwoStrategyBonus = this.fighterTwo.mobility;
          this.playerTwoHype += 2;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.COUNTER:
          playerTwoStrategyBonus = this.fighterTwo.outofshield;
          this.playerTwoHype -= 1;
          break;
      }
    }

    let playerOneNeutral =
      Math.random() * NEUTRAL_MODIFIER +
      (NEUTRAL_MODIFIER + this.playerOne.neutral) +
      this.fighterOne.neutral +
      this.playerOneFamiliarityBonus +
      Math.random() *
        (playerOneStrategyBonus *
          STRATEGY_EFFECTIVENESS[this.playerOne.neutralStrategy][
            this.playerTwo.neutralStrategy
          ] -
          playerTwoStrategyBonus);
    let playerTwoNeutral =
      Math.random() * NEUTRAL_MODIFIER +
      (NEUTRAL_MODIFIER + this.playerTwo.neutral) +
      this.fighterTwo.neutral +
      this.playerTwoFamiliarityBonus +
      Math.random() *
        (playerTwoStrategyBonus *
          STRATEGY_EFFECTIVENESS[this.playerTwo.neutralStrategy][
            this.playerOne.neutralStrategy
          ] -
          playerOneStrategyBonus);

    switch (this.fighterOne.type) {
      case FIGHTER_TYPES.RUSHDOWN:
        if (
          this.fighterTwo.type === FIGHTER_TYPES.ZONER &&
          Math.random() > 0.8
        ) {
          // console.log("||P1 rushdown bonus");
          playerOneNeutral *= 1.25;
        }
        break;
      case FIGHTER_TYPES.ZONER:
        if (
          this.fighterTwo.type === FIGHTER_TYPES.BAIT_PUNISH &&
          Math.random() > 0.8
        ) {
          // console.log("||P1 zoner bonus");
          playerOneNeutral *= 1.25;
        }
        break;
      case FIGHTER_TYPES.BAIT_PUNISH:
        if (
          this.fighterTwo.type === FIGHTER_TYPES.RUSHDOWN &&
          Math.random() > 0.8
        ) {
          // console.log("||P1 bp bonus");
          playerOneNeutral *= 1.25;
        }
        break;
    }

    switch (this.fighterTwo.type) {
      case FIGHTER_TYPES.RUSHDOWN:
        if (
          this.fighterOne.type === FIGHTER_TYPES.ZONER &&
          Math.random() > 0.8
        ) {
          // console.log("||P2 rushdown bonus");
          playerTwoNeutral *= 1.25;
        }
        break;
      case FIGHTER_TYPES.ZONER:
        if (
          this.fighterOne.type === FIGHTER_TYPES.BAIT_PUNISH &&
          Math.random() > 0.8
        ) {
          // console.log("||P2 zoner bonus");
          playerTwoNeutral *= 1.25;
        }
        break;
      case FIGHTER_TYPES.BAIT_PUNISH:
        if (
          this.fighterOne.type === FIGHTER_TYPES.RUSHDOWN &&
          Math.random() > 0.8
        ) {
          // console.log("||P2 bp bonus");
          playerTwoNeutral *= 1.25;
        }
        break;
    }

    if (playerOneNeutral > playerTwoNeutral) {
      this.playerTwoDamage += playerOneNeutral / 2;
      if (
        this.fighterOne.type === FIGHTER_TYPES.BAIT_PUNISH &&
        Math.random() > 0.7
      ) {
        // P1 bp advantage skip bonus
        this.playerOneBonus = true;
        this.playerOneState = MATCH_STATES.PUNISHING;
        this.playerTwoState = MATCH_STATES.VULNERABLE;
      } else if (
        this.fighterTwo.type === FIGHTER_TYPES.ZONER &&
        this.playerTwo.neutralStrategy !==
          STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT &&
        this.playerTwo.neutralStrategy !==
          STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID &&
        Math.random() > 0.7
      ) {
        // P2 zoner advantage skip bonus
        this.playerTwoBonus = true;
        this.playerOneState = MATCH_STATES.DISADVANTAGE;
        this.playerTwoState = MATCH_STATES.ADVANTAGE;
      } else {
        this.playerOneState = MATCH_STATES.ADVANTAGE;
        this.playerTwoState = MATCH_STATES.DISADVANTAGE;
      }
    } else if (playerOneNeutral < playerTwoNeutral) {
      this.playerOneDamage += playerTwoNeutral / 2;
      if (
        this.fighterTwo.type === FIGHTER_TYPES.BAIT_PUNISH &&
        Math.random() > 0.7
      ) {
        // P2 bp advantage skip bonus
        this.playerTwoBonus = true;
        this.playerOneState = MATCH_STATES.VULNERABLE;
        this.playerTwoState = MATCH_STATES.PUNISHING;
      } else if (
        this.fighterOne.type === FIGHTER_TYPES.ZONER &&
        this.playerOne.neutralStrategy !==
          STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT &&
        this.playerOne.neutralStrategy !==
          STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID &&
        Math.random() > 0.7
      ) {
        // P1 zoner advantage skip bonus
        this.playerOneBonus = true;
        this.playerOneState = MATCH_STATES.ADVANTAGE;
        this.playerTwoState = MATCH_STATES.DISADVANTAGE;
      } else {
        this.playerOneState = MATCH_STATES.DISADVANTAGE;
        this.playerTwoState = MATCH_STATES.ADVANTAGE;
      }
    }

    let interval = this.interval;
    if (
      this.playerOneState === MATCH_STATES.ADVANTAGE &&
      this.playerOneSkipNeutralInterval
    ) {
      interval = 1;
      this.playerOneSkipNeutralInterval = false;
    } else if (
      this.playerTwoState === MATCH_STATES.ADVANTAGE &&
      this.playerTwoSkipNeutralInterval
    ) {
      interval = 1;
      this.playerTwoSkipNeutralInterval = false;
    }

    setTimeout(() => {
      this.play();
    }, interval);
  }

  advantagePhase() {
    this.commentate(MATCH_STATES.ADVANTAGE);

    let advantage;
    if (
      (this.playerOneState === MATCH_STATES.ADVANTAGE &&
        (this.playerOne.neutralStrategy ===
          STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT ||
          this.playerOne.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID)) ||
      (this.playerTwoState === MATCH_STATES.ADVANTAGE &&
        (this.playerTwo.neutralStrategy ===
          STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT ||
          this.playerTwo.neutralStrategy ===
            STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID))
    ) {
      advantage = 0;
    } else if (
      this.playerOneState === MATCH_STATES.ADVANTAGE ||
      this.playerOneState === MATCH_STATES.RELEASE_CHARGE_ADVANTAGE
    ) {
      let damageDealt =
        Math.random() * ADVANTAGE_MODIFIER +
        (this.playerOne.advantage + this.fighterOne.advantage);

      advantage = this.playerTwoDamage;
      advantage +=
        Math.random() * (this.playerOne.advantage + this.fighterOne.advantage);
      advantage -=
        Math.random() *
        (this.playerTwo.disadvantage + this.fighterTwo.disadvantage);

      if (
        this.fighterOne.type === FIGHTER_TYPES.RUSHDOWN &&
        Math.random() > 0.9
      ) {
        if (advantage < 0) advantage *= -1;
        else advantage *= 2;

        // P1 rushdown advantage bonus
        this.playerOneBonus = true;
        damageDealt *= 1.5;
      }

      this.playerTwoDamage += damageDealt;
    } else if (
      this.playerTwoState === MATCH_STATES.ADVANTAGE ||
      this.playerTwoState === MATCH_STATES.RELEASE_CHARGE_ADVANTAGE
    ) {
      let damageDealt =
        Math.random() * ADVANTAGE_MODIFIER +
        (this.playerTwo.advantage + this.fighterTwo.advantage);

      advantage = this.playerOneDamage;
      advantage +=
        Math.random() * (this.playerTwo.advantage + this.fighterTwo.advantage);
      advantage -=
        Math.random() *
        (this.playerOne.disadvantage + this.fighterOne.disadvantage);

      if (
        this.fighterTwo.type === FIGHTER_TYPES.RUSHDOWN &&
        Math.random() > 0.7
      ) {
        if (advantage < 0) advantage *= -1;
        else advantage *= 2;

        // P2 rushdown advantage bonus
        this.playerTwoBonus = true;
        damageDealt *= 1.5;
      }

      this.playerOneDamage += damageDealt;
    }

    if (advantage < 100) {
      this.playerOneState = MATCH_STATES.NEUTRAL;
      this.playerTwoState = MATCH_STATES.NEUTRAL;
    } else {
      if (this.playerOneState === MATCH_STATES.ADVANTAGE) {
        this.playerOneState = MATCH_STATES.PUNISHING;
        this.playerTwoState = MATCH_STATES.VULNERABLE;
      } else if (this.playerTwoState === MATCH_STATES.ADVANTAGE) {
        this.playerOneState = MATCH_STATES.VULNERABLE;
        this.playerTwoState = MATCH_STATES.PUNISHING;
      } else {
        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.NEUTRAL;
      }
    }

    setTimeout(() => {
      this.play();
    }, this.interval);
  }

  punishPhase() {
    this.commentate(MATCH_STATES.PUNISHING);

    let punish;
    if (this.playerOneState === MATCH_STATES.VULNERABLE) {
      punish =
        this.playerOneDamage +
        Math.random() * PUNISH_MODIFIER +
        (this.playerTwo.punish + this.fighterTwo.punish);

      if (
        this.playerTwoBonus &&
        this.fighterTwo.type === FIGHTER_TYPES.BAIT_PUNISH
      )
        punish *= 2.5;

      punish += this.playerTwoCharge * PUNISH_MODIFIER;

      punish -=
        Math.random() * PUNISH_MODIFIER +
        (this.playerOne.technique +
          this.fighterOne.recovery +
          this.fighterOne.weight);

      this.playerOneDamage += this.playerTwo.punish + this.fighterTwo.punish;
    } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
      punish =
        this.playerTwoDamage +
        Math.random() * PUNISH_MODIFIER +
        (this.playerOne.punish + this.fighterOne.punish);

      if (
        this.playerOneBonus &&
        this.fighterOne.type === FIGHTER_TYPES.BAIT_PUNISH
      )
        punish *= 2.5;

      punish += this.playerOneCharge * PUNISH_MODIFIER;

      punish -=
        Math.random() * PUNISH_MODIFIER +
        (this.playerTwo.technique +
          this.fighterTwo.recovery +
          this.fighterTwo.weight);

      this.playerTwoDamage += this.playerOne.punish + this.fighterOne.punish;
    }

    this.playerOneBonus = false;
    this.playerTwoBonus = false;

    if (punish > 150) {
      if (this.playerOneState === MATCH_STATES.VULNERABLE) {
        this.playerOneStocks--;
        this.playerOneDamage = 0;
        this.playerOneCharge = 0;

        this.playerOneState = MATCH_STATES.RESPAWNING;
        this.playerTwoState = MATCH_STATES.NEUTRAL;

        this.changeAIStrategy(this.playerOne, this.playerTwo, this.fighterOne);
      } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
        this.playerTwoStocks--;
        this.playerTwoDamage = 0;
        this.playerTwoCharge = 0;

        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.RESPAWNING;

        this.changeAIStrategy(this.playerTwo, this.playerOne, this.fighterTwo);
      }
    } else {
      this.recoveryPhase();
      return;
    }

    this.checkStocksRemaining();
  }

  recoveryPhase() {
    const humanPlayer = getData("humanPlayer");

    this.playerOneEdgeguardingStrategy =
      this.playerOneEdgeguardingOptions[
        Math.floor(Math.random() * this.playerOneEdgeguardingOptions.length)
      ];
    this.playerTwoEdgeguardingStrategy =
      this.playerTwoEdgeguardingOptions[
        Math.floor(Math.random() * this.playerTwoEdgeguardingOptions.length)
      ];

    if (humanPlayer) {
      if (this.playerOne.id === humanPlayer.id)
        this.playerOneEdgeguardingStrategy =
          this.selectedStrategyActions[STRATEGIES.EDGEGUARDING];
      else if (this.playerTwo.id === humanPlayer.id)
        this.playerTwoEdgeguardingStrategy =
          this.selectedStrategyActions[STRATEGIES.EDGEGUARDING];
    }

    if (this.playerOneState === MATCH_STATES.VULNERABLE) {
      this.playerTwoHype +=
        STRATEGY_EFFECTIVENESS[this.playerTwoEdgeguardingStrategy].HYPE;
      if (
        this.playerTwoEdgeguardingStrategy ===
        STRATEGIES.EDGEGUARDING_OPTIONS.NONE
      ) {
        this.playerOneState = MATCH_STATES.RECOVERING;
        this.playerTwoState = MATCH_STATES.NEUTRAL;
      } else {
        const risk =
          STRATEGY_EFFECTIVENESS[this.playerTwoEdgeguardingStrategy].RISK;
        const reward =
          STRATEGY_EFFECTIVENESS[this.playerTwoEdgeguardingStrategy].REWARD;

        const edgeguardSuccess =
          Math.random() * this.playerTwo.punish + this.fighterTwo.punish >
          this.fighterOne.recovery - reward;
        let stageReturnSuccess =
          Math.random() * this.playerTwo.technique + this.fighterTwo.recovery >
          this.fighterTwo.recovery + risk;

        if (
          this.playerOneEdgeguardSkipThreshold &&
          this.playerOneEdgeguardSkipThreshold > reward
        ) {
          stageReturnSuccess = true;
        }

        this.playerOneState = edgeguardSuccess
          ? MATCH_STATES.EDGEGUARDED
          : MATCH_STATES.RECOVERING;
        this.playerTwoState = stageReturnSuccess
          ? MATCH_STATES.EDGEGUARDING
          : MATCH_STATES.SELF_DESTRUCTING;

        if (this.playerTwoState === MATCH_STATES.SELF_DESTRUCTING) {
          this.playerTwoStocks--;
          this.playerTwoDamage = 0;
          this.playerTwoCharge = 0;

          this.changeAIStrategy(
            this.playerTwo,
            this.playerOne,
            this.fighterTwo
          );
        }
        if (
          this.playerOneState === MATCH_STATES.EDGEGUARDED &&
          this.playerTwoStocks > 0
        ) {
          this.playerOneStocks--;
          this.playerOneDamage = 0;
          this.playerOneCharge = 0;

          this.changeAIStrategy(
            this.playerOne,
            this.playerTwo,
            this.fighterOne
          );
        }
      }
    } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
      this.playerOneHype +=
        STRATEGY_EFFECTIVENESS[this.playerOneEdgeguardingStrategy].HYPE;
      if (
        this.playerOneEdgeguardingStrategy ===
        STRATEGIES.EDGEGUARDING_OPTIONS.NONE
      ) {
        this.playerTwoState = MATCH_STATES.RECOVERING;
        this.playerOneState = MATCH_STATES.NEUTRAL;
      } else {
        const risk =
          STRATEGY_EFFECTIVENESS[this.playerOneEdgeguardingStrategy].RISK;
        const reward =
          STRATEGY_EFFECTIVENESS[this.playerOneEdgeguardingStrategy].REWARD;

        const edgeguardSuccess =
          Math.random() * (this.playerOne.punish + this.fighterOne.punish) >
          this.fighterTwo.recovery - reward;
        let stageReturnSuccess =
          Math.random() *
            (this.playerOne.technique + this.fighterOne.recovery) >
          this.fighterOne.recovery + risk;

        if (
          this.playerTwoEdgeguardSkipThreshold &&
          this.playerTwoEdgeguardSkipThreshold > reward
        ) {
          stageReturnSuccess = true;
        }

        this.playerOneState = stageReturnSuccess
          ? MATCH_STATES.EDGEGUARDING
          : MATCH_STATES.SELF_DESTRUCTING;
        this.playerTwoState = edgeguardSuccess
          ? MATCH_STATES.EDGEGUARDED
          : MATCH_STATES.RECOVERING;

        if (this.playerOneState === MATCH_STATES.SELF_DESTRUCTING) {
          this.playerOneStocks--;
          this.playerOneDamage = 0;
          this.playerOneCharge = 0;

          this.changeAIStrategy(
            this.playerOne,
            this.playerTwo,
            this.fighterOne
          );
        }
        if (
          this.playerTwoState === MATCH_STATES.EDGEGUARDED &&
          this.playerOneStocks > 0
        ) {
          this.playerTwoStocks--;
          this.playerTwoDamage = 0;
          this.playerTwoCharge = 0;

          this.changeAIStrategy(
            this.playerTwo,
            this.playerOne,
            this.fighterTwo
          );
        }
      }
    }

    this.checkStocksRemaining();
  }

  checkStocksRemaining() {
    if (this.playerOneStocks === 0 || this.playerTwoStocks === 0) {
      if (this.playerOneStocks === 0) {
        this.playerOneState =
          this.playerOneState === MATCH_STATES.RESPAWNING
            ? MATCH_STATES.DEFEATED
            : this.playerOneState;
        this.playerTwoState =
          this.playerOneState === MATCH_STATES.DEFEATED
            ? MATCH_STATES.PUNISHING
            : this.playerOneState === MATCH_STATES.SELF_DESTRUCTING
            ? MATCH_STATES.RECOVERING
            : MATCH_STATES.EDGEGUARDING;
      } else if (this.playerTwoStocks === 0) {
        this.playerTwoState =
          this.playerTwoState === MATCH_STATES.RESPAWNING
            ? MATCH_STATES.DEFEATED
            : this.playerTwoState;
        this.playerOneState =
          this.playerTwoState === MATCH_STATES.DEFEATED
            ? MATCH_STATES.PUNISHING
            : this.playerTwoState === MATCH_STATES.SELF_DESTRUCTING
            ? MATCH_STATES.RECOVERING
            : MATCH_STATES.EDGEGUARDING;
      }
      setTimeout(() => {
        this.finish();
      }, this.interval);
    } else {
      setTimeout(() => {
        this.play();
      }, this.interval);
    }
  }

  changeAIStrategy(player, opponent, fighter) {
    const humanPlayer = getData("humanPlayer");

    if (!humanPlayer || (humanPlayer && humanPlayer.id !== player.id)) {
      switch (opponent.neutralStrategy) {
        case STRATEGIES.NEUTRAL_OPTIONS.COUNTER:
          if (fighter.projectiles !== 0) {
            player.neutralStrategy = STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM;
          } else {
            player.neutralStrategy = STRATEGIES.NEUTRAL_OPTIONS.BALANCED;
          }
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PRESSURE:
          player.neutralStrategy = STRATEGIES.NEUTRAL_OPTIONS.COUNTER;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM:
          player.neutralStrategy = STRATEGIES.NEUTRAL_OPTIONS.PRESSURE;
          break;
        case STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW:
        case STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT:
        case STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID:
          break;
        default:
          const options = [
            STRATEGIES.NEUTRAL_OPTIONS.BALANCED,
            STRATEGIES.NEUTRAL_OPTIONS.COUNTER,
            STRATEGIES.NEUTRAL_OPTIONS.PRESSURE,
          ];
          if (fighter.projectiles > 0)
            options.push(STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM);
          player.neutralStrategy = options.sort(
            () => Math.random() - Math.random()
          )[0];
          break;
      }
    }
  }

  finish() {
    this.showGameEnd = true;

    setTimeout(() => {
      this.complete = true;

      if (this.playerOneStocks > this.playerTwoStocks) {
        this.onFinish(
          this.playerOne.id,
          this.playerOneStocks,
          this.playerOneHype,
          this.playerTwoHype
        );
      } else {
        this.onFinish(
          this.playerTwo.id,
          this.playerTwoStocks,
          this.playerTwoHype,
          this.playerOneHype
        );
      }
    }, DEFAULT_INTERVAL);
  }
}
