import React, { Component } from "react";

import { charIcons } from "../../data/assets";
import "./MatchView.scss";

class MatchView extends Component {
    render() {
        const {
            roundMatch,
            fighterA,
            fighterB,
            competitorA,
            competitorB,
        } = this.props;

        return (
            <div className="round-match">
                <div>
                    <span>
                        {fighterA ? <img src={charIcons[fighterA.name]} /> : ""}
                        {competitorA ? competitorA.name : ""}{" "}
                        {competitorA ? <img src={competitorA.flag} /> : ""}
                    </span>
                    <strong>{roundMatch.scoreA}</strong>
                </div>
                <div>
                    <span>
                        {fighterB ? <img src={charIcons[fighterB.name]} /> : ""}
                        {competitorB ? competitorB.name : ""}{" "}
                        {competitorB ? <img src={competitorB.flag} /> : ""}
                    </span>
                    <strong>{roundMatch.scoreB}</strong>
                </div>
            </div>
        );
    }
}

export default MatchView;
