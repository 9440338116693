import React, { Component } from "react";
import { Link } from "react-router-dom";

import { charIcons } from "../../data/assets";
import Button from "../../components/button/Button";
import { getData, setData } from "../../util";

import "./Region.scss";
import FollowManagement from "../../components/follow-management/FollowManagement";

class Region extends Component {
    getPlayers() {
        const regions = getData("regions");
        const players = getData("players");
        const fighters = getData("fighters");
        const region = regions.find(
            (r) => r.id === parseInt(this.props.match.params.id)
        );

        let regionPlayers = region.players.map((playerId) =>
            players.find((player) => player.id === playerId)
        );

        regionPlayers = regionPlayers.sort((a, b) =>
            a.seed > b.seed ? -1 : 1
        );

        return (
            <ul className="region">
                <li>{region.name}</li>
                <ul>
                    {regionPlayers.map((player) => {
                        const main = fighters.find(
                            (fighter) => fighter.id === player.main
                        );
                        return (
                            <li>
                                {main ? <img src={charIcons[main.name]} /> : ""}
                                <Link
                                    to={`${process.env.PUBLIC_URL}/players/${player.id}`}
                                >
                                    <strong>{player.name}</strong> [
                                    {player.seed.toFixed(2)}]
                                </Link>
                                <FollowManagement player={player} />
                            </li>
                        );
                    })}
                </ul>
            </ul>
        );
    }

    render() {
        return <div className="region-container">{this.getPlayers()}</div>;
    }
}

export default Region;
