import React, { Component } from "react";
import { Link } from "react-router-dom";

import { charIcons } from "../../data/assets";
import Button from "../../components/button/Button";
import { getData } from "../../util";

import "./Regions.scss";

class Regions extends Component {
    parentRegions() {
        const parentRegions = getData("parentRegions");
        const players = getData("players");
        const followedPlayers = getData("followedPlayers");
        const humanPlayer = getData("humanPlayer");
        const fighters = getData("fighters");
        const sponsors = getData("sponsors");

        return Object.keys(parentRegions).map((parentRegion) => (
            <ul className="parent-region" key={parentRegion}>
                <li>{parentRegion}</li>
                {parentRegions[parentRegion].map((region) => {
                    let regionPlayers = region.players.map((playerId) =>
                        players.find((player) => player.id === playerId)
                    );

                    regionPlayers = regionPlayers
                        .sort((a, b) => (a.seed > b.seed ? -1 : 1))
                        .slice(0, 8);

                    return (
                        <ul className="region" key={region.id}>
                            <li>{region.name}</li>
                            <ul>
                                {regionPlayers.map((player) => {
                                    const main = fighters.find(
                                        (fighter) => fighter.id === player.main
                                    );
                                    const isFollowed = followedPlayers.find(
                                        (fp) => fp.id === player.id
                                    );
                                    const isHuman =
                                        humanPlayer &&
                                        humanPlayer.id === player.id;

                                    const sponsorKey = player.sponsor
                                        ? Object.keys(sponsors).find((key) => {
                                              const sponsor = sponsors[key];
                                              return (
                                                  sponsor.id === player.sponsor
                                              );
                                          })
                                        : null;

                                    const sponsor = sponsors[sponsorKey];
                                    return (
                                        <li
                                            key={player.id}
                                            className={
                                                isHuman
                                                    ? "human"
                                                    : isFollowed
                                                    ? "followed"
                                                    : ""
                                            }
                                        >
                                            <Link
                                                to={`${process.env.PUBLIC_URL}/players/${player.id}`}
                                            >
                                                {sponsor && (
                                                    <img src={sponsor.emblem} />
                                                )}
                                                {player.name} [
                                                {player.seed.toFixed(2)}]
                                            </Link>
                                            {main ? (
                                                <img
                                                    src={charIcons[main.name]}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </li>
                                    );
                                })}
                                <li>
                                    <Button href={`/regions/${region.id}`}>
                                        See all
                                    </Button>
                                </li>
                            </ul>
                        </ul>
                    );
                })}
            </ul>
        ));
    }

    render() {
        return <div className="regions-container">{this.parentRegions()}</div>;
    }
}

export default Regions;
