import React, { Component } from "react";

import "./TextInput.scss";

class TextInput extends Component {
    onInput() {
        const { onInput, name } = this.props;
        if (!onInput) return;

        const input = document.getElementById(name);
        onInput(input.value);
    }

    render() {
        const { name } = this.props;
        return (
            <input
                id={name}
                name={name}
                type="text"
                className="text-input"
                autoComplete="off"
                onInput={this.onInput.bind(this)}
            />
        );
    }
}

export default TextInput;
