export default class Region {
    constructor(
        id,
        name,
        parentRegion,
        countryCode,
        tournamentName,
        tournamentPrizePot = 1000
    ) {
        this.id = id;
        this.name = name;
        this.tournamentName = tournamentName;
        this.parentRegion = parentRegion;
        this.players = [];
        this.flag = `https://flagcdn.com/36x27/${countryCode}.png`;
        this.tournamentPrizePot = tournamentPrizePot;
    }
}
