import { generateId } from "../util";

export const FIGHTER_TYPES = {
  RUSHDOWN: "Rushdown", // 30% to double advantage bonus, 20% chance of neutral bonus over ZONER
  ZONER: "Zoner", // 30% chance of stopping opponent proceeding to advantage after winning neutral, 20% chance of neutral bonus over BAIT_PUNISH
  BAIT_PUNISH: "Bait & Punish", // 30% chance to skip advantage straight to punish, 20% chance of neutral bonus over RUSHDOWN
  DYNAMIC: "Dynamic", // character type changes depending on match conditions
};

export const FIGHTER_TYPE_ICONS = {
  [FIGHTER_TYPES.RUSHDOWN]: `${process.env.PUBLIC_URL}/rushdown.png`,
  [FIGHTER_TYPES.ZONER]: `${process.env.PUBLIC_URL}/zoner.png`,
  [FIGHTER_TYPES.BAIT_PUNISH]: `${process.env.PUBLIC_URL}/bait.png`,
  [FIGHTER_TYPES.DYNAMIC]: `${process.env.PUBLIC_URL}/dynamic.png`,
};

export const FIGHTER_TYPE_ICONS_DARK = {
  [FIGHTER_TYPES.RUSHDOWN]: `${process.env.PUBLIC_URL}/rushdown_b.png`,
  [FIGHTER_TYPES.ZONER]: `${process.env.PUBLIC_URL}/zoner_b.png`,
  [FIGHTER_TYPES.BAIT_PUNISH]: `${process.env.PUBLIC_URL}/bait_b.png`,
  [FIGHTER_TYPES.DYNAMIC]: `${process.env.PUBLIC_URL}/dynamic_b.png`,
};

export default class Fighter {
  constructor(name, stats = null, dlcIndex = null, ability = null, forms = []) {
    this.id = generateId();
    this.name = name;
    this.seed = 1;
    this.dlcIndex = dlcIndex;

    this.ability = ability;
    this.forms = forms;

    this.neutral = 5;
    this.projectiles = 5;
    this.mobility = 5;
    // grapple

    this.advantage = 5;
    // combo finishers
    // edgeguarding
    // ledgetrapping

    this.disadvantage = 5;
    this.weight = 5;
    this.outofshield = 5;
    // hurtbox size

    this.punish = 5;
    // smash attacks
    // shield break setups

    this.recovery = 5;
    // recovery distance
    // recovery hitboxes

    this.type = "";

    this.hype = 0;

    this.history = [
      {
        balance: null,
        position: null,
      },
    ];

    if (stats) {
      Object.keys(stats).forEach((key) => (this[key] = stats[key]));
    }
  }

  generateTechnicalStats() {
    this.neutral = Math.ceil(Math.random() * 10);
    this.advantage = Math.ceil(Math.random() * 10);
    this.disadvantage = Math.ceil(Math.random() * 10);
    this.punish = Math.ceil(Math.random() * 10);
    this.recovery = Math.ceil(Math.random() * 10);
  }

  mapJson(json) {
    this.id = json.id;
    this.neutral = json.neutral;
    this.advantage = json.advantage;
    this.disadvantage = json.disadvantage;
    this.punish = json.punish;
    this.recovery = json.recovery;
    this.weight = json.weight;
    this.projectiles = json.projectiles;
    this.mobility = json.mobility;
    this.outofshield = json.outofshield;
    this.type = json.type;
    this.history = json.history;
    this.seed = json.seed;
    this.index = json.index;
    this.ability = json.ability;
    this.forms = json.forms;
    this.hype = json.hype;
  }

  nerf(notes) {
    const stats = [
      "neutral",
      "advantage",
      "disadvantage",
      "punish",
      "recovery",
      "projectiles",
      "mobility",
      "outofshield",
    ];

    const statToChange = Math.floor(Math.random() * stats.length);
    if (statToChange === "projectiles" && this.projectiles === 0) return;

    const changeBy = 1 + Math.ceil(Math.random() * 4);

    this[stats[statToChange]] -= changeBy;
    if (this[stats[statToChange]] < 1) {
      this[stats[statToChange]] = 1;
    }

    if (notes[this.id]) {
      notes[this.id] -= changeBy;
    } else {
      notes[this.id] = -changeBy;
    }
  }

  buff(notes) {
    const stats = [
      "neutral",
      "advantage",
      "disadvantage",
      "punish",
      "recovery",
      "projectiles",
      "mobility",
      "outofshield",
    ];
    const statToChange = Math.floor(Math.random() * stats.length);
    if (statToChange === "projectiles" && this.projectiles === 0) return;

    const changeBy = 1 + Math.ceil(Math.random() * 4);

    this[stats[statToChange]] += changeBy;

    if (notes[this.id]) {
      notes[this.id] += changeBy;
    } else {
      notes[this.id] = changeBy;
    }
  }
}
