import { getData } from "../util";

export const VIDEO_VIBES = {
  CHILL: "Chilled",
  INFORMATIVE: "Informative",
  FUNNY: "Funny",
};

export const getVideoTitle = (vibe, character, streamer) => {
  const pool = VIDEO_TITLES[vibe];
  const title = pool[Math.floor(Math.random() * pool.length)];

  const time = getData("time");
  const timeString = `S${time ? time.season : ""} M${time ? time.month : ""} W${
    time ? time.week : ""
  }`;

  return title
    .replace(/{{character}}/g, character)
    .replace(/{{streamer}}/g, streamer)
    .replace(/{{time}}/g, timeString);
};

export const VIDEO_TITLES = {
  [VIDEO_VIBES.CHILL]: [
    `Just lounging and playing some vidya`,
    `Getting down with some {{character}}`,
    `Most elite {{character}} gameplay`,
    `Reacting to the BEST {{character}} combos`,
    `{{streamer}}'s tier list - {{time}}`,
    `{{character}} can do that?`,
    `{{streamer}} Iron Man Challenge`,
    `Running an online Nuzlocke!`,
    `BAN {{character}}`,
    `BAN {{character}}???`,
    `[DEFINITELY NOT MODDED] Playing mods`,
    `{{character}} is BROKEN`,
    `Reacting to the most STYLISH combos`,
  ],
  [VIDEO_VIBES.INFORMATIVE]: [
    `{{character}} combos: beginner to expert`,
    `Who Should You Main?`,
    `{{character}} is broken, and here's why`,
    `{{character}} is not broken, and here's why`,
    `New tech discovered`,
    `{{character}} 0-to-death`,
    `How to play {{character}} like a pro`,
  ],
  [VIDEO_VIBES.FUNNY]: [
    `TROLLING ONLINE with {{character}}`,
    `RAGING at online`,
    `IF I LOSE I HAVE TO DO SOMETHING STUPID`,
    `WORLD'S MOST ELITE {{character}}`,
    `Online Bingo`,
    `Playing online but BLINDFOLDED`,
    `Playing online but WITH MY HANDS TIED TOGETHER`,
    `Playing online but MY CONTROLLER IS UPSIDE DOWN`,
    `Playing online but I'M EATING WHILE PLAYING`,
    `Playing online but A BIRD IS PECKING AT ME`,
    `Playing online but I'M TERRIBAD`,
    `MY GIRLFRIEND GUESSES CHARACTER NAMES`,
    `MY MUM GUESSES CHARACTER NAMES`,
    `MY DAD GUESSES CHARACTER NAMES`,
    `MY GRAN GUESSES CHARACTER NAMES`,
    `MY GRANDAD GUESSES CHARACTER NAMES`,
    `MY UNCLE'S COUSIN'S SISTER'S WIFE GUESSES CHARACTER NAMES`,
    `{{character}} is OP!!`,
  ],
};
