import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./NewsView.scss";

class NewsView extends Component {
    state = {
        open: false,
    };

    toggle() {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        const { open } = this.state;
        const { newsItem } = this.props;

        const body = (
            <>
                <time>{newsItem.date}</time>
                {newsItem.link && (
                    <Link to={newsItem.link}>
                        <h2
                            className={open ? "open" : ""}
                            onClick={this.toggle.bind(this)}
                            dangerouslySetInnerHTML={{
                                __html: newsItem.headline,
                            }}
                        ></h2>
                    </Link>
                )}
                {!newsItem.link && (
                    <h2
                        className={open ? "open" : ""}
                        onClick={this.toggle.bind(this)}
                    >
                        {newsItem.headline}
                    </h2>
                )}
                <div
                    className={open ? "content open" : "content"}
                    dangerouslySetInnerHTML={{
                        __html: newsItem.body,
                    }}
                />
            </>
        );

        return <div className={`news-${newsItem.type}`}>{body}</div>;
    }
}

export default NewsView;
