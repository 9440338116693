import { getData } from "../util";
import { FIGHTER_TYPES } from "./Fighter";
import { MATCH_STATES } from "../data/MatchStates";
import { commentate } from "../data/Commentary";

const MODIFIER = 10;
const DEFAULT_INTERVAL = 1500;
const INTERVAL = DEFAULT_INTERVAL;

export class MatchV2R {
    constructor(playerOne, playerTwo, onFinish) {
        this.renderable = true;
        this.complete = false;
        this.showGameStart = true;
        this.showGameEnd = false;

        this.interval = INTERVAL;

        this.playerOne = playerOne;
        this.playerTwo = playerTwo;

        const fighters = getData("fighters");
        this.fighterOne = fighters.find(
            (fighter) => playerOne.main === fighter.id
        );
        this.fighterTwo = fighters.find(
            (fighter) => playerTwo.main === fighter.id
        );

        this.playerOneStocks = 3;
        this.playerTwoStocks = 3;

        this.playerOneDamage = 0;
        this.playerTwoDamage = 0;

        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.NEUTRAL;

        this.onFinish = onFinish;

        this.commentary = [];
        this.skipping = false;
    }

    skip() {
        this.skipping = true;
        this.interval = 1;
    }

    play() {
        if (this.showGameStart) {
            setTimeout(() => {
                this.showGameStart = false;
                this.play();
            }, this.interval);
        } else {
            if (
                this.playerOneState === MATCH_STATES.RESPAWNING ||
                this.playerTwoState === MATCH_STATES.RESPAWNING
            ) {
                this.commentate(MATCH_STATES.RESPAWNING);
                this.resetPhase();
            } else if (
                this.playerOneState === MATCH_STATES.RECOVERING ||
                this.playerTwoState === MATCH_STATES.RECOVERING
            ) {
                this.commentate(MATCH_STATES.RECOVERING);
                this.resetPhase();
            } else if (
                this.playerOneState === MATCH_STATES.NEUTRAL ||
                this.playerTwoState === MATCH_STATES.NEUTRAL
            ) {
                this.neutralPhase();
            } else if (
                this.playerOneState === MATCH_STATES.ADVANTAGE ||
                this.playerTwoState === MATCH_STATES.ADVANTAGE
            ) {
                this.advantagePhase();
            } else if (
                this.playerOneState === MATCH_STATES.VULNERABLE ||
                this.playerTwoState === MATCH_STATES.VULNERABLE
            ) {
                this.punishPhase();
            }
        }
    }

    commentate(phase) {
        let winningPlayer = this.playerOne;
        let winningFighter = this.fighterOne;
        let losingPlayer = this.playerTwo;
        let losingFighter = this.fighterTwo;

        if (
            (phase === MATCH_STATES.NEUTRAL &&
                this.playerOneStocks < this.playerTwoStocks) ||
            (phase === MATCH_STATES.ADVANTAGE &&
                this.playerTwoState === MATCH_STATES.ADVANTAGE) ||
            (phase === MATCH_STATES.PUNISHING &&
                this.playerTwoState === MATCH_STATES.PUNISHING) ||
            (phase === MATCH_STATES.RESPAWNING &&
                this.playerOneState === MATCH_STATES.RESPAWNING) ||
            (phase === MATCH_STATES.RECOVERING &&
                this.playerOneState === MATCH_STATES.RECOVERING)
        ) {
            winningPlayer = this.playerTwo;
            winningFighter = this.fighterTwo;
            losingPlayer = this.playerOne;
            losingFighter = this.fighterOne;
        }

        this.commentary.push(
            commentate(
                winningPlayer,
                winningFighter,
                losingPlayer,
                losingFighter,
                phase
            )
        );
    }

    resetPhase() {
        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.NEUTRAL;

        setTimeout(() => {
            this.play();
        }, this.interval);
    }

    neutralPhase() {
        this.commentate(MATCH_STATES.NEUTRAL);

        this.playerOneState = MATCH_STATES.NEUTRAL;
        this.playerTwoState = MATCH_STATES.NEUTRAL;

        let playerOneNeutral =
            Math.random() * MODIFIER +
            this.playerOne.neutral +
            this.fighterOne.neutral;
        let playerTwoNeutral =
            Math.random() * MODIFIER +
            this.playerTwo.neutral +
            this.fighterTwo.neutral;

        switch (this.fighterOne.type) {
            case FIGHTER_TYPES.RUSHDOWN:
                if (
                    this.fighterTwo.type === FIGHTER_TYPES.ZONER &&
                    Math.random() > 0.8
                ) {
                    // console.log("||P1 rushdown bonus");
                    playerOneNeutral *= 1.25;
                }
                break;
            case FIGHTER_TYPES.ZONER:
                if (
                    this.fighterTwo.type === FIGHTER_TYPES.BAIT_PUNISH &&
                    Math.random() > 0.8
                ) {
                    // console.log("||P1 zoner bonus");
                    playerOneNeutral *= 1.25;
                }
                break;
            case FIGHTER_TYPES.BAIT_PUNISH:
                if (
                    this.fighterTwo.type === FIGHTER_TYPES.RUSHDOWN &&
                    Math.random() > 0.8
                ) {
                    // console.log("||P1 bp bonus");
                    playerOneNeutral *= 1.25;
                }
                break;
        }

        switch (this.fighterTwo.type) {
            case FIGHTER_TYPES.RUSHDOWN:
                if (
                    this.fighterOne.type === FIGHTER_TYPES.ZONER &&
                    Math.random() > 0.8
                ) {
                    // console.log("||P2 rushdown bonus");
                    playerTwoNeutral *= 1.25;
                }
                break;
            case FIGHTER_TYPES.ZONER:
                if (
                    this.fighterOne.type === FIGHTER_TYPES.BAIT_PUNISH &&
                    Math.random() > 0.8
                ) {
                    // console.log("||P2 zoner bonus");
                    playerTwoNeutral *= 1.25;
                }
                break;
            case FIGHTER_TYPES.BAIT_PUNISH:
                if (
                    this.fighterOne.type === FIGHTER_TYPES.RUSHDOWN &&
                    Math.random() > 0.8
                ) {
                    // console.log("||P2 bp bonus");
                    playerTwoNeutral *= 1.25;
                }
                break;
        }

        if (playerOneNeutral > playerTwoNeutral) {
            this.playerTwoDamage += playerOneNeutral - playerTwoNeutral;
            if (
                this.fighterOne.type === FIGHTER_TYPES.BAIT_PUNISH &&
                Math.random() > 0.7
            ) {
                // console.log("||P1 bp advantage skip bonus");
                this.playerOneState = MATCH_STATES.PUNISHING;
                this.playerTwoState = MATCH_STATES.VULNERABLE;
            } else if (
                this.fighterTwo.type === FIGHTER_TYPES.ZONER &&
                Math.random() > 0.7
            ) {
                // console.log("||P2 zoner advantage skip bonus");
                this.playerOneState = MATCH_STATES.NEUTRAL;
                this.playerTwoState = MATCH_STATES.NEUTRAL;
            } else {
                this.playerOneState = MATCH_STATES.ADVANTAGE;
                this.playerTwoState = MATCH_STATES.DISADVANTAGE;
            }
        } else if (playerOneNeutral < playerTwoNeutral) {
            this.playerOneDamage += playerTwoNeutral - playerOneNeutral;
            if (
                this.fighterTwo.type === FIGHTER_TYPES.BAIT_PUNISH &&
                Math.random() > 0.7
            ) {
                // console.log("||P2 bp advantage skip bonus");
                this.playerOneState = MATCH_STATES.VULNERABLE;
                this.playerTwoState = MATCH_STATES.PUNISHING;
            } else if (
                this.fighterOne.type === FIGHTER_TYPES.ZONER &&
                Math.random() > 0.7
            ) {
                // console.log("||P1 zoner advantage skip bonus");
                this.playerOneState = MATCH_STATES.NEUTRAL;
                this.playerTwoState = MATCH_STATES.NEUTRAL;
            } else {
                this.playerOneState = MATCH_STATES.DISADVANTAGE;
                this.playerTwoState = MATCH_STATES.ADVANTAGE;
            }
        }

        setTimeout(() => {
            this.play();
        }, this.interval);
    }

    advantagePhase() {
        this.commentate(MATCH_STATES.ADVANTAGE);

        let advantage;
        if (this.playerOneState === MATCH_STATES.ADVANTAGE) {
            advantage = this.playerTwoDamage;
            advantage +=
                Math.random() *
                (this.playerOne.advantage + this.fighterOne.advantage);
            advantage -=
                Math.random() *
                (this.playerTwo.disadvantage + this.fighterTwo.disadvantage);

            if (
                this.fighterOne.type === FIGHTER_TYPES.RUSHDOWN &&
                Math.random() > 0.7
            ) {
                if (advantage < 0) advantage *= -1;
                else advantage *= 2;

                // console.log(`||P1 rushdown advantage bonus`);
            }

            this.playerTwoDamage += Math.random() * this.playerOne.advantage;
        } else if (this.playerTwoState === MATCH_STATES.ADVANTAGE) {
            advantage = this.playerOneDamage;
            advantage +=
                Math.random() *
                (this.playerTwo.advantage + this.fighterTwo.advantage);
            advantage -=
                Math.random() *
                (this.playerOne.disadvantage + this.fighterOne.disadvantage);

            if (
                this.fighterTwo.type === FIGHTER_TYPES.RUSHDOWN &&
                Math.random() > 0.7
            ) {
                if (advantage < 0) advantage *= -1;
                else advantage *= 2;

                // console.log(`||P2 rushdown advantage bonus`);
            }

            this.playerOneDamage += Math.random() * this.playerTwo.advantage;
        }

        if (advantage < 100) {
            this.playerOneState = MATCH_STATES.NEUTRAL;
            this.playerTwoState = MATCH_STATES.NEUTRAL;
        } else {
            if (this.playerOneState === MATCH_STATES.ADVANTAGE) {
                this.playerOneState = MATCH_STATES.PUNISHING;
                this.playerTwoState = MATCH_STATES.VULNERABLE;
            } else if (this.playerTwoState === MATCH_STATES.ADVANTAGE) {
                this.playerOneState = MATCH_STATES.VULNERABLE;
                this.playerTwoState = MATCH_STATES.PUNISHING;
            }
        }

        setTimeout(() => {
            this.play();
        }, this.interval);
    }

    punishPhase() {
        this.commentate(MATCH_STATES.PUNISHING);

        let punish;
        if (this.playerOneState === MATCH_STATES.VULNERABLE) {
            punish =
                Math.random() *
                (this.playerTwo.punish + this.fighterTwo.punish);
            punish -=
                Math.random() *
                (this.playerOne.technique + this.fighterOne.recovery);
        } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
            punish =
                Math.random() *
                (this.playerOne.punish + this.fighterOne.punish);
            punish -=
                Math.random() *
                (this.playerTwo.technique + this.fighterTwo.recovery);
        }

        if (punish > 0) {
            if (this.playerOneState === MATCH_STATES.VULNERABLE) {
                this.playerOneStocks--;
                this.playerOneDamage = 0;

                this.playerOneState = MATCH_STATES.RESPAWNING;
                this.playerTwoState = MATCH_STATES.NEUTRAL;
            } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
                this.playerTwoStocks--;
                this.playerTwoDamage = 0;

                this.playerOneState = MATCH_STATES.NEUTRAL;
                this.playerTwoState = MATCH_STATES.RESPAWNING;
            }

            // console.log(
            // `KO!!!! Stocks remaining: ${this.playerOneStocks}::${this.playerTwoStocks}`
            // );
        } else {
            if (this.playerOneState === MATCH_STATES.VULNERABLE) {
                this.playerOneState = MATCH_STATES.RECOVERING;
                this.playerTwoState = MATCH_STATES.NEUTRAL;
            } else if (this.playerTwoState === MATCH_STATES.VULNERABLE) {
                this.playerOneState = MATCH_STATES.NEUTRAL;
                this.playerTwoState = MATCH_STATES.RECOVERING;
            }
        }

        if (this.playerOneStocks === 0 || this.playerTwoStocks === 0) {
            if (this.playerOneStocks === 0) {
                this.playerOneState = MATCH_STATES.DEFEATED;
                this.playerTwoState = MATCH_STATES.PUNISHING;
            } else if (this.playerTwoStocks === 0) {
                this.playerTwoState = MATCH_STATES.DEFEATED;
                this.playerOneState = MATCH_STATES.PUNISHING;
            }
            this.finish();
        } else {
            setTimeout(() => {
                this.play();
            }, this.interval);
        }
    }

    finish() {
        this.showGameEnd = true;

        setTimeout(() => {
            this.complete = true;

            if (this.playerOneStocks > this.playerTwoStocks) {
                this.onFinish(this.playerOne.id, this.playerOneStocks);
            } else {
                this.onFinish(this.playerTwo.id, this.playerTwoStocks);
            }
        }, DEFAULT_INTERVAL);
    }
}
