import React, { Component } from "react";
import Button from "../../components/button/Button";
import PageHeader from "../../components/page-header/PageHeader";

import "./Wiki.scss";

class Wiki extends Component {
  state = {
    contentsOpen: false,
  };

  constructor(props) {
    super(props);

    this.introRef = React.createRef();
    this.objectiveRef = React.createRef();
    this.timeRef = React.createRef();
    this.actionsRef = React.createRef();
    this.matchesRef = React.createRef();
    this.fundamentalsRef = React.createRef();
    this.archetypeRef = React.createRef();
    this.rushdownRef = React.createRef();
    this.zonerRef = React.createRef();
    this.baitPunishRef = React.createRef();
    this.dynamicRef = React.createRef();
    this.strategiesRef = React.createRef();
    this.abilitiesRef = React.createRef();
    this.passiveAbilitiesRef = React.createRef();
    this.activeAbilitiesRef = React.createRef();
    this.edgeguardingRef = React.createRef();
    this.expertiseRef = React.createRef();
    this.hypeRef = React.createRef();
    this.aiPlayersRef = React.createRef();
    this.zeroPlayerRef = React.createRef();
    this.tournamentRef = React.createRef();
    this.friendliesRef = React.createRef();
    this.playTubeRef = React.createRef();
    this.watchingStreamsRef = React.createRef();
    this.sponsorsRef = React.createRef();
    this.seasonEndRef = React.createRef();
    this.changingMainsRef = React.createRef();
    this.balancePatchRef = React.createRef();
    this.dlcRef = React.createRef();
    this.settingsRef = React.createRef();
    this.settingsDatabaseRef = React.createRef();
    this.settingsMatchEngineRef = React.createRef();
    this.settingsMatchEngineV1Ref = React.createRef();
    this.settingsMatchEngineV2XRef = React.createRef();
    this.settingsMatchEngineV3XRef = React.createRef();
    this.settingsStatModeRef = React.createRef();
    this.settingsBalancePatchPolicyRef = React.createRef();
    this.settingsDlcPolicyRef = React.createRef();
  }

  scrollToRef(ref) {
    document.querySelector("html").scrollTo(0, ref.current.offsetTop - 60);
  }

  toggleContents() {
    this.setState({
      contentsOpen: !this.state.contentsOpen,
    });
  }

  render() {
    const { contentsOpen } = this.state;

    return (
      <section className="wiki">
        <aside className={contentsOpen ? "open" : ""}>
          <Button onClick={this.toggleContents.bind(this)}>
            {contentsOpen ? "Close" : "Contents"}
          </Button>
          <ul>
            <li onClick={this.scrollToRef.bind(this, this.introRef)}>
              Introduction
            </li>
            <ul>
              <li onClick={this.scrollToRef.bind(this, this.objectiveRef)}>
                Objective of the game
              </li>
              <li onClick={this.scrollToRef.bind(this, this.timeRef)}>
                The passage of time
              </li>

              <li onClick={this.scrollToRef.bind(this, this.actionsRef)}>
                Weekly actions
              </li>
              <li onClick={this.scrollToRef.bind(this, this.seasonEndRef)}>
                End of season
              </li>
              <ul>
                <li onClick={this.scrollToRef.bind(this, this.dlcRef)}>
                  Downloadable content
                </li>
                <li onClick={this.scrollToRef.bind(this, this.balancePatchRef)}>
                  Balance patches
                </li>
                <li onClick={this.scrollToRef.bind(this, this.statPointsRef)}>
                  Assigning stat points
                </li>
                <li
                  onClick={this.scrollToRef.bind(this, this.changingMainsRef)}
                >
                  Changing mains
                </li>
              </ul>
            </ul>
            <li onClick={this.scrollToRef.bind(this, this.matchesRef)}>
              Matches
            </li>
            <ul>
              <li onClick={this.scrollToRef.bind(this, this.fundamentalsRef)}>
                Fundamentals
              </li>
              <li onClick={this.scrollToRef.bind(this, this.archetypeRef)}>
                Character archetypes
              </li>
              <ul>
                <li onClick={this.scrollToRef.bind(this, this.rushdownRef)}>
                  Rushdown
                </li>
                <li onClick={this.scrollToRef.bind(this, this.zonerRef)}>
                  Zoner
                </li>
                <li onClick={this.scrollToRef.bind(this, this.baitPunishRef)}>
                  Bait and Punish
                </li>
                <li onClick={this.scrollToRef.bind(this, this.dynamicRef)}>
                  Dynamic
                </li>
              </ul>
              <li onClick={this.scrollToRef.bind(this, this.strategiesRef)}>
                Strategies
              </li>
              <li onClick={this.scrollToRef.bind(this, this.abilitiesRef)}>
                Abilities
              </li>
              <ul>
                <li
                  onClick={this.scrollToRef.bind(
                    this,
                    this.passiveAbilitiesRef
                  )}
                >
                  Passive
                </li>
                <li
                  onClick={this.scrollToRef.bind(this, this.activeAbilitiesRef)}
                >
                  Active
                </li>
              </ul>
              <li onClick={this.scrollToRef.bind(this, this.edgeguardingRef)}>
                Edgeguarding
              </li>
              <li onClick={this.scrollToRef.bind(this, this.expertiseRef)}>
                Character familiarity
              </li>
              <li onClick={this.scrollToRef.bind(this, this.hypeRef)}>Hype</li>
            </ul>
            <li onClick={this.scrollToRef.bind(this, this.tournamentRef)}>
              Tournaments
            </li>
            <li onClick={this.scrollToRef.bind(this, this.friendliesRef)}>
              Friendlies
            </li>
            <li onClick={this.scrollToRef.bind(this, this.playTubeRef)}>
              PlayTube
            </li>
            <ul>
              <li
                onClick={this.scrollToRef.bind(this, this.watchingStreamsRef)}
              >
                Watching streams
              </li>
            </ul>
            <li onClick={this.scrollToRef.bind(this, this.sponsorsRef)}>
              Sponsors
            </li>
            <li onClick={this.scrollToRef.bind(this, this.aiPlayersRef)}>
              AI Players
            </li>
            <ul>
              <li onClick={this.scrollToRef.bind(this, this.zeroPlayerRef)}>
                Zero-Player Mode
              </li>
            </ul>
            <li onClick={this.scrollToRef.bind(this, this.settingsRef)}>
              Game Settings
            </li>
            <ul>
              <li
                onClick={this.scrollToRef.bind(this, this.settingsDatabaseRef)}
              >
                Database
              </li>
              <li
                onClick={this.scrollToRef.bind(
                  this,
                  this.settingsMatchEngineRef
                )}
              >
                Match engine
              </li>
              <ul>
                <li
                  onClick={this.scrollToRef.bind(
                    this,
                    this.settingsMatchEngineV1Ref
                  )}
                >
                  V1
                </li>
                <li
                  onClick={this.scrollToRef.bind(
                    this,
                    this.settingsMatchEngineV2XRef
                  )}
                >
                  V2X
                </li>
                <li
                  onClick={this.scrollToRef.bind(
                    this,
                    this.settingsMatchEngineV3XRef
                  )}
                >
                  V3X
                </li>
              </ul>
              <li
                onClick={this.scrollToRef.bind(this, this.settingsStatModeRef)}
              >
                Stat mode
              </li>
              <li
                onClick={this.scrollToRef.bind(
                  this,
                  this.settingsBalancePatchPolicyRef
                )}
              >
                Balance patch policy
              </li>
              <li
                onClick={this.scrollToRef.bind(this, this.settingsDlcPolicyRef)}
              >
                DLC policy
              </li>
            </ul>
          </ul>
        </aside>
        <article>
          <PageHeader>How To Play</PageHeader>
          <p>
            This page lists all of the information you may need to know for how
            to play 20XX Smash Manager.
          </p>
          <h2 ref={this.introRef}>Introduction</h2>
          <h3 ref={this.objectiveRef}>Objective of the game</h3>
          <p>
            The main objective of the game is to become the best smasher in the
            world. As 20XX develops and evolves, there will be more than one way
            of achieving this goal, from getting over 1 million subscribers as a
            content creator, to topping the character pyramid.
          </p>
          <p>
            As of version 0.2.7-alpha, the main objective is to become the #1
            ranked professional player in the world, and the best way to acheive
            that is by winning the global Smash XL Worldwide tournament.
          </p>
          <h3 ref={this.timeRef}>The passage of time</h3>
          <p>
            The passage of time in 20XX is divided into seasons, months and
            weeks. A season is largely self-contained. There are three months to
            a season and five weeks to a month.
          </p>
          <p>
            At the beginning of each season you will be given the opportunity to
            choose a fighter to main for the upcoming season. This fighter is
            the fighter you will be associated with until the next season, with
            the exception of friendlies, so choose wisely. You will also be
            given the opportunity to use any skill points you have obtained over
            the course of the season.
          </p>
          <h3 ref={this.actionsRef}>Weekly actions</h3>
          <p>
            Each week you have the opportunity to perform an action that
            increases your standing in some fashion. These include taking part
            in tournaments, watching other player's streams, or playing
            friendlies with your network.
          </p>
          <p>
            Once per month you are required by the Smash XL Association to
            participate in your regional tournament, which consists of every
            player in your region. You will not be able to perform any other
            actions in this week.
          </p>
          <p>
            The final week of the season sees the top 8 seeds from each region
            qualify to the most prestigious tournament of all - Smash XL
            Worldwide. Winning this crowns you World Champion for that season.
          </p>
          <h3 ref={this.seasonEndRef}>End of season</h3>
          <p>
            At the end of each season there a number of single-time actions
            available to you.
          </p>
          <h4 ref={this.dlcRef}>Downloadable content</h4>
          <p>
            If your DLC setting is not "No DLC", one new character will be
            available for selection at the end of every season. Depending on the
            setting you choose, they will either release in the same order as
            real life, a different order, or the whole database can be shuffled
            so that some of the base roster will be released as DLC, and DLC
            characters will be in the base roster.
          </p>
          <p>See the settings help screen for more details.</p>
          <h4 ref={this.balancePatchRef}>Balance patching</h4>
          <p>
            At the end of each season a balance patch will be applied that
            changes the statstics of characters according to the balance patch
            policy chosen at the start of the game.
          </p>
          <p>
            Please note that if you are playing in Immersive stat mode you will
            be able to see when a character has been nerfed or buffed, but not
            what the actual stat changes are until the completion of the next
            season.
          </p>
          <h4 ref={this.statPointsRef}>Assigning stat points</h4>
          <p>
            At the end of each sesson you are given the opportunity to assign
            any stat points you have accrued over the course of the season. This
            consists of one base point per month, plus any other you accrue from
            weekly actions.
          </p>
          <h4 ref={this.changingMainsRef}>Changing mains</h4>
          <p>
            Perhaps your main has been nerfed. Maybe a buffed character or the
            new DLC interests you. Maybe you just suck with the character you
            have been using thus far.
          </p>
          <p>
            At the end of the season you will be given the opportunity to choose
            a new main for the next season. You may choose to remain with the
            same main or peruse the data to find another you may have more
            success with or enjoy more.
          </p>
          <h2 ref={this.matchesRef}>Matches</h2>
          <h3 ref={this.fundamentalsRef}>Fundamentals</h3>
          <p>A match consists of the following phases:</p>
          <ul>
            <li>
              Neutral: a state in which the competitors attempt to seize stage
              control.
            </li>
            <li>
              Advantage/disadvantage: when one player has won neutral, that puts
              them into advantage state, and correspondingly, the player that
              lost neutral enters disadvantage state.
            </li>
            <li>
              Punish: an attempt by a player to send the other player off stage.
            </li>
            <li>
              Recovery/edgeguard: an attempt by the player knocked off stage to
              return whislt avoiding attempts by the opponent to stop this from
              happening.
            </li>
          </ul>
          <p>
            With each neutral and advantage phase, damage is dealt. The higher
            the damage, the more likely the punish phase is to be successful.
          </p>
          <p>
            Each phase is dictated by the relevant stats for each fighter and
            player. As of match engine V2/V2R, there is one stat that
            corresponds to each phase. Over time, more statistics will be added.
          </p>

          <h3 ref={this.archetypeRef}>Character Archetypes</h3>
          <img src={`${process.env.PUBLIC_URL}/rps_diagram.png`} />
          <p>There are three main character archetypes:</p>
          <ul>
            <li>
              Rushdown{" "}
              <img
                src={`${process.env.PUBLIC_URL}/rushdown.png`}
                className="icon"
              />
            </li>
            <li>
              Zoner{" "}
              <img
                src={`${process.env.PUBLIC_URL}/zoner.png`}
                className="icon"
              />
            </li>
            <li>
              Bait and punish{" "}
              <img
                src={`${process.env.PUBLIC_URL}/bait.png`}
                className="icon"
              />
            </li>
          </ul>
          <p>
            Every fighter is assigned to one of these archetypes*. It works on a
            classic Rock-Paper-Scissors system, wherein the dominant type gets a
            small bonus in the neutral game against the recessive. Each
            archetype also has its own bonuses, as outlined below.
          </p>
          <p>
            You can identify when a character bonus is activated because they
            will glow slightly in the battle scene.
          </p>
          <p>
            <em>
              *N.B. This is a gross oversimplification of character archetypes
              within the real Smash Bros., and as such not all characters fit
              perfectly into one archetype.
            </em>
          </p>
          <h4 ref={this.rushdownRef}>
            Rushdown{" "}
            <img
              src={`${process.env.PUBLIC_URL}/rushdown.png`}
              className="icon"
            />
          </h4>
          <p>
            Rushdown characters' gameplan is based around winning the neutral
            phase as often as possible. As a result they tend to have higher
            base stats in neutral and advantage, but lower in punish and
            disadvantage.
          </p>
          <p>
            Rushdown's unique bonus is a chance to double the damage they do in
            the advantage state.
          </p>
          <h4 ref={this.zonerRef}>
            Zoner{" "}
            <img src={`${process.env.PUBLIC_URL}/zoner.png`} className="icon" />
          </h4>
          <p>
            Zoner characters rely on a strong defensive game and from stopping
            an opponent pressing an advantage if they have lost the neutral.
            This leads to Zoners having average neutral and advantage stats, but
            higher than average disadvantage stats.
          </p>
          <p>
            Zoner's unique bonus is they can occasionally break out of being in
            a disadvantage state.*
          </p>
          <p>
            <em>
              *N.B. In match engine V2X, Zoners have proven to be too weak, so
              the bonus has been changed in V3X, so that when a Zoner breaks
              disadvantage, they themselves get put into an advantage state.
            </em>
          </p>
          <h4 ref={this.baitPunishRef}>
            Bait and Punish{" "}
            <img src={`${process.env.PUBLIC_URL}/bait.png`} className="icon" />
          </h4>
          <p>
            Bait and Punish characters assume that they are going to lose the
            neutral phase more often than not, so they instead rely on a much
            stronger advantage and punish game, so that the few hits they do get
            in can really pack a punch.
          </p>
          <p>
            Bait and Punish's unique bonus is that they can occasionally skip
            straight to the punish phase out of neutral, giving them an
            opportunity for early KOs.*
          </p>
          <p>
            <em>
              *N.B. In match engine V2X, although the bonus itself works well,
              it works a little too well due to recovery being slightly
              underpowered in the match engine, which led to a refreshed punish
              phase in V3X.
            </em>
          </p>
          <h4 ref={this.dynamicRef}>
            Dynamic{" "}
            <img
              src={`${process.env.PUBLIC_URL}/dynamic.png`}
              className="icon"
            />
          </h4>
          <p>
            Dynamic characters are characters that can switch between different
            archetypes depending on the form they are in.
          </p>

          <h3 ref={this.strategiesRef}>Strategies</h3>
          <p>
            During the neutral phase your fighter will adopt a certain strategy.
            There are four universal strategies and their effectiveness is
            defined by different attributes of your chosen fighter. Some
            strategies provide a small dominance bonus over others. Strategies
            are available in match engine V3X onwards.
          </p>
          <ul>
            <li>
              Counter - Your fighter lies in wait, waiting for the perfect time
              to strike. Effectiveness is defined by the Out of Shield stat.
              Strong against pressure strategies
            </li>
            <li>
              Pressure - Your fighter will continually apply pressure by moving
              around the stage and trying to confuse your opponent.
              Effectiveness defined by the Mobility stat. Strong against
              projectile campers.
            </li>
            <li>
              Campy - Your fighter will throw out projectiles and run away from
              confrontation. Effectiveness is defined by the Projectiles stat.
              This strategy is unavailable to characters without projectiles.
              Strong against counter strategies.
            </li>
            <li>Balanced - A mixture of all other strategies.</li>
          </ul>
          <p>
            Some characters have abilities that provide them with unique
            strategies.
          </p>

          <h3 ref={this.abilitiesRef}>Abilities</h3>
          <p>
            Many characters have unique abilities which can give them a boost in
            battle. These abilities can be either active or passive. Abilities
            are available in match engine V3X onwards.
          </p>

          <h4 ref={this.activeAbilitiesRef}>Passive</h4>
          <p>
            A passive ability is continuously active in the background without
            intervention from the player. These usually come in the form of stat
            boosts or comeback mechanics.
          </p>

          <h4 ref={this.passiveAbilitiesRef}>Active</h4>
          <p>
            An active ability is one that the player needs to activate in order
            to use. This can be a form change, unique attack or a unique
            strategy.
          </p>

          <h3 ref={this.edgeguardingRef}>Edgeguarding</h3>
          <p>
            As of match engine V3X, the recovery phase has been extended to
            include edgeguarding. This gives a player an opportunity to close
            out a stock without having to reset to neutral.
          </p>
          <p>
            However, there is a risk to edgeguarding. If you fail, you may
            self-destruct and use your own stock. An edgeguard's success rate is
            determined by your recovery and technique as opposed to your
            opponent's, as well as how risky your edgeguard is.
          </p>
          <p>
            Every character has high-risk and low-risk edgeguarding options, and
            some characters have specialised options. Higher risk edgeguarding
            strategies tend to have a higher success rate of KO'ing your
            opponent but also a higher chance of self-destructing along with it.
            It is recommended that edgeguarding is only undertaken when you are
            up a stock or when you are fighting a character that has a poor
            recovery in order to maximise success rate.
          </p>
          <p>
            A successful edgeguard will improve your player's hype rating, and
            the riskier the better.*
            <em>*Not yet implemented.</em>
          </p>

          <h3 ref={this.expertiseRef}>Character familiarity</h3>
          <p>
            As you play, you will gain some experience with the character you
            are maining as well as a smaller measure of experience of your
            opponent. This experience is factored in to how well you can play as
            or against these characters in future matches.
          </p>

          <h3 ref={this.hypeRef}>Hype</h3>
          <p>
            Everyone loves a hype player. If you play a hype character, high
            pressure gameplay and risky edgeguarding, your hype rating will
            improve and you may have the opportunity to be picked up by a
            sponsor. Conversely, if you play a lame character, spend all your
            time camping, refuse to edgeguard or even worse - use ledgetrapping
            edgeguard techniques, you may win some matches, but lose respect!
          </p>

          <h2 ref={this.tournamentRef}>Tournaments</h2>
          <p>
            All tournaments are presently single elimination. Competing in any
            tournament increases your seed based on how well you perform. You
            get more seed points for winning with more stocks remaining. The
            seed increase is significantly more if you make it top the top 8 of
            a tournament, and each tournament can earn you a different number of
            seed points based on how prestigious it is.
          </p>

          <h2 ref={this.friendliesRef}>Friendlies</h2>
          <p>
            Friendlies give you the opportunity to play against other players
            without the stress of tournament conditions. They are a great way to
            gain experience towards additional skill points and get familiarity
            with both yours and your opponent's characters.
          </p>

          <h2 ref={this.playTubeRef}>PlayTube</h2>
          <p>
            PlayTube is the official SmashXL streaming service, exclusively for
            top players. Choose a vibe - either chill out with your subscribers,
            deep dive into an informative video to gain experience or exploit
            the masses for some cash with a funny stream.
          </p>

          <h3 ref={this.watchingStreamsRef}>Watching streams</h3>
          <p>
            Don't want the pressure of running a stream? Sometimes you can take
            more away from just watching a stream, especially studying specific
            characters to get in-depth familiarty with them.
          </p>

          <h2 ref={this.sponsorsRef}>Sponsors</h2>
          <p>
            Play well enough, be exciting enough, or produce enough great
            content, and you will soon attract the attention of sponsors.
            Sponsors will give you a monthly salary with which you can pad out
            your wallet.
          </p>
          <p>
            Different sponsors value different things, so be sure to check out
            what each sponsor wants from their roster, otherwise you could end
            up losing your sponsorship.
          </p>

          <h2 ref={this.aiPlayersRef}>AI Players</h2>
          <p>
            You can follow AI players you are interested in to get a lowdown on
            their results, in the form of reaction tweets.
          </p>
          <h3 ref={this.zeroPlayerRef}>Zero-Player Mode</h3>
          <p>
            You can follow AI players you are interested in to get a lowdown on
            their results, in the form of reaction tweets.
          </p>
          <h2 ref={this.settingsRef}>Settings</h2>
          <p>
            When you begin a new save, you can choose how you want to play
            through the use of various settings. These cannot be changed once
            the game has begun.
          </p>

          <h3 ref={this.settingsDatabaseRef}>Database</h3>
          <p>
            This is the dataset of characters available to play as and contains
            information relating to their abilities and stats. At the time of
            writing only Super Smash Bros. Ultimate is available but there are
            plans to add more later.
          </p>

          <h3 ref={this.settingsMatchEngineRef}>Match engine</h3>
          <p>
            You can switch between match engine versions for your playthrough,
            if you want to experiment with different rulesets. The highest
            numbered version will always be the most featureful and recommended,
            however.
          </p>
          <p>
            Please note that single player mode requires a renderer enabled
            version of the engine, marked as VXR. If you want to play solely
            non-rendered you must play the Zero-player simulation mode.
          </p>

          <h4 ref={this.settingsMatchEngineV1Ref}>V1</h4>
          <p>
            Version 1 of the match engine follows the basic phases of play using
            one base stat per fighter and per player for each corresponding
            phase. There are no character archetypes and recovery phase is as
            simple as you make it back or you are insta KOd.
          </p>
          <p>
            This is the rawest form of the match engine and as such neutral is
            king in this version. This version is only available in Zero-player
            sim for interest only, as it does not have a rendering engine
            assigned.
          </p>
          <h4 ref={this.settingsMatchEngineV2XRef}>V2X</h4>
          <p>
            Version 2 and 2R introduce the base 3 character archetypes of
            Rushdown, Zoner and Bait and Punish charcaters. There are also a
            number of reworks to the neutral game to try and negate the
            overpowering effect of a strong neutral game.
          </p>
          <p>
            As there is a renderer for this version (V2R) this is the first
            match engine available in single player mode.
          </p>

          <h4 ref={this.settingsMatchEngineV3XRef}>V3X</h4>
          <p>
            Version 3 and 3R introduces a lot of the features intended to give
            players an influence on the outcome of matches. This includes:
          </p>
          <ul>
            <li>Strategies</li>
            <li>Abilities</li>
            <li>Edgeguarding</li>
          </ul>
          <p>
            New stats have been added to each fighter, including Projectiles,
            Out of Shield, Mobility and Weight. In addition to this, the punish
            phase has been effectively rewritten and damage has been made more
            consistent across all phases, and character familiarity has been
            introduced.
          </p>

          <h3 ref={this.settingsStatModeRef}>Stat mode</h3>
          <p>
            There are two different variations of stat mode: Immersive/Open and
            Default/Randomised. These two variations can be combined.
          </p>
          <p>
            Immersive mode hides character stats to make the experience more of
            a challenge. At the end of each season a tier list and report for
            each character is generated which will indicate performance and a
            graded score for each stat for the season just gone. You can see
            when a character has been buffed or nerfed, but not the specifics.
            In Open mode you can see the stats in their raw numeric form and
            they update in real time.
          </p>
          <p>
            In Default mode the stats are taken from the database. In Randomised
            mode, who knows what you are going to get!
          </p>
          <h3 ref={this.settingsBalancePatchPolicyRef}>Balance patch policy</h3>
          <p>
            This defines the nature of the balance patches that happen between
            seasons. At present there is either a sensible balance patch policy
            where the highest performers are nerfed and the lowest buffed, but
            if you're a bit unhinged, you can choose the Chaotic option which
            completely randomises which characters receive buffs or nerfs.
          </p>
          <h3 ref={this.settingsDlcPolicyRef}>DLC policy</h3>
          <p>
            This defines the chracters available as downloadable content and the
            order which they are released in, if at all. You can follow the
            order defined in the database, which follows the same as real life,
            keep the same characters but shuffle the order, or completely
            shuffle the database so that random characters will be DLC.
          </p>
        </article>
      </section>
    );
  }
}

export default Wiki;
