import React, { Component } from "react";
import Button from "../../components/button/Button";

import PageHeader from "../../components/page-header/PageHeader";

import "./Credits.scss";

class Credits extends Component {
  render() {
    return (
      <div className="credits">
        <Button
          primary
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </Button>
        <PageHeader>Credits</PageHeader>

        <h2>Game design and development</h2>

        <ul>
          <li>Declan Tyson,</li>
          <li>Retroparadise Games</li>
        </ul>

        <h2>Original concept</h2>

        <ul>
          <li>Declan Tyson,</li>
          <li>Retroparadise Games</li>
        </ul>

        <h2>Community contributors</h2>

        <ul>
          <li>Richard Twena</li>
          <li>swisscheeseisvile</li>
          <li>Hufff</li>
          <li>ToT3344</li>
          <li>TheMemecromancer</li>
        </ul>

        <ul>
          <li>
            Super Smash Bros. Ultimate and all of the franchises depicted within
            are not claimed by Retroparadise Games as its own.
          </li>
        </ul>
      </div>
    );
  }
}

export default Credits;
