import React, { Component } from "react";
import MatchView from "../match-view/MatchView";

import "./RoundView.scss";

class RoundView extends Component {
    render() {
        const { round, players, fighters } = this.props;

        return (
            <div className="round">
                {round.find(
                    (match) => match.scoreA !== null && match.scoreB !== null
                ) && (
                    <>
                        <p>{round[0].name}</p>
                        {round.map((roundMatch) => {
                            const competitorA = players.find(
                                (player) => player.id === roundMatch.competitorA
                            );
                            const competitorB = players.find(
                                (player) => player.id === roundMatch.competitorB
                            );

                            const fighterA = competitorA
                                ? fighters.find(
                                      (fighter) =>
                                          competitorA.main === fighter.id
                                  )
                                : null;
                            const fighterB = competitorB
                                ? fighters.find(
                                      (fighter) =>
                                          competitorB.main === fighter.id
                                  )
                                : null;

                            if (
                                roundMatch.scoreA === null &&
                                roundMatch.scoreB === null
                            )
                                return null;

                            return (
                                <MatchView
                                    roundMatch={roundMatch}
                                    fighterA={fighterA}
                                    fighterB={fighterB}
                                    competitorA={competitorA}
                                    competitorB={competitorB}
                                />
                            );
                        })}
                    </>
                )}
            </div>
        );
    }
}

export default RoundView;
