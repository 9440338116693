import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Button from "../../components/button/Button";
import Dropdown from "../../components/dropdown/Dropdown";
import CharacterSelectScreen from "../../components/character-select-screen/CharacterSelectScreen";
import PageHeader from "../../components/page-header/PageHeader";
import StatAdder from "../../components/stat-adder/StatAdder";

import { FriendlyTournament } from "../../classes/FriendlyTournament";

import { getData, setData } from "../../util";

import "./Actions.scss";
import Video from "../../classes/Video";
import { VIDEO_VIBES } from "../../data/Video";
import NewsItem from "../../classes/NewsItem";
import { charIcons, charRenders } from "../../data/assets";
import Player, { boostCharacterFamiliarity } from "../../classes/Player";
import HelpModal from "../../components/help-modal/HelpModal";

const ACTIONS = {
  FRIENDLIES: "Friendlies",
  TOURNAMENT: "Tournament",
  STREAM: "Stream",
  WATCH_STREAM: "Watch stream",
  COMBO_VIDEO: "Combo Video",
  MEME_VIDEO: "Meme Video",
  WATCH_VIDEO: "Watch Video",
  NOTHING: "Nothing",
};

class Actions extends Component {
  state = {
    redirect: false,
    requireMainChange: false,
    mainConfirm: false,
    mainConfirmWindowOpen: false,
    mainSwitch: null,
    pointsRemaining: 0,
    statChanges: {
      neutral: 1,
      advantage: 1,
      disadvantage: 1,
      punish: 1,
      technique: 1,
    },
    selectedFriendly: null,
    selectedStreamer: null,
    streamVibe: VIDEO_VIBES.CHILL,
  };

  componentDidMount() {
    const { game } = this.props;

    const time = getData("time");
    const humanPlayer = getData("humanPlayer");
    const requireMainChange =
      time.season > 1 && time.month === 1 && time.week === 1;

    let pointsRemaining = 0;
    if (requireMainChange) {
      pointsRemaining = Math.floor(humanPlayer.experience / 25) + 3;
    }

    this.setState({
      requireMainChange,
      pointsRemaining,
      statChanges: {
        neutral: humanPlayer.neutral,
        advantage: humanPlayer.advantage,
        disadvantage: humanPlayer.disadvantage,
        punish: humanPlayer.punish,
        technique: humanPlayer.technique,
      },
      selectedFriendly: game.lookingForFriendlies[0],
      selectedStreamer: game.streamingPlayers[0],
    });
  }

  changeMain(main) {
    this.setState({
      mainSwitch: main,
    });
  }

  closeMainConfirmWindow() {
    this.setState({
      mainConfirmWindowOpen: false,
    });
  }

  confirmMainChange() {
    const { game } = this.props;
    const { mainSwitch, statChanges } = this.state;

    const humanPlayer = getData("humanPlayer");
    const players = getData("players");
    const playerRecord = players.find((p) => p.id === humanPlayer.id);

    if (mainSwitch !== null) {
      humanPlayer.main = mainSwitch.id;
      humanPlayer.history[humanPlayer.history.length - 1].main = mainSwitch.id;

      playerRecord.main = mainSwitch.id;
      playerRecord.history[playerRecord.history.length - 1].main =
        mainSwitch.id;
    }

    humanPlayer.neutral = statChanges.neutral;
    humanPlayer.advantage = statChanges.advantage;
    humanPlayer.disadvantage = statChanges.disadvantage;
    humanPlayer.punish = statChanges.punish;
    humanPlayer.technique = statChanges.technique;
    humanPlayer.experience = 0;

    playerRecord.neutral = statChanges.neutral;
    playerRecord.advantage = statChanges.advantage;
    playerRecord.disadvantage = statChanges.disadvantage;
    playerRecord.punish = statChanges.punish;
    playerRecord.technique = statChanges.technique;
    playerRecord.experience = 0;

    setData("humanPlayer", humanPlayer);
    setData("players", players);

    const availableTournaments = game.tournamentsInProgress;
    availableTournaments.forEach((tournament) => {
      const humanInTournament = tournament.players.find(
        (tp) => tp.id === humanPlayer.id
      );
      if (humanInTournament) {
        humanInTournament.main = humanPlayer.main;

        humanInTournament.neutral = humanPlayer.neutral;
        humanInTournament.advantage = humanPlayer.advantage;
        humanInTournament.disadvantage = humanPlayer.disadvantage;
        humanInTournament.punish = humanPlayer.punish;
        humanInTournament.technique = humanPlayer.technique;
      }
    });

    this.setState({
      mainConfirm: true,
      pointsRemaining: 0,
    });

    this.proceed(ACTIONS.TOURNAMENT, true);
  }

  stream(humanStreamer = false) {
    const { game } = this.props;
    const { selectedStreamer, streamVibe } = this.state;

    const humanPlayer = getData("humanPlayer");
    const players = getData("players");
    const humanPlayerRecord = players.find(
      (player) => player.id === humanPlayer.id
    );

    const streamerRecord = players.find(
      (player) => player.id === selectedStreamer.id
    );

    const streamer = humanStreamer ? humanPlayerRecord : streamerRecord;
    const playTubeHistoryKey = humanStreamer
      ? "playTubeUploadHistory"
      : "playTubeWatchHistory";
    const playTubeHistory = getData(playTubeHistoryKey);

    const video = new Video(streamer, humanStreamer ? streamVibe : null);

    if (humanStreamer) {
      setData("humanPlayer", streamer);
      const time = getData("time");
      const timeString = `S${time ? time.season : ""} M${
        time ? time.month : ""
      } W${time ? time.week : ""}`;
      const videoReport = new NewsItem(
        `Statistics for your latest stream`,
        `<div class="video-thumbnail">
          <div class="video-thumbnail-image">
            <img src="/video_thumbs/${
              video.thumbnail.background
            }.jpg" class="background" />
            <img src="${streamer.avatar}" style="left: ${
          video.thumbnail.streamerPositionX
        }%;top: ${video.thumbnail.streamerPositionY}%; transform:rotate(${
          video.thumbnail.streamerRotation
        }deg) translateX(-50%) translateY(-50%);border-radius: 50%;" /> 
            <img src="${charRenders[video.fighter.name]}" style="left: ${
          video.thumbnail.characterPositionX
        }%;top: ${video.thumbnail.characterPositionY}%;transform: rotate(${
          video.thumbnail.characterRotation
        }deg) translateX(-50%) translateY(-50%);" /> 
          </div>
          <p>
            <strong>${video.title}</strong>
          </p>
          <div class="stream-details">
            <p><img src="${streamer.avatar}" class="avatar" /> ${
          streamer.name
        }</p>
            <div class="quality">
              <span class="template light"></span>
              <span
                class="quality-rating"
                style="width: ${(100 / 5) * video.quality}%"
              ></span>
            </div>
          </div>
        </div>
        <p>Your latest stream generated $${(video.profit / 10).toFixed(
          2
        )} in profit, capping out at ${video.views} views.</p>`,
        timeString,
        "stream"
      );
      const news = getData("news");
      news.unshift(videoReport);
      setData("news", news);
    } else {
      let xpBoost = video.vibe === VIDEO_VIBES.INFORMATIVE ? 5 : 2;
      let familiarityBoost = video.vibe === VIDEO_VIBES.INFORMATIVE ? 2 : 0.5;

      humanPlayerRecord.experience += xpBoost;
      boostCharacterFamiliarity(
        humanPlayerRecord,
        video.fighter.id,
        familiarityBoost
      );

      setData("humanPlayer", humanPlayerRecord);
    }

    playTubeHistory.unshift(video);
    setData("players", players);
    setData(playTubeHistoryKey, playTubeHistory);

    game.readyToProceed = false;
    setTimeout(() => {
      game.playTournaments();
    }, 500);

    this.setState({
      redirect: true,
    });
  }

  proceed(action, override = false) {
    const { game } = this.props;
    const { mainConfirm, requireMainChange, pointsRemaining } = this.state;

    const players = getData("players");
    const fighters = getData("fighters");

    if (
      (requireMainChange && !mainConfirm && !override) ||
      pointsRemaining > 0
    ) {
      this.setState({
        mainConfirmWindowOpen: true,
      });

      return;
    }

    const friendlies = new FriendlyTournament(
      game.lookingForFriendlies,
      () => {
        game.checkIfTournamentsFinished();
      },
      game
    );

    game.tournamentsInProgress.push(friendlies);

    game.videos = game.streamingPlayers.map((player) => {
      const video = new Video(player);
      const playerRecord = players.find((p) => p.id === player.id);
      playerRecord.entertainment = video.streamer.entertainment;
      playerRecord.subscribers = video.streamer.subscribers;
      playerRecord.finance = video.streamer.finance;
      return video;
    });

    const humanPlayer = getData("humanPlayer");
    players.forEach((player) => {
      const isStreaming = game.streamingPlayers.find(
        (streamer) => streamer.id === player.id
      );
      if (!isStreaming) {
        if (player.id === humanPlayer.id)
          if (action === ACTIONS.STREAM) return;
          else
            humanPlayer.subscribers = Math.floor(
              humanPlayer.subscribers / 0.95
            );

        player.subscribers = Math.floor(player.subscribers * 0.95);
      }
    });

    setData("players", players);
    setData("humanPlayer", humanPlayer);

    switch (action) {
      case ACTIONS.FRIENDLIES:
        this.playFriendlies();
        break;
      case ACTIONS.TOURNAMENT:
        this.enterTournament();
        break;
      case ACTIONS.WATCH_STREAM:
        this.stream(false);
        break;
      case ACTIONS.STREAM:
        this.stream(true);
        break;
      case ACTIONS.NOTHING:
        game.proceedGameweek();
        break;
    }
  }

  enterTournament() {
    const { game } = this.props;

    if (game.tournamentsInProgress.length > 0) {
      game.readyToProceed = false;
      setTimeout(() => {
        game.playTournaments();
      }, 500);
    } else {
      game.proceedGameweek();
    }

    this.setState({
      redirect: true,
    });
  }

  playFriendlies() {
    const { game } = this.props;
    const { selectedFriendly } = this.state;

    const friendlies = game.tournamentsInProgress.find(
      (t) => t.region === 10000
    );
    friendlies.injectHumanPlayerAgainstOpponent(selectedFriendly);

    game.readyToProceed = false;
    setTimeout(() => {
      game.playTournaments();
    }, 500);

    this.setState({
      redirect: true,
    });
  }

  updatePlayerStats(stat, value, pointsUsed) {
    const { pointsRemaining, statChanges } = this.state;
    this.setState({
      pointsRemaining: pointsRemaining - pointsUsed,
      statChanges: {
        ...statChanges,
        [stat]: value,
      },
    });
  }

  changeFriendlyOpponent(value) {
    const { game } = this.props;

    const player = game.lookingForFriendlies.find(
      (p) => p.id === parseInt(value)
    );

    this.setState({
      selectedFriendly: player,
    });
  }

  changeStreamerToWatch(value) {
    const { game } = this.props;

    const player = game.streamingPlayers.find((p) => p.id === parseInt(value));

    this.setState({
      selectedStreamer: player,
    });
  }

  changeStreamVibe(value) {
    this.setState({
      streamVibe: value,
    });
  }

  render() {
    const { game } = this.props;
    const {
      redirect,
      requireMainChange,
      mainConfirmWindowOpen,
      mainSwitch,
      pointsRemaining,
      selectedFriendly,
      selectedStreamer,
    } = this.state;

    const humanPlayer = getData("humanPlayer");
    const fighters = getData("fighters");
    const humanMain = fighters.find((f) => f.id === humanPlayer.main);
    const regions = getData("regions");
    const availableTournaments = game.tournamentsInProgress;
    let tournamentObligation = false;

    if (redirect) return <Redirect to="/news" />;

    let tournamentEligible = false;

    return (
      <div className="player-actions">
        {mainConfirmWindowOpen && (
          <>
            <div className="help-modal-mask open" />
            <div className="help-modal open">
              <h2>Confirm main for upcoming season</h2>
              {(mainSwitch === null || mainSwitch.id === humanMain.id) && (
                <p>
                  You will continue with {humanMain.name} for the upcoming
                  season.
                </p>
              )}
              {mainSwitch !== null && mainSwitch.id !== humanMain.id && (
                <p>
                  You will switch from {humanMain.name} to {mainSwitch.name} for
                  the upcoming season.
                </p>
              )}

              {pointsRemaining > 0 && (
                <p>
                  You have unassigned skill points! If you don't use them now
                  they will be lost.
                </p>
              )}

              <Button primary onClick={this.confirmMainChange.bind(this)}>
                Confirm
              </Button>
              <Button onClick={this.closeMainConfirmWindow.bind(this)}>
                Actually, wait...
              </Button>
            </div>
          </>
        )}
        {requireMainChange && (
          <>
            <PageHeader>Update your stats</PageHeader>
            <dl className="stat-points">
              <p>Points remaining: {pointsRemaining}</p>

              <dt>Neutral</dt>
              <dd>
                <StatAdder
                  startValue={humanPlayer.neutral}
                  max={24}
                  min={1}
                  onChange={this.updatePlayerStats.bind(this, "neutral")}
                  pointsToSpend={pointsRemaining}
                />
              </dd>

              <dt>Advantage</dt>
              <dd>
                <StatAdder
                  startValue={humanPlayer.advantage}
                  max={24}
                  min={1}
                  pointsToSpend={pointsRemaining}
                  onChange={this.updatePlayerStats.bind(this, "advantage")}
                />
              </dd>

              <dt>Disadvantage</dt>
              <dd>
                <StatAdder
                  startValue={humanPlayer.disadvantage}
                  max={24}
                  min={1}
                  pointsToSpend={pointsRemaining}
                  onChange={this.updatePlayerStats.bind(this, "disadvantage")}
                />
              </dd>

              <dt>Punish</dt>
              <dd>
                <StatAdder
                  startValue={humanPlayer.punish}
                  max={24}
                  min={1}
                  pointsToSpend={pointsRemaining}
                  onChange={this.updatePlayerStats.bind(this, "punish")}
                />
              </dd>

              <dt>Technique</dt>
              <dd>
                <StatAdder
                  startValue={humanPlayer.technique}
                  max={24}
                  min={1}
                  pointsToSpend={pointsRemaining}
                  onChange={this.updatePlayerStats.bind(this, "technique")}
                />
              </dd>
            </dl>

            <PageHeader>Change main?</PageHeader>
            <CharacterSelectScreen
              onCharacterSelect={this.changeMain.bind(this)}
            />
          </>
        )}

        <PageHeader>
          This week's tournaments
          <HelpModal>
            <h2>Tournaments</h2>
            <p>
              Once per month you are required by the Smash XL Association to
              participate in your regional tournament, which consists of every
              player in your region. You will not be able to perform any other
              actions in this week.
            </p>
            <p>
              The final week of the season sees the top 8 seeds from each region
              qualify to the most prestigious tournament of all - Smash XL
              Worldwide. Winning this crowns you World Champion for that season.
            </p>
          </HelpModal>
        </PageHeader>
        <div className="tournaments">
          {availableTournaments.length === 0 && (
            <>
              <div className="tournament">
                <small>No tournaments are running this week.</small>
              </div>
            </>
          )}
          {availableTournaments
            .sort((a, b) => (a.region > b.region ? 1 : -1))
            .map((t) => {
              const region = regions.find((r) => r.id === t.region);
              const presenceRequired = t.players.find(
                (tp) => tp.id === humanPlayer.id
              );
              const homeRegion = regions.find(
                (r) => r.id === humanPlayer.region
              );

              if (!tournamentObligation)
                tournamentObligation = presenceRequired;

              if (presenceRequired) tournamentEligible = true;

              return (
                <div className="tournament">
                  <h3>{region.tournamentName}</h3>
                  {presenceRequired && (
                    <Button
                      onClick={this.proceed.bind(this, ACTIONS.TOURNAMENT)}
                      primary
                    >
                      Enter
                    </Button>
                  )}
                  {!presenceRequired && (
                    <Button onClick={() => {}} disabled>
                      Ineligble
                    </Button>
                  )}
                  {presenceRequired && region.id !== 1000 && (
                    <small>Your presence is required at this tournament.</small>
                  )}
                  {presenceRequired && region.id === 1000 && (
                    <small>
                      Congratulations! You have qualified as top 8 player in{" "}
                      {homeRegion.name}.
                    </small>
                  )}
                  {!presenceRequired && region.id === 1000 && (
                    <small>
                      Unfortunately, you have failed to qualify for the
                      worldwide tournament. You must be a top 8 player in your
                      region to qualify. <br />
                      Better luck next season!
                    </small>
                  )}
                </div>
              );
            })}
        </div>

        <PageHeader>
          PlayTube
          <HelpModal>
            <h2>What is PlayTube?</h2>
            <p>
              PlayTube is the official SmashXL streaming service, exclusively
              for top players. Choose a vibe - either chill out with your
              subscribers, deep dive into an informative video to gain
              experience or exploit the masses for some cash with a funny
              stream.
            </p>

            <h3>Watching streams</h3>
            <p>
              Don't want the pressure of running a stream? Sometimes you can
              take more away from just watching a stream, especially studying
              specific characters to get in-depth familiarty with them.
            </p>
          </HelpModal>
        </PageHeader>
        <div className="smash-tube">
          {tournamentObligation && (
            <p>
              Your presence is required at an official tournament this week.
            </p>
          )}
          {!tournamentObligation && (
            <>
              <p>Run your own stream:</p>
              <div className="action">
                <Dropdown
                  onChange={this.changeStreamVibe.bind(this)}
                  options={Object.keys(VIDEO_VIBES).map((vibe) => ({
                    value: VIDEO_VIBES[vibe],
                    display: VIDEO_VIBES[vibe],
                  }))}
                ></Dropdown>
                <Button
                  onClick={this.proceed.bind(this, ACTIONS.STREAM)}
                  primary
                >
                  Stream
                </Button>
              </div>
              <hr />
              <p>The following players are streaming:</p>
              <div className="action">
                <table className="actions-table">
                  {game.streamingPlayers.map((player) => {
                    const main = fighters.find((f) => f.id === player.main);
                    return (
                      <tr
                        onClick={this.changeStreamerToWatch.bind(
                          this,
                          player.id
                        )}
                        className={
                          selectedStreamer && selectedStreamer.id === player.id
                            ? "selected"
                            : ""
                        }
                      >
                        <td>{player.name}</td>
                        <td>
                          <img src={charIcons[main.name]} />
                        </td>
                        <td>{player.seed.toFixed(2)}</td>
                      </tr>
                    );
                  })}
                </table>

                {selectedStreamer && (
                  <Button
                    onClick={this.proceed.bind(this, ACTIONS.WATCH_STREAM)}
                    primary
                  >
                    Watch stream
                  </Button>
                )}
              </div>
            </>
          )}
        </div>

        <PageHeader>
          Friendlies
          <HelpModal>
            <h2>Friendlies</h2>
            <p>
              Friendlies give you the opportunity to play against other players
              without the stress of tournament conditions. They are a great way
              to gain experience towards additional skill points and get
              familiarity with both yours and your opponent's characters.
            </p>
          </HelpModal>
        </PageHeader>
        <div className="friendlies">
          {tournamentObligation && (
            <p>
              Your presence is required at an official tournament this week.
            </p>
          )}
          {!tournamentObligation && (
            <>
              <p>The following players are seeking friendlies:</p>
              <div className="action">
                <table className="actions-table">
                  {game.lookingForFriendlies.map((player) => {
                    const main = fighters.find((f) => f.id === player.main);
                    return (
                      <tr
                        onClick={this.changeFriendlyOpponent.bind(
                          this,
                          player.id
                        )}
                        className={
                          selectedFriendly && selectedFriendly.id === player.id
                            ? "selected"
                            : ""
                        }
                      >
                        <td>{player.name}</td>
                        <td>
                          <img src={charIcons[main.name]} />
                        </td>
                        <td>{player.seed.toFixed(2)}</td>
                      </tr>
                    );
                  })}
                </table>

                {selectedFriendly && (
                  <Button
                    onClick={this.proceed.bind(this, ACTIONS.FRIENDLIES)}
                    primary
                  >
                    Play friendly
                  </Button>
                )}
              </div>
            </>
          )}
        </div>

        <div className="other-actions">
          {!tournamentEligible && (
            <Button onClick={this.proceed.bind(this, ACTIONS.TOURNAMENT)}>
              Do nothing and go to next week
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Actions;
