import React, { Component, Fragment } from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  CartesianGrid,
} from "recharts";

import {
  getData,
  playerHypeGrade,
  playerStatGrade,
  playerEntertainmentGrade,
} from "../../util";
import { charIcons } from "../../data/assets";

import "./Player.scss";
import Button from "../../components/button/Button";
import PageHeader from "../../components/page-header/PageHeader";
import FollowManagement from "../../components/follow-management/FollowManagement";

class Player extends Component {
  chartData(player, playerGlobalRanking) {
    const fighters = getData("fighters");
    let lastMain = null;

    return player.history.map((item, index) => {
      const main = fighters.find((f) => f.id === item.main);
      let mainChanged = false;
      if (lastMain === null || lastMain.id !== main.id) {
        mainChanged = true;
        lastMain = main;
      }

      return {
        season: `S${index + 1}`,
        "WR #":
          item.globalRanking !== null
            ? item.globalRanking + 1
            : playerGlobalRanking,
        icon: charIcons[main.name],
        mainChanged,
      };
    });
  }

  render() {
    const sponsors = getData("sponsors");
    const fighters = getData("fighters");
    const regions = getData("regions");
    const settings = getData("settings");
    const players = getData("players").sort((a, b) =>
      a.seed > b.seed ? -1 : 1
    );
    const player = players.find(
      (p) => p.id === parseInt(this.props.match.params.id)
    );
    const playerGlobalRanking =
      players.findIndex((p) => p.id === parseInt(this.props.match.params.id)) +
      1;

    const immersiveStatMode = settings.statMode.indexOf("immersive") > -1;

    const sponsorKey = player.sponsor
      ? Object.keys(sponsors).find((key) => {
          const sponsor = sponsors[key];
          return sponsor.id === player.sponsor;
        })
      : null;

    const sponsor = sponsors[sponsorKey];

    return (
      <div className="player-container">
        <PageHeader>
          <div>
            {player.name}
            {sponsor && (
              <div className="sponsor">
                <img src={sponsor.emblem} className="sponsor-emblem" />
                <span>{sponsor.name}</span>
              </div>
            )}
          </div>
          <img src={player.avatar} className="avatar" />
        </PageHeader>
        <div className="stats">
          <dl className="seed">
            <dt>
              WR #{playerGlobalRanking}
              <span>[Seed {player.seed.toFixed(2)}]</span>
            </dt>
            <dd>
              <div className="player-actions">
                <FollowManagement player={player} />
              </div>
            </dd>
          </dl>

          <div className="score-history">
            <LineChart
              margin={{
                top: 15,
                left: 15,
                right: 15,
                bottom: 15,
              }}
              data={this.chartData(player, playerGlobalRanking)}
              height={240}
              width={320}
            >
              <XAxis dataKey="season" tick={{ fontSize: 14 }} />
              <CartesianGrid />
              <YAxis
                yAxisId="left"
                tick={{ fontSize: 10 }}
                width={20}
                ticks={[1, 128]}
                reversed
                domain={[1, 128]}
                orientation="right"
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="WR #"
                stroke="#62c89eff"
                isAnimationActive={false}
                dot={<StockIcon />}
                yAxisId="left"
              />
            </LineChart>
          </div>

          <dl>
            <dt>Neutral game</dt>
            <dd>
              {immersiveStatMode
                ? playerStatGrade(player.neutral)
                : player.neutral}
            </dd>

            <dt>Advantage game</dt>
            <dd>
              {immersiveStatMode
                ? playerStatGrade(player.advantage)
                : player.advantage}
            </dd>

            <dt>Disadvantage game</dt>
            <dd>
              {immersiveStatMode
                ? playerStatGrade(player.disadvantage)
                : player.disadvantage}
            </dd>

            <dt>Punish game</dt>
            <dd>
              {immersiveStatMode
                ? playerStatGrade(player.punish)
                : player.punish}
            </dd>

            <dt>Technique</dt>
            <dd>
              {immersiveStatMode
                ? playerStatGrade(player.technique)
                : player.technique}
            </dd>
          </dl>

          <dl className="personality">
            {player.id !== 1 && (
              <>
                <dt>Main preference</dt>
                <dd>{player.mainPreference}</dd>

                <dt>Playstyle</dt>
                <dd>{player.neutralStrategy}</dd>
              </>
            )}
            <dt>Hypeness</dt>
            <dd>
              {immersiveStatMode ? playerHypeGrade(player.hype) : player.hype}
            </dd>
            <dt>Entertainment value</dt>
            <dd>
              {immersiveStatMode
                ? playerEntertainmentGrade(player.entertainment)
                : player.entertainment}
            </dd>
            <dt>Subscribers</dt>
            <dd>{player.subscribers}</dd>
          </dl>

          <dl>
            <dt>Season XP</dt>
            <dd>
              {player.experience} [{Math.floor(player.experience / 25)} skill
              points]
            </dd>
          </dl>
          {player.history[0].tournamentResults.length > 0 && (
            <h3>Tournament results</h3>
          )}
          <dl className="tournaments">
            {player.history.reverse().map((history) => {
              const main = fighters.find((f) => f.id === history.main);

              return [
                history.tournamentResults.map((tr) => (
                  <Fragment key={tr.date}>
                    <dt>
                      <small>{tr.date}</small>
                      {
                        regions.find((r) => r.id === parseInt(tr.region))
                          .tournamentName
                      }
                    </dt>
                    <dd>
                      {tr.roundReached}
                      {main ? (
                        <img src={charIcons[main.name]} className="char-icon" />
                      ) : (
                        ""
                      )}
                    </dd>
                  </Fragment>
                )),
              ];
            })}
          </dl>

          {Object.keys(player.characterFamiliarity).length > 0 && (
            <h3>Character familiarity</h3>
          )}
          <dl className="character-expertise">
            {Object.keys(player.characterFamiliarity)
              .sort((a, b) =>
                player.characterFamiliarity[a] > player.characterFamiliarity[b]
                  ? -1
                  : 1
              )
              .map((fighterId) => {
                const fighter = fighters.find((f) => {
                  if (f.id === fighterId) return true;
                  if (f.forms.find((form) => form.id === fighterId))
                    return true;

                  return false;
                });

                return (
                  <Fragment key={fighterId}>
                    <dt>
                      <img
                        src={charIcons[fighter.name]}
                        className="char-icon"
                      />
                      {fighter.name}
                    </dt>
                    <dd>
                      <div className="progress">
                        <div
                          className="overlap"
                          style={{
                            width: `${
                              100 - player.characterFamiliarity[fighterId]
                            }%`,
                          }}
                        />
                      </div>
                    </dd>
                  </Fragment>
                );
              })}
          </dl>
        </div>
      </div>
    );
  }
}

const StockIcon = (props) => {
  const { cx, cy, payload } = props;

  if (!payload.mainChanged) return null;

  return (
    <image href={payload.icon} height="24" width="24" x={cx - 12} y={cy - 12} />
  );
};

export default Player;
