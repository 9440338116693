import React, { Component, Fragment } from "react";

import { getData } from "../../util";
import { settingData } from "../../data/Settings";
import PageHeader from "../../components/page-header/PageHeader";

import "./Settings.scss";
import Button from "../../components/button/Button";
import HelpModal from "../../components/help-modal/HelpModal";

import packageJson from "../../../package.json";
import { Link } from "react-router-dom";

class Settings extends Component {
    render() {
        const settings = getData("settings");
        const humanPlayer = getData("humanPlayer");
        return (
            <div className="settings-container">
                <PageHeader>Current game settings</PageHeader>
                <dl className="settings">
                    {Object.keys(settings).map((setting) => (
                        <Fragment key={setting}>
                            <dt>{settingData[setting].label}</dt>
                            <dd>
                                {settingData[setting][settings[setting]].name}
                                <HelpModal>
                                    <h2>
                                        {settingData[setting].label}{" "}
                                        <span>
                                            {
                                                settingData[setting][
                                                    settings[setting]
                                                ].name
                                            }
                                        </span>
                                    </h2>
                                    <p>
                                        {
                                            settingData[setting][
                                                settings[setting]
                                            ].description
                                        }
                                    </p>
                                </HelpModal>
                            </dd>
                        </Fragment>
                    ))}
                </dl>

                <dl className="settings mode">
                    <dt>Mode</dt>
                    <dd>{humanPlayer ? "Single-player" : "Zero-player"}</dd>
                </dl>

                <dl className="settings version">
                    <dt>Version</dt>
                    <dd>v{packageJson.version}</dd>
                </dl>

                <Button href={`${process.env.PUBLIC_URL}/`} primary>
                    Return to main menu
                </Button>

                <Link to={`${process.env.PUBLIC_URL}/credits`}>Credits</Link>
                <Link to={`${process.env.PUBLIC_URL}/wiki`}>
                    How to play &amp; Wiki
                </Link>
            </div>
        );
    }
}

export default Settings;
