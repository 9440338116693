import React from "react";
import { Redirect, useHistory } from "react-router-dom";

import Game from "../classes/Game";
import { getData, setData } from "../util";
import Routes from "../Routes";
import Navigation from "../components/navigation/Navigation";
import QuickMatchView from "../components/quick-match-view/QuickMatchView";
import { generateTweet } from "../data/Tweets";
import Loader from "../components/loader/Loader";
import MatchRenderer from "../components/match-renderer/MatchRenderer";
import Notification from "../components/notification/Notification";

class Root extends React.Component {
  state = {
    refresh: false,
    redirect: null,
  };

  componentDidMount() {
    setInterval(() => {
      this.setState({
        refresh: !this.state.refresh,
      });
    }, 400);

    this.game = new Game();

    window.getData = getData;
    window.setData = setData;
    window.balancePatch = this.game.balancePatch.bind(this.game);
    window.checkForMainChange = this.game.checkForMainChange.bind(this.game);
    window.releaseDLC = this.game.releaseDLC.bind(this.game);
    window.endSeason = this.game.endSeason.bind(this.game);
    window.sponsorPay = this.game.sponsorPay.bind(this.game);
    window.sponsorSearch = this.game.sponsorSearch.bind(this.game);
    window.generateTweet = generateTweet;
    window.sendNotification = this.game.sendNotification.bind(this.game);
    window.clearNotification = this.game.clearNotification.bind(this.game);
  }

  componentDidUpdate() {
    if (window.location.pathname === this.state.redirect) {
      this.setState({
        redirect: null,
      });
    }

    document.querySelectorAll(".internal-link").forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        this.setState({
          redirect: e.currentTarget.getAttribute("href"),
        });
      });
    });
  }

  render() {
    const { redirect } = this.state;
    if (redirect) return <Redirect to={redirect} />;

    if (!this.game) {
      return <Loader />;
    }

    if (this.game.goToActions) {
      this.game.goToActions = false;
      return (
        <>
          <Loader />
          <Redirect to="/actions" />
        </>
      );
    }

    let wrapperClass = "";
    if (window.location.pathname === "/") wrapperClass = "no-header";
    else if (
      this.game.tournamentsInProgress.length > 0 &&
      !this.game.readyToProceed
    )
      wrapperClass = "tourneys-in-progress";

    return (
      <>
        {window.location.pathname !== "/" &&
          window.location.pathname !== "/credits" && (
            <Navigation
              next={this.game.next.bind(this.game)}
              readyToProceed={
                this.game.readyToProceed &&
                window.location.pathname !== "/actions"
              }
            />
          )}
        {this.game.notification && <Notification game={this.game} />}
        <main className={wrapperClass}>
          <Routes
            game={this.game}
            tournaments={this.game.tournamentsInProgress}
          />

          <div className="weeklies">
            <QuickMatchView
              tournaments={this.game.tournamentsInProgress}
              visible={
                this.game.tournamentsInProgress.length > 0 &&
                !this.game.readyToProceed
              }
            />
          </div>

          {this.game.matchWatching && this.game.matchWatching.renderable && (
            <MatchRenderer match={this.game.matchWatching} />
          )}
        </main>
      </>
    );
  }
}

export default Root;
