import { getData, setData } from "../util";
import { Match } from "./Match";
import { MatchV2 } from "./MatchV2";
import { MatchV2R } from "./MatchV2R";
import { MatchV3 } from "./MatchV3";
import { MatchV3R } from "./MatchV3R";
import { boostCharacterFamiliarity } from "./Player";
import { Tournament } from "./Tournament";
import { TournamentRound } from "./TournamentRound";

export class FriendlyTournament {
  matchEngines = {
    v2: {
      quick: MatchV2,
    },
    v1: {
      quick: Match,
    },
    v2r: {
      quick: MatchV2,
      rendered: MatchV2R,
    },
    v3: {
      quick: MatchV3,
    },
    v3r: {
      quick: MatchV3,
      rendered: MatchV3R,
    },
  };

  constructor(players, callback = () => {}, game = null) {
    this.players = players;
    this.callback = callback;
    this.game = game;
    this.region = 10000;

    this.settings = getData("settings");
    this.players.sort(() => Math.random() - Math.random());

    this.matches = [];
    this.currentMatchIndex = 0;

    const humanPlayer = getData("humanPlayer");

    const matchEngine = this.matchEngines[this.settings.matchEngine];
    for (let i = 0; i < players.length; i += 2) {
      const playerOne = players[i];
      const playerTwo = players[i + 1];
      const roundMatch = new TournamentRound(
        `friendly_${i}`,
        playerOne.id,
        playerTwo.id,
        ""
      );
      let thisMatchEngine = matchEngine.quick;
      if (
        matchEngine.rendered &&
        humanPlayer &&
        (humanPlayer.id === playerOne.id || humanPlayer.id === playerTwo.id)
      ) {
        thisMatchEngine = matchEngine.rendered;
      }

      const match = this.friendlyMatch(thisMatchEngine, playerOne, playerTwo);
      roundMatch.match = match;

      this.matches.push(roundMatch);
    }

    this.rounds = [this.matches];
  }

  beginTournament() {
    this.playCurrentRound();
  }

  endTournament() {
    this.complete = true;
    if (this.game.matchWatching && this.game.matchWatching.complete)
      this.game.matchWatching = null;

    const players = getData("players");
    const humanPlayer = getData("humanPlayer");
    this.players.forEach((player) => {
      const playerRecord = players.find((p) => p.id === player.id);
      playerRecord.characterFamiliarity = player.characterFamiliarity;
      playerRecord.experience = player.experience;

      if (humanPlayer && player.id === humanPlayer.id) {
        humanPlayer.experience = player.experience;
      }
    });
    setData("players", players);
    setData("humanPlayer", humanPlayer);
    this.callback();
  }

  injectHumanPlayerAgainstOpponent(opponent) {
    const humanPlayer = getData("humanPlayer");
    const players = getData("players");
    const humanPlayerRecord = players.find((p) => p.id === humanPlayer.id);

    this.players.push(humanPlayerRecord);
    const round = this.matches.find(
      (match) =>
        match.competitorA === opponent.id || match.competitorB === opponent.id
    );
    if (round) {
      const matchEngine = this.matchEngines[this.settings.matchEngine];

      if (round.competitorA === opponent.id) {
        round.competitorB = humanPlayer.id;
        round.match = this.friendlyMatch(
          matchEngine.rendered,
          opponent,
          humanPlayer
        );
      } else if (round.competitorB === opponent.id) {
        round.competitorA = humanPlayer.id;
        round.match = this.friendlyMatch(
          matchEngine.rendered,
          humanPlayer,
          opponent
        );
      }
    }
  }

  friendlyMatch(matchEngine, playerOne, playerTwo) {
    const match = new matchEngine(
      playerOne,
      playerTwo,
      (winner, winnerStocks, winnerHype, loserHype) => {
        const roundMatch = this.rounds[0][this.currentMatchIndex];
        const match = roundMatch.match;

        roundMatch.scoreA =
          winner === roundMatch.competitorA ? winnerStocks : 0;
        roundMatch.scoreB =
          winner === roundMatch.competitorB ? winnerStocks : 0;

        let loser;
        let winningFighter, losingFighter;
        if (winner === roundMatch.competitorA) {
          loser = roundMatch.competitorB;
          winningFighter = match.fighterOne;
          losingFighter = match.fighterTwo;
        } else {
          loser = roundMatch.competitorA;
          winningFighter = match.fighterTwo;
          losingFighter = match.fighterOne;
        }

        const player = this.players.find((player) => winner === player.id);
        const losingPlayer = this.players.find((player) => loser === player.id);

        boostCharacterFamiliarity(player, winningFighter.id, 1);
        boostCharacterFamiliarity(player, losingFighter.id, 0.75);

        boostCharacterFamiliarity(losingPlayer, losingFighter.id, 1);
        boostCharacterFamiliarity(losingPlayer, winningFighter.id, 0.75);

        player.experience += 10;
        losingPlayer.experience += 5;

        this.currentMatchIndex++;
        this.playCurrentRound();
      }
    );

    match.tournamentName = "Friendly";
    match.region = {};
    match.commentary = [];

    return match;
  }

  playCurrentRound() {
    if (this.currentMatchIndex < this.matches.length) {
      if (
        !this.game.matchWatching ||
        !this.game.matchWatching.renderable ||
        (this.game.matchWatching.renderable && this.game.matchWatching.complete)
      ) {
        this.game.matchWatching = this.matches[this.currentMatchIndex].match;
        this.matches[this.currentMatchIndex].match.fighterFlexible = true;
        this.matches[this.currentMatchIndex].match.showCSS = true;
      }

      if (
        !this.game.matchWatching ||
        !this.game.matchWatching.renderable ||
        this.game.matchWatching.tournamentName !== "Friendly"
      ) {
        this.matches[this.currentMatchIndex].match.shuffleFighters();
        this.matches[this.currentMatchIndex].match.play();
      }
    } else {
      this.endTournament();
    }
  }
}
