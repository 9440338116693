import { getData, setData } from "../util";
import { Tournament } from "../classes/Tournament";
import NewsItem from "../classes/NewsItem";
import { charIcons } from "../data/assets";
import Fighter, { FIGHTER_TYPES } from "./Fighter";
import Player, { MAIN_PREFERENCE_TYPES } from "../classes/Player";
import { generateReactionTweet, TWEET_SITUATIONS } from "../data/Tweets";
import { charRenders } from "../data/assets";
import { SPONSOR_VALUES } from "./Sponsor";
import { FriendlyTournament } from "./FriendlyTournament";

export default class Game {
  constructor() {
    this.seasonalReseedValue = 2;
    this.tournamentsPerWeek = 2;
    this.monthsPerSeason = 3;
    this.balancePatchNotes = {};
    this.playerSwitches = [];
    this.tournamentsInProgress = [];
    this.lookingForFriendlies = [];
    this.streamingPlayers = [];
    this.readyToProceed = true;
    this.matchWatching = null;
    this.goToActions = false;
    this.notification = null;
  }

  balancePatch() {
    const settings = getData("settings");
    const fighters = getData("fighters")
      .sort((a, b) => (a.seed > b.seed ? -1 : 1))
      .map((character) => {
        const fighter = new Fighter(character.name);
        fighter.mapJson(character);
        return fighter;
      });

    fighters.forEach((fighter, index) => {
      fighter.history[fighter.history.length - 1].position = index;
      fighter.history[fighter.history.length - 1].seed = fighter.seed;
      fighter.history[fighter.history.length - 1].stats = {
        neutral: fighter.neutral,
        advantage: fighter.advantage,
        disadvantage: fighter.disadvantage,
        punish: fighter.punish,
        recovery: fighter.recovery,
        projectiles: fighter.projectiles,
        mobility: fighter.mobility,
        outofshield: fighter.outofshield,
      };

      const historyObject = {
        balance: null,
        position: null,
        seed: null,
        stats: {
          neutral: null,
          advantage: null,
          disadvantage: null,
          punish: null,
          recovery: null,
        },
      };

      fighter.history.push(historyObject);
    });

    this.balancePatchNotes = {};

    const range = fighters.length / 5;

    if (settings.balancePatchPolicy === "standard") {
      for (let i = 0; i < range; i++) {
        const nerfChance = Math.random() * 10;
        if (nerfChance < 7) {
          fighters[i].nerf(this.balancePatchNotes);
        }

        const buffChance = Math.random() * 10;
        if (buffChance < 7) {
          fighters[fighters.length - 1 - i].buff(this.balancePatchNotes);
        }
      }
    } else if (settings.balancePatchPolicy === "chaotic") {
      for (let i = 0; i < range; i++) {
        const randomIndex = Math.floor(Math.random() * fighters.length);
        const character = fighters[randomIndex];
        character.buff(this.balancePatchNotes);
      }

      for (let i = 0; i < range; i++) {
        const randomIndex = Math.floor(Math.random() * fighters.length);
        const character = fighters[randomIndex];
        character.nerf(this.balancePatchNotes);
      }
    }

    const time = getData("time");
    const timeString = `S${time.season} M${time.month} W${time.week}`;
    const sortedBalancePatchNotes = Object.keys(this.balancePatchNotes)
      .sort((a, b) => this.balancePatchNotes[b] - this.balancePatchNotes[a])
      .map((key) => ({
        id: key,
        change: this.balancePatchNotes[key],
      }));

    let tweetCount = 0;
    const followedPlayers = getData("followedPlayers");
    const players = getData("players");
    followedPlayers
      .sort(() => Math.random() - Math.random())
      .forEach((followedPlayer) => {
        if (tweetCount >= 5) return;
        const player = players.find((p) => p.id === followedPlayer.id);
        const mainChange = sortedBalancePatchNotes.find(
          (c) => c.id === player.main
        );

        if (typeof mainChange !== "undefined") {
          if (mainChange.change > 0) {
            generateReactionTweet(player, TWEET_SITUATIONS.MAIN_BUFFED);
            tweetCount++;
          } else if (mainChange.change < 0) {
            generateReactionTweet(player, TWEET_SITUATIONS.MAIN_NERFED);
            tweetCount++;
          }
        }
      });

    let reportBody = "<ul class='balance-patch'>";

    const bigBuffs = [];
    const smallBuffs = [];
    const smallNerfs = [];
    const bigNerfs = [];

    sortedBalancePatchNotes.forEach((note) => {
      if (note.change === 0) return;
      const fighter = fighters.find((fighter) => fighter.id === note.id);
      const noteDescription = `<li><img class="char-icon" src="${
        charIcons[fighter.name]
      }" /></li>`;

      if (note.change < 0) {
        if (note.change < -3) {
          bigNerfs.push(noteDescription);
          fighter.history[fighter.history.length - 1].balance = "--";
        } else {
          smallNerfs.push(noteDescription);
          fighter.history[fighter.history.length - 1].balance = "-";
        }
      } else if (note.change > 0) {
        if (note.change > 3) {
          bigBuffs.push(noteDescription);
          fighter.history[fighter.history.length - 1].balance = "++";
        } else {
          smallBuffs.push(noteDescription);
          fighter.history[fighter.history.length - 1].balance = "+";
        }
      }
    });
    reportBody += `<li>Big buffs</li><ul>${bigBuffs.join("")}</ul>`;
    reportBody += `<li>Small improvements</li><ul>${smallBuffs.join("")}</ul>`;
    reportBody += `<li>Minor nerfs</li><ul>${smallNerfs.join("")}</ul>`;
    reportBody += `<li>Nerf hammer</li><ul>${bigNerfs.join("")}</ul>`;

    reportBody += "</ul>";

    const report = new NewsItem(
      `Balance patch notes for S${time.season}`,
      reportBody,
      timeString,
      "balance-patch"
    );

    const news = getData("news");
    news.unshift(report);
    setData("news", news);
    setData("fighters", fighters);
  }

  proceedGameweek() {
    const regions = getData("regions");
    regions.splice(0, 1);

    const time = getData("time");

    let weekNumber = time.week;
    let monthNumber = time.month;
    let seasonNumber = time.season;

    if (this.tournamentsPerWeek * weekNumber > regions.length) {
      weekNumber = 1;
      monthNumber++;
      this.sponsorPay();

      if (monthNumber > this.monthsPerSeason) {
        monthNumber = 1;
        seasonNumber++;

        this.endSeason();
      }
    } else {
      weekNumber++;
    }

    setData("time", {
      week: weekNumber,
      month: monthNumber,
      season: seasonNumber,
    });
    this.sponsorSearch();
    this.readyToProceed = true;
  }

  sponsorPay() {
    const sponsors = getData("sponsors");
    const players = getData("players");
    const humanPlayer = getData("humanPlayer");

    players
      .filter((p) => p.sponsor !== null)
      .forEach((player) => {
        const sponsorKey = player.sponsor
          ? Object.keys(sponsors).find((key) => {
              const sponsor = sponsors[key];
              return sponsor.id === player.sponsor;
            })
          : null;

        const sponsor = sponsors[sponsorKey];
        player.finance += sponsor.salary;

        if (humanPlayer && humanPlayer.id === player.id)
          humanPlayer.finance += sponsor.salary;
      });

    setData("players", players);
    setData("humanPlayer", humanPlayer);
  }

  sponsorSearch() {
    const sponsors = getData("sponsors");
    const humanPlayer = getData("humanPlayer");
    const players = getData("players");
    const time = getData("time");
    const news = getData("news");

    const timeString = `S${time ? time.season : ""} M${
      time ? time.month : ""
    } W${time ? time.week : ""}`;
    const firstMonth = time.season === 1 && time.month === 1;

    Object.keys(sponsors).forEach((key) => {
      const sponsor = sponsors[key];
      if (sponsor.id === 1) return;
      if (firstMonth) return;
      if (Math.random() > 0.33) return; // don't fill a slot every single time

      if (sponsor.players.length < sponsor.slots) {
        const POOL_COUNT = 5;
        let reason = "";
        let description = "";
        const eligiblePlayers = players
          .filter(
            (p) => p.sponsor === null && sponsor.blacklist.indexOf(p.id) === -1
          )
          .sort((a, b) => {
            if (sponsor.values === SPONSOR_VALUES.RESULTS) {
              description = "top player";
              reason = "their excellent results";
              return a.seed > b.seed ? -1 : 1;
            }
            if (sponsor.values === SPONSOR_VALUES.HYPE) {
              description = "hype merchant";
              reason = "their awesome gameplay";
              return a.hype > b.hype ? -1 : 1;
            }
          })
          .slice(0, POOL_COUNT);

        const chosenPlayer =
          eligiblePlayers[Math.floor(Math.random() * POOL_COUNT)];
        chosenPlayer.sponsor = sponsor.id;
        sponsor.players.push(chosenPlayer.id);
        sponsor.blacklist.shift();

        const sponsorshipReport = new NewsItem(
          `${sponsor.name} picks up ${description} ${chosenPlayer.name}`,
          `<p>${chosenPlayer.name} has been on the radar of many sponsors for a while due to ${reason}, but ${sponsor.name} got in there first with a lucrative deal! Congratulations,  ${chosenPlayer.name}!</p>`,
          timeString,
          "sponsorship",
          `/players/${chosenPlayer.id}`
        );
        news.unshift(sponsorshipReport);

        if (humanPlayer && chosenPlayer.id === humanPlayer.id) {
          this.sendNotification(
            "Congratulations!",
            "You have been sponsored! You will now earn a salary and more opportunities for tournaments and streaming collabs will become available."
          );
        }
      }
    });

    setData("sponsors", sponsors);
    setData("players", players);
    setData("news", news);
  }

  releaseDLC() {
    const time = getData("time");

    const chance = 1; // Math.floor(Math.random() * 10) + 1;
    if (chance === 1) {
      const fighters = getData("fighters");
      const seeds = fighters.map((a) => a.seed);
      const meanSeed = seeds.reduce((a, b) => a + b, 0) / fighters.length;

      const dlcFighters = getData("dlcFighters");
      if (dlcFighters.length > 0) {
        const newFighter = dlcFighters.splice(0, 1)[0];
        newFighter.history = [];
        for (let i = 0; i < time.season; i++) {
          newFighter.history.push({
            balance: null,
            position: "X",
            seed: newFighter.seed,
          });
        }
        newFighter.history.push({
          balance: null,
          position: null,
          seed: newFighter.seed,
        });

        newFighter.seed = meanSeed;
        fighters.push(newFighter);
        setData("fighters", fighters);
        setData("dlcFighters", dlcFighters);

        const players = getData("players");
        players.forEach((player) => {
          if (
            player.mainPreference === MAIN_PREFERENCE_TYPES.DLC_JUNKIE &&
            Math.random() < 0.2
          ) {
            const existingMainIndex = fighters.findIndex(
              (fighter) => fighter.id === player.main
            );
            const existingSwitch = this.playerSwitches.find(
              (p) => p.player.id === player.id
            );

            if (existingSwitch) {
              existingSwitch.new = newFighter;
            } else {
              this.playerSwitches.push({
                player,
                old: fighters[existingMainIndex],
                new: newFighter,
              });
            }
            player.main = newFighter.id;
            player.history[player.history.length - 1].main = newFighter.id;
          }
        });

        setData("players", players);

        const followedPlayers = getData("followedPlayers");
        followedPlayers.sort(() => Math.random() - Math.random());
        followedPlayers.forEach((player, index) => {
          if (index >= 5) return;
          generateReactionTweet(
            player,
            TWEET_SITUATIONS.DLC_REACTION,
            null,
            newFighter
          );
        });

        const timeString = `S${time.season} M${time.month} W${time.week}`;
        const tweet = new NewsItem(
          `${newFighter.name} released as DLC #${newFighter.dlcIndex + 1}`,
          `<img class="char-icon" src="${charRenders[newFighter.name]}" />`,
          timeString,
          "dlc",
          `/fighters/${newFighter.id}`
        );

        const news = getData("news");
        news.unshift(tweet);
        setData("news", news);
      }
    }
  }

  endSeason() {
    this.balancePatch();

    const time = getData("time");

    this.playerSwitches = [];
    const players = getData("players");
    let humanPlayer = getData("humanPlayer");

    players
      .sort((a, b) => (a.seed > b.seed ? -1 : 1))
      .forEach((player, index) => {
        if ((humanPlayer && player.id !== humanPlayer.id) || !humanPlayer) {
          const playerObject = new Player();
          playerObject.mapJson(player);
          const points = Math.floor(playerObject.experience / 25);
          playerObject.useStatPoints(points + 3);

          player.neutral = playerObject.neutral;
          player.advantage = playerObject.advantage;
          player.disadvantage = playerObject.disadvantage;
          player.punish = playerObject.punish;
          player.technique = playerObject.technique;
          player.experience = 0;

          this.checkForMainChange(player);
        }

        player.history[player.history.length - 1].globalRanking = index;

        player.history.push({
          main: player.main,
          globalRanking: null,
          tournamentResults: [],
        });

        player.seed = parseFloat(
          (player.seed / this.seasonalReseedValue).toFixed(2)
        );

        if (player.strikes > 0) player.strikes--;

        if (humanPlayer && player.id === humanPlayer.id) {
          humanPlayer = player;
        }
      });

    setData("players", players);
    setData("humanPlayer", humanPlayer);

    this.releaseDLC();

    const timeString = `S${time.season} M${time.month} W${time.week}`;
    let reportBody = "";
    this.playerSwitches
      .sort((a, b) => (a.player.seed > b.player.seed ? -1 : 1))
      .forEach((playerSwitch) => {
        const playerRanking = players.findIndex(
          (player) => player.id === playerSwitch.player.id
        );
        if (playerRanking < 128) {
          reportBody += `<p><span>${
            playerSwitch.player.name
          }</span> <img class="char-icon" src="${
            charIcons[playerSwitch.old.name]
          }" /><img class="char-icon" src="${
            charIcons[playerSwitch.new.name]
          }" /></p>`;
        }
      });
    reportBody += "</div>";

    const report = new NewsItem(
      `Top 128 player character changes`,
      reportBody,
      timeString,
      "character-changes"
    );

    const news = getData("news");
    news.unshift(report);
    setData("news", news);

    const fighters = getData("fighters");
    fighters.forEach(
      (fighter) =>
        (fighter.seed = parseFloat(
          (fighter.seed / this.seasonalReseedValue).toFixed(2)
        ))
    );
    setData("fighters", fighters);
  }

  checkForMainChange(player) {
    const fighters = getData("fighters").sort((a, b) =>
      a.seed > b.seed ? -1 : 1
    );
    const switchChance = Math.random();

    const existingMainIndex = fighters.findIndex(
      (fighter) => fighter.id === player.main
    );

    switch (player.mainPreference) {
      case MAIN_PREFERENCE_TYPES.TIER_WHORE:
        if (existingMainIndex >= 20) {
          const newIndex = Math.floor(Math.random() * 20);
          player.main = fighters[newIndex].id;

          this.playerSwitches.push({
            player,
            old: fighters[existingMainIndex],
            new: fighters[newIndex],
          });
        }
        break;
      case MAIN_PREFERENCE_TYPES.BUFF_CHASER:
        if (switchChance < 0.4) {
          const buffedCharacters = Object.keys(this.balancePatchNotes).filter(
            (key) => this.balancePatchNotes[key] > 0
          );
          const newIndex = Math.floor(Math.random() * buffedCharacters.length);
          player.main = buffedCharacters[newIndex];

          this.playerSwitches.push({
            player,
            old: fighters[existingMainIndex],
            new: fighters.find((f) => f.id === player.main),
          });
        }
        break;
      case MAIN_PREFERENCE_TYPES.LOW_TIER_HERO:
        if (existingMainIndex < fighters.length - 20 && switchChance < 0.4) {
          const newIndex = fighters.length - Math.floor(Math.random() * 20) - 1;
          player.main = fighters[newIndex].id;

          this.playerSwitches.push({
            player,
            old: fighters[existingMainIndex],
            new: fighters[newIndex],
          });
        }
        break;
      case MAIN_PREFERENCE_TYPES.FLUID:
      case MAIN_PREFERENCE_TYPES.DLC_JUNKIE:
        if (switchChance < 0.1) {
          const newIndex = Math.floor(Math.random() * fighters.length);
          player.main = fighters[newIndex].id;

          this.playerSwitches.push({
            player,
            old: fighters[existingMainIndex],
            new: fighters[newIndex],
          });
        }
        break;
      case MAIN_PREFERENCE_TYPES.ZONER_FLUID:
        if (switchChance < 0.1) {
          const zoners = fighters.filter((f) => f.type === FIGHTER_TYPES.ZONER);
          const newIndex = Math.floor(Math.random() * zoners.length);
          player.main = zoners[newIndex].id;

          this.playerSwitches.push({
            player,
            old: fighters[existingMainIndex],
            new: zoners[newIndex],
          });
        }
        break;
      case MAIN_PREFERENCE_TYPES.RUSHDOWN_FLUID:
        if (switchChance < 0.1) {
          const rushdowns = fighters.filter(
            (f) => f.type === FIGHTER_TYPES.RUSHDOWN
          );
          const newIndex = Math.floor(Math.random() * rushdowns.length);
          player.main = rushdowns[newIndex].id;

          this.playerSwitches.push({
            player,
            old: fighters[existingMainIndex],
            new: rushdowns[newIndex],
          });
        }
        break;
      case MAIN_PREFERENCE_TYPES.BAIT_PUNISH_FLUID:
        if (switchChance < 0.1) {
          const baitPunishers = fighters.filter(
            (f) => f.type === FIGHTER_TYPES.BAIT_PUNISH
          );
          const newIndex = Math.floor(Math.random() * baitPunishers.length);
          player.main = baitPunishers[newIndex].id;

          this.playerSwitches.push({
            player,
            old: fighters[existingMainIndex],
            new: baitPunishers[newIndex],
          });
        }
        break;
    }
  }

  checkIfTournamentsFinished() {
    const incompleteTournaments = this.tournamentsInProgress.filter(
      (tournament) => !tournament.complete
    );

    if (incompleteTournaments.length === 0 && !this.readyToProceed) {
      this.readyToProceed = true;
      setTimeout(() => {
        this.tournamentsInProgress = [];
      }, 2000);
      this.proceedGameweek();
    }
  }

  next() {
    const humanPlayer = getData("humanPlayer");
    const players = getData("players");
    const time = getData("time");
    let weekNumber = time.week;
    let monthNumber = time.month;

    const regions = getData("regions");
    regions.splice(0, 1);
    const tournamentsInProgress = [];

    if (!humanPlayer) this.readyToProceed = false;

    // there _may_ be something fucky going on here... test later

    if (this.tournamentsPerWeek * (weekNumber - 1) >= regions.length - 1) {
      if (monthNumber === this.monthsPerSeason) {
        const endOfSeasonEntrants = [];
        regions.forEach((region) => {
          const regionPlayers = region.players.map((playerId) =>
            players.find((player) => player.id === playerId)
          );
          const topRegionPlayers = regionPlayers
            .sort((a, b) => (a.seed > b.seed ? -1 : 1))
            .slice(0, 8);
          topRegionPlayers.forEach((player) =>
            endOfSeasonEntrants.push(player.id)
          );
        });
        const endOfSeasonWorldwideTournament = new Tournament(
          endOfSeasonEntrants,
          1000,
          5,
          10000,
          this.checkIfTournamentsFinished.bind(this),
          this
        );
        endOfSeasonWorldwideTournament.baseSeedIncrement = 2;

        tournamentsInProgress.push(endOfSeasonWorldwideTournament);
      } else if (!humanPlayer) {
        this.proceedGameweek();
      }
    } else {
      regions.forEach((region, index) => {
        if (
          index >= (weekNumber - 1) * this.tournamentsPerWeek &&
          index < weekNumber * this.tournamentsPerWeek &&
          region.id !== 10000
        ) {
          tournamentsInProgress.push(
            new Tournament(
              region.players,
              region.id,
              2.5,
              region.tournamentPrizePot ? region.tournamentPrizePot : 1000,
              this.checkIfTournamentsFinished.bind(this),
              this
            )
          );
        }
      });
    }

    this.lookingForFriendlies = players
      .filter((player) => (humanPlayer ? player.id !== humanPlayer.id : true))
      .filter((player) => {
        let playerInTournament = false;
        tournamentsInProgress.forEach((tournament) => {
          if (
            tournament.players.find(
              (tournamentPlayer) => player.id === tournamentPlayer.id
            )
          )
            playerInTournament = true;
        });

        return !playerInTournament;
      })
      .sort(() => Math.random() - Math.random())
      .splice(0, 128)
      .sort((a, b) => (a.seed > b.seed ? -1 : 1));

    this.streamingPlayers = players
      .filter((player) => (humanPlayer ? player.id !== humanPlayer.id : true))
      .filter((player) => {
        let playerInTournament = false;
        tournamentsInProgress.forEach((tournament) => {
          if (
            tournament.players.find(
              (tournamentPlayer) => player.id === tournamentPlayer.id
            )
          )
            playerInTournament = true;
        });

        return !playerInTournament;
      })
      .filter(
        (player) =>
          !this.lookingForFriendlies.find(
            (friendliesPlayer) => friendliesPlayer.id === player.id
          )
      )
      .sort(() => Math.random() - Math.random())
      .splice(0, 128)
      .sort((a, b) => (a.seed > b.seed ? -1 : 1));

    this.tournamentsInProgress = tournamentsInProgress;

    if (humanPlayer) {
      this.goToActions = true;
      return;
    }

    const friendlies = new FriendlyTournament(
      this.lookingForFriendlies,
      () => {
        this.checkIfTournamentsFinished();
      },
      this
    );
    this.tournamentsInProgress.push(friendlies);

    setTimeout(() => {
      this.playTournaments();
    }, 500);
  }

  playTournaments() {
    this.tournamentsInProgress.forEach((tournament) =>
      tournament.playCurrentRound()
    );
  }

  sendNotification(testHeader, testBody) {
    this.notification = (
      <>
        <h2>{testHeader}</h2>
        <p>{testBody}</p>
      </>
    );
  }

  clearNotification() {
    this.notification = null;
  }
}
