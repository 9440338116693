import React, { Component } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import store, { history } from "./store";
import "./App.scss";
import Root from "./containers/Root";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ConnectedRouter history={history} basename="/">
                    <ScrollToTop />
                    <Root />
                </ConnectedRouter>
            </Provider>
        );
    }
}

export default App;
