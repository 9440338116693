export const STRATEGIES = {
  EDGEGUARDING: "Edgeguarding",
  EDGEGUARDING_OPTIONS: {
    NONE: "None",
    LOW_RISK: "Low risk",
    HIGH_RISK: "High risk",
    FORWARD_AERIAL: "Forward aerial",
    CAPE: "Offstage cape swing",
    FLUDD: "F.L.U.D.D.",
    REMOTE_BOMBS: "Remote Bombs",
  },
  NEUTRAL: "Neutral",
  NEUTRAL_OPTIONS: {
    BALANCED: "Balanced",
    PROJECTILE_SPAM: "Campy",
    PRESSURE: "Pressure",
    COUNTER: "Counter",
    CARGO_THROW: "Cargo Throw",
    CHARGE_SHOT: "Charge Shot",
    VIKING_RAID: "Play Epic Riffs",
  },
};

export const STRATEGY_EFFECTIVENESS = {
  [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: {
    [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: 1.1,
    [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: 1.1,
  },
  [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: {
    [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: 0.8,
    [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: 0.9,
  },
  [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: {
    [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: 0.8,
    [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: 1.1,
    [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: 1.1,
  },
  [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: {
    [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: 0.8,
    [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: 1.1,
    [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: 1.1,
  },
  [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: {
    [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: 1.2,
    [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: 1.2,
    [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: 1.1,
    [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: 1.1,
  },
  [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: {
    [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: 1.1,
    [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: 1,
  },
  [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: {
    [STRATEGIES.NEUTRAL_OPTIONS.BALANCED]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.PROJECTILE_SPAM]: 1.1,
    [STRATEGIES.NEUTRAL_OPTIONS.PRESSURE]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.COUNTER]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.CARGO_THROW]: 0.9,
    [STRATEGIES.NEUTRAL_OPTIONS.CHARGE_SHOT]: 1,
    [STRATEGIES.NEUTRAL_OPTIONS.VIKING_RAID]: 1,
  },

  // Edgeguarding

  [STRATEGIES.EDGEGUARDING_OPTIONS.NONE]: {
    RISK: 0,
    REWARD: 0,
    HYPE: -2,
    MEMES: -2,
  },
  [STRATEGIES.EDGEGUARDING_OPTIONS.LOW_RISK]: {
    RISK: 2,
    REWARD: 2,
    HYPE: 1,
    MEMES: 0,
  },
  [STRATEGIES.EDGEGUARDING_OPTIONS.HIGH_RISK]: {
    RISK: 5,
    REWARD: 5,
    HYPE: 4,
    MEMES: 0,
  },
  [STRATEGIES.EDGEGUARDING_OPTIONS.FORWARD_AERIAL]: {
    RISK: 3,
    REWARD: 5,
    HYPE: 4,
    MEMES: 3,
  },
  [STRATEGIES.EDGEGUARDING_OPTIONS.FLUDD]: {
    RISK: -1000,
    REWARD: 1,
    HYPE: -5,
    MEMES: 3,
  },
  [STRATEGIES.EDGEGUARDING_OPTIONS.CAPE]: {
    RISK: 1,
    REWARD: 2,
    HYPE: -1,
    MEMES: 4,
  },
  [STRATEGIES.EDGEGUARDING_OPTIONS.REMOTE_BOMBS]: {
    RISK: -1000,
    REWARD: 3,
    HYPE: -3,
    MEMES: 1,
  },
};
