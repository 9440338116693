import React, { Component } from "react";
import { Link } from "react-router-dom";

import { charRenders } from "../../data/assets";
import "./VideoThumbnail.scss";

class VideoThumbnail extends Component {
  render() {
    const { title, streamer, fighter, quality, thumbnail } = this.props;

    return (
      <div className="video-thumbnail">
        {thumbnail && (
          <div className="video-thumbnail-image">
            <img
              src={`/video_thumbs/${thumbnail.background}.jpg`}
              className="background"
            />
            <img
              src={streamer.avatar}
              style={{
                left: `${thumbnail.streamerPositionX}%`,
                top: `${thumbnail.streamerPositionY}%`,
                transform: `rotate(${thumbnail.streamerRotation}deg) translateX(-50%) translateY(-50%)`,
                borderRadius: "50%",
              }}
            />
            <img
              src={charRenders[fighter.name]}
              style={{
                left: `${thumbnail.characterPositionX}%`,
                top: `${thumbnail.characterPositionY}%`,
                transform: `rotate(${thumbnail.characterRotation}deg) translateX(-50%) translateY(-50%)`,
              }}
            />
          </div>
        )}
        <p>
          <strong>{title}</strong>
        </p>
        <div class="stream-details">
          <Link to={`/players/${streamer.id}`}>
            <img src={streamer.avatar} className="avatar" /> {streamer.name}
          </Link>
          <div className="quality">
            <span className="template" />
            <span
              className="quality-rating"
              style={{ width: `${(100 / 5) * quality}%` }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoThumbnail;
