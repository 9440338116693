import React, { Component } from "react";

import "./Switcher.scss";

class Switcher extends Component {
    render() {
        return <div className="switcher">{this.props.children}</div>;
    }
}

export default Switcher;
