import React from "react";
import { Link } from "react-router-dom";

import "./Dropdown.scss";

class Dropdown extends React.Component {
    onChange(event) {
        const value = event.currentTarget.value;
        this.props.onChange(value);
    }

    render() {
        const { options } = this.props;

        return (
            <select className="dropdown" onChange={this.onChange.bind(this)}>
                {options.map((o) => (
                    <option value={o.value} disabled={o.disabled} key={o.value}>
                        {o.display}
                    </option>
                ))}
            </select>
        );
    }
}

export default Dropdown;
