import { compress, decompress } from "lzutf8";

export const generateId = (length = 16) => {
  let id = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++)
    id += characters.charAt(Math.floor(Math.random() * characters.length));

  return id;
};

export const clearData = () => {
  Object.keys(localStorage).forEach((key) => localStorage.removeItem(key));
};

export const getData = (key) => {
  const input = localStorage.getItem(key);
  if (!input) return null;

  const output = decompress(input, {
    inputEncoding: "StorageBinaryString",
  });
  return JSON.parse(output);
};

export const setData = (key, data) => {
  const input = JSON.stringify(data);
  const output = compress(input, {
    outputEncoding: "StorageBinaryString",
  });
  localStorage.setItem(key, output);
};

export const playerStatGrade = (stat) => {
  if (stat > 16) return "S";
  if (stat > 12) return "A";
  if (stat > 8) return "B";
  if (stat > 4) return "C";
  if (stat > 1) return "D";
  return "E";
};

export const fighterStatGrade = (stat, history, current = null) => {
  const activeHistory = history.filter(
    (h) => h.position !== "X" && h.position !== null
  );
  if (activeHistory.length === 0) {
    if (stat === "projectiles" && current === 0) {
      return "-";
    }
    return "?";
  }

  const value = activeHistory[activeHistory.length - 1].stats[stat];

  if (value === 0) return "-";
  if (value > 10) return "S";
  if (value > 8) return "A";
  if (value > 6) return "B";
  if (value > 5) return "C";
  if (value > 3) return "D";
  return "E";
};

export const weightClass = (weight) => {
  if (weight >= 12) return "Superheavyweight";
  if (weight >= 10) return "Heavyweight";
  if (weight >= 9) return "Middleweight";
  if (weight >= 8) return "Lightweight";
  return "Featherweight";
};

export const playerHypeGrade = (hype) => {
  if (hype >= 1500) return "Hype AF";
  if (hype >= 1250) return "Hype";
  if (hype >= 1000) return "Fun";
  if (hype >= 750) return "Lame";
  return "Boring";
};

export const playerEntertainmentGrade = (hype) => {
  if (hype >= 1500) return "Top creator";
  if (hype >= 1250) return "Hilarious";
  if (hype >= 1000) return "Fun";
  if (hype >= 750) return "Uninteresting";
  return "Boring";
};

export const fighterHypeGrade = (hype) => {
  if (hype >= 5) return "Hype AF";
  if (hype >= 3) return "Hype";
  if (hype >= 0) return "Fun";
  if (hype >= -2) return "Lame";
  return "Boring";
};

export const times = (x) => (f) => {
  if (x > 0) {
    f();
    times(x - 1)(f);
  }
};
