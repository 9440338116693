import React, { Component } from "react";

import RoundView from "../../components/round-view/RoundView";
import "./TournamentView.scss";

class TournamentView extends Component {
    render() {
        const { tournament, regions, players, fighters } = this.props;

        const region = regions.find(
            (region) => region.id === tournament.region
        );

        return (
            <div className="tournament">
                <h3>{region.name}</h3>
                <div className="rounds">
                    {tournament.rounds
                        .sort((a, b) => (a.length < b.length ? -1 : 1))
                        .map((round) => (
                            <RoundView
                                round={round}
                                players={players}
                                fighters={fighters}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default TournamentView;
