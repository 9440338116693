import React, { Component } from "react";
import { Link } from "react-router-dom";

import { charIcons } from "../../data/assets";
import Button from "../../components/button/Button";
import { getData, playerHypeGrade, setData } from "../../util";

import "./TopPlayers.scss";
import Switcher from "../../components/switcher/Switcher";
import FollowManagement from "../../components/follow-management/FollowManagement";
import Dropdown from "../../components/dropdown/Dropdown";

class TopPlayers extends Component {
  state = {
    filter: "seed",
  };

  changeFilter(filter) {
    this.setState({
      filter,
    });
  }

  getTopPlayers() {
    const { filter } = this.state;

    const sponsors = getData("sponsors");
    const fighters = getData("fighters");
    const players = getData("players");
    const settings = getData("settings");

    const immersiveStatMode = settings.statMode.indexOf("immersive") > -1;

    return (
      <>
        <div className="filters">
          <Dropdown
            onChange={this.changeFilter.bind(this)}
            options={[
              {
                value: "seed",
                display: "Seed",
              },
              {
                value: "hype",
                display: "Hype",
              },
              {
                value: "finance",
                display: "Rich list",
              },
              {
                value: "subscribers",
                display: "Subscribers",
              },
            ]}
          ></Dropdown>
        </div>
        <ul className="top-players">
          <ul>
            {players
              .sort((a, b) => (a[filter] < b[filter] ? 1 : -1))
              .slice(0, 128)
              .map((player, index) => {
                const main = fighters.find(
                  (fighter) => fighter.id === player.main
                );

                const sponsorKey = player.sponsor
                  ? Object.keys(sponsors).find((key) => {
                      const sponsor = sponsors[key];
                      return sponsor.id === player.sponsor;
                    })
                  : null;

                const sponsor = sponsors[sponsorKey];
                return (
                  <li key={player.id} className={sponsor ? "has-sponsor" : ""}>
                    <span className="rank">#{index + 1}</span>
                    {main ? <img src={charIcons[main.name]} /> : ""}
                    <Link to={`${process.env.PUBLIC_URL}/players/${player.id}`}>
                      <div>
                        <strong>
                          {player.name}
                          <img src={player.flag} />
                        </strong>

                        {sponsor && (
                          <div className="sponsor">
                            <img
                              src={sponsor.emblem}
                              className="sponsor-emblem"
                            />
                            <span>{sponsor.name}</span>
                          </div>
                        )}
                      </div>
                    </Link>
                    {filter === "seed" && <>[{player.seed.toFixed(2)}]</>}
                    {filter === "hype" && (
                      <>{immersiveStatMode ? "" : `[${player.hype}]`}</>
                    )}
                    {filter === "finance" && (
                      <>${(player.finance / 10).toFixed(2)}</>
                    )}

                    {filter === "subscribers" && <>[{player.subscribers}]</>}
                    <FollowManagement player={player} />
                  </li>
                );
              })}
          </ul>
        </ul>
      </>
    );
  }

  follow(player) {
    const followedPlayers = getData("followedPlayers");
    const isFollowing = followedPlayers.findIndex((p) => p.id === player.id);

    if (isFollowing < 0) {
      followedPlayers.push(player);
    } else {
      followedPlayers.splice(isFollowing, 1);
    }

    setData("followedPlayers", followedPlayers);
  }

  render() {
    const time = getData("time");
    const firstMonth = time.season === 1 && time.month === 1;

    return (
      <div className="top-players-container">
        <Switcher>
          <span className="active">Top 128</span>
          <Link to="/players/following">
            <span>Following</span>
          </Link>
        </Switcher>
        {firstMonth && (
          <p className="patience-message">
            Woah there! Give the meta a chance! Come back after a month or so to
            see how well players are doing.
          </p>
        )}
        {!firstMonth && this.getTopPlayers()}
      </div>
    );
  }
}

export default TopPlayers;
