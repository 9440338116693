import { MATCH_STATES } from "./MatchStates";

export const commentary = {
    [MATCH_STATES.NEUTRAL]: [
        "Some good play this",
        "Happy feet!",
        "Look at that movement.",
        "Schnasty.",
        "{{winningPlayer}} looks to have this covered.",
        "You can never rule out {{losingFighter}}, though!",
        "{{winningPlayer}}'s {{winningFighter}} is something else.",
        "{{winningFighter}}-{{losingFighter}} is my favourite match up.",
        "Eurgh, {{losingFighter}}'s neutral game is so lame.",
        "Can {{losingPlayer}} make a comeback here?",
        "I've always found this matchup to be pretty balanced.",
        "We'll see how well {{losingPlayer}} will be able to handle the speed at which {{winningFighter}} plays at.",
        "Wow, can't believe Nintendo made Melee HD and ruined the entire game!",
    ],
    [MATCH_STATES.ADVANTAGE]: [
        "Wombo Combo!",
        "I bet that took some labbing",
        "Mate I couldn't do that in a thousand years",
        "Nice follow up from {{winningPlayer}}...",
        "Making use of those autopilot {{winningFighter}} strings.",
    ],
    [MATCH_STATES.PUNISHING]: [
        "That ain't Falco!",
        "What a punish!",
        "OH! OHHHHH!",
        "Surely {{losingPlayer}} ain't coming back from that",
        "Smaaaaaash!",
        "Yo did he just walk up slowly and down smash?",
    ],
    [MATCH_STATES.RESPAWNING]: [
        "Where you at!!",
        "We tech those",
        "KO'd!",
        "Let's see how {{losingPlayer}} recovers from this one.",
        "you gotta stay on that platform and regroup",
        "{{losingPlayer}} down for the count here",
        "what was that DI from {{losingPlayer}}?",
    ],
    [MATCH_STATES.RECOVERING]: [
        "Will they make it back?",
        "{{losingFighter}}'s recovery is actually garbage.",
        "Ledgetrapping!!",
        "I'm going high!",
        "{{losingFighter}} basically has free recovery, right?",
        "Is {{winningPlayer}} even going to try and edgeguard?",
    ],
};

export const commentate = (
    winningPlayer,
    winningFighter,
    losingPlayer,
    losingFighter,
    state
) => {
    let commentaryItem =
        commentary[state][Math.floor(Math.random() * commentary[state].length)];
    commentaryItem = commentaryItem.replace(
        /{{winningPlayer}}/g,
        winningPlayer.name
    );
    commentaryItem = commentaryItem.replace(
        /{{losingPlayer}}/g,
        losingPlayer.name
    );
    commentaryItem = commentaryItem.replace(
        /{{winningFighter}}/g,
        winningFighter.name
    );
    commentaryItem = commentaryItem.replace(
        /{{losingFighter}}/g,
        losingFighter.name
    );

    return commentaryItem;
};
