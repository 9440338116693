import React, { Component } from "react";

import { getData } from "../../util";
import { Tournament } from "../../classes/Tournament";

import "./News.scss";
import TournamentView from "../../components/tournament-view/TournamentView";
import NewsView from "../../components/news-view/NewsView";

class News extends Component {
  state = {
    testTournament: null,
  };

  createTestTournament() {
    const region = getData("regions")[0];
    this.setState({
      testTournament: new Tournament(region.players, region.id),
    });
  }

  playTestTournamentRound() {
    this.state.testTournament.playCurrentRound();
    setInterval(() => {
      this.setState({
        refresh: !this.state.refresh,
      });
    }, 50);
  }

  render() {
    const { tournaments } = this.props;

    const players = getData("players");
    const fighters = getData("fighters");
    const regions = getData("regions");
    const news = getData("news");

    return (
      <div className="home-container">
        {/* <button onClick={this.createTestTournament.bind(this)}>
                    Test Tournament
                </button> */}

        {this.state.testTournament && (
          <div className="test-tournament">
            <button onClick={this.playTestTournamentRound.bind(this)}>
              Play next round
            </button>
            <TournamentView
              tournament={this.state.testTournament}
              regions={regions}
              players={players}
              fighters={fighters}
            />
          </div>
        )}

        <div className="news">
          {news &&
            news
              .slice(0, 100)
              .map((newsItem, index) => (
                <NewsView newsItem={newsItem} key={`news_${index}`} />
              ))}
        </div>
      </div>
    );
  }
}

export default News;
